import React from "react"

// Data
import legalData from "./data/modalLegal"

/**
 * Modal for showing legal data
 * @param type - type of legal data to be shown
 * @param handleCloseModal - close modal
 * */
const ModalLegal = ({ type, handleCloseModal }) => {
  // Set modal data
  const data = legalData[type]

  return (
    <>
      <span
        role="button"
        onKeyDown={handleCloseModal}
        onClick={handleCloseModal}
        tabIndex={0}
        aria-label="Close Modal."
        className="overlay"
      />
      <div className="modal modal-legal">
        <button
          onClick={handleCloseModal}
          aria-label="Close Modal."
          className="modal__close"
        >
          <i className="fal fa-times" />
        </button>

        <h3 className="txt--left mb--30">{data.title}</h3>
        <div
          className="modal__content"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </div>
    </>
  )
}
export default ModalLegal
