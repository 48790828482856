import React, { useEffect, useState } from "react"

// Data
import apparelData from "../../../static/data/apparel"

/**
 * Modal for apparel size guide
 * @param shopifyProduct - the selected product
 * @param handleCloseModalSizeGuide - close the modal
 * */

const ModalSizeGuide = ({ shopifyProduct, handleCloseModalSizeGuide }) => {
  /*=============================================
    SET TABLE DATA
  =============================================*/
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (!shopifyProduct) return
    setTableData(apparelData[shopifyProduct.shopifyId].sizeGuide)
  }, [shopifyProduct])

  /*=============================================
    HANDLE CONVERSION
  =============================================*/
  useEffect(() => {
    if (!tableData.horizontal || !tableData.horizontal.length) return

    tableData.horizontal.forEach(item => {
      let convertedToMetric = []
      item.imperial.forEach(inches => {
        let convertedValue =
          inches !== "OSFM" ? (inches * 2.54).toFixed(2) : "OSFM"

        convertedToMetric = [...convertedToMetric, convertedValue]
      })

      item.metric = convertedToMetric
    })
  }, [tableData.horizontal])

  /*=============================================
    HANDLE UNIT CHANGE
  =============================================*/
  const [selectedUnit, setSelectedUnit] = useState("inch")

  const handleMetric = unit => {
    setSelectedUnit(unit)
  }

  return (
    <>
      <span
        role="button"
        onKeyDown={handleCloseModalSizeGuide}
        onClick={handleCloseModalSizeGuide}
        tabIndex={0}
        aria-label="Close Modal."
        className="overlay overlay-size"
      />
      <div className="modal modal--lg modal-size">
        <button
          onClick={handleCloseModalSizeGuide}
          aria-label="Close modal."
          className="modal__close"
        >
          <i className="fal fa-times" />
        </button>

        <div className="modal__header">
          <h3 className="txt--left txt--white">Sizing Guide</h3>
        </div>
        <div className="modal__content">
          <div className="modal__subheader">
            <h5 className="m--0">{shopifyProduct.title}</h5>
            <div className="modal__buttons d-flex justify-content-between">
              <button
                aria-label="Change to inch."
                onClick={() => {
                  handleMetric("inch")
                }}
                className={`btn-metric ${
                  selectedUnit === "inch" ? "active" : ""
                }`}
              >
                Inch
              </button>
              <button
                aria-label="Change to cm."
                onClick={() => {
                  handleMetric("cm")
                }}
                className={`btn-metric ${
                  selectedUnit === "cm" ? "active" : ""
                }`}
              >
                Cm
              </button>
            </div>
          </div>

          {tableData && (
            <table
              className={`table-size ${
                !(tableData.vertical && tableData.vertical.length > 0)
                  ? "table-size--columns"
                  : ""
              }`}
            >
              {tableData.vertical && tableData.vertical.length > 0 && (
                <thead>
                  <tr>
                    <th />
                    {tableData.vertical.map((item, index) => (
                      <th key={`vertical-${index}`}>{item}</th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody>
                {tableData.horizontal &&
                  tableData.horizontal.map((item, index) => (
                    <tr key={`horizontal-${index}`}>
                      <td>{item.name}</td>
                      {selectedUnit === "inch" ? (
                        <>
                          {item.imperial.map((value, index) => (
                            <td
                              key={index}
                              data-size={tableData.vertical[index]}
                            >
                              {value}
                              {value !== "OSFM" ? '"' : ""}
                            </td>
                          ))}
                        </>
                      ) : (
                        <>
                          {item.metric.map((value, index) => (
                            <td
                              key={`metric-${index}`}
                              data-size={tableData.vertical[index]}
                            >
                              {value}
                              {value !== "OSFM" ? "cm" : ""}
                            </td>
                          ))}
                        </>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          )}

          {tableData.notes &&
            tableData.notes.map((item, index) => (
              <div key={`notes-${index}`} className="modal__note">
                <p>
                  {item.title.length > 0 && <strong>{item.title}:</strong>}{" "}
                  {item.content}
                </p>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
export default ModalSizeGuide
