/**
 * Format shopify id
 * @param id - original shopify id
 * @returns id - formatted
 * */
export const formatShopifyId = id => {
  if (!id) return

  if (id.indexOf("Shopify__ProductVariant__") !== -1) {
    return id.split("Shopify__ProductVariant__")[1]
  } else {
    return id
  }
}

/**
 * Decode variant id from base64 encoding
 * @param id - original shopify id
 * @returns id - decoded
 * */
export const decodeID = id => {
  if (!id || !id.length) return

  let substring = "gid://shopify/ProductVariant/"
  let decoded = atob(id)
  return parseInt(decoded.replace(substring, ""))
}

/**
 * Extract color from image source
 * @param text - string
 * @returns text - extracted color
 * */
export const extractColorFromSrc = text => {
  let startIndex = text.indexOf("__") + 2
  let endIndex = text.lastIndexOf("__")
  if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
    return text.substring(startIndex, endIndex)
  } else {
    return text
  }
}

/**
 * Format extracted color
 * @param text - string
 * @returns text - extracted color from source and formatted
 * */
export const formatExtractedColor = text => {
  let color = extractColorFromSrc(text)

  // Add space instead of -
  let formattedColor = color.replaceAll("-", " ")
  // Add / instead of _
  if (formattedColor.toLowerCase() === "antique denim_grey") {
    formattedColor = "antique denim (grey)"
  } else {
    formattedColor = formattedColor.replace(/_/g, "/")
  }
  return formattedColor.toLowerCase()
}

/**
 * Reverse format extracted color
 * @param text - string
 * @returns text - extracted color from source and formatted
 * */
export const reverseFormatExtractedColor = text => {
  let color = extractColorFromSrc(text)

  // Add - instead of space
  let formattedColor = color.replaceAll(" ", "-")
  // Add _ instead of /
  if (formattedColor.toLowerCase() === "antique-denim-(grey)") {
    formattedColor = "antique-denim_grey"
  } else {
    formattedColor = formattedColor.replace(/\//g, "_")
  }

  return formattedColor.toLowerCase()
}

/**
 * Extract image name and color
 * @param text - string
 * @returns { name: string, color: string} - image name and color
 * */
export const extractImageNameAndColor = text => {
  let slicedNameFromBeginning = text.slice(text.lastIndexOf("/") + 1, text.length)
  let slicedNameFromEnd = slicedNameFromBeginning.slice(0, slicedNameFromBeginning.lastIndexOf("__"))

  let name = slicedNameFromEnd.split("__")[0]
  let color = slicedNameFromEnd.split("__")[1]

  return { name: name, color: color }
}

/**
 * Extract image name and color
 * @param name - string of the full shopify image path
 * @returns formattedName - only the name of the image without extension
 * */
export const extractSelectedOptionImageName = name => {
  if (!name.length) return

  const slicedNameBeginning = name.slice(name.lastIndexOf("products/") + 9, name.length)

  return slicedNameBeginning.slice(0, slicedNameBeginning.indexOf("bundle") + 6)
}

/**
 * Extract type and interval image name
 * @param name - string of the full shopify image path
 * @param shopifyProduct - the product of the variant
 * @returns formattedName - only the name of the image without extension
 * */
export const extractVariantImageName = (name, shopifyProduct) => {
  if (!name.length) return

  let slicedNameBeginning

  if (shopifyProduct.tags.indexOf("promo") !== -1) {
    const searchWord = "-promo-"
    slicedNameBeginning = name.slice(name.lastIndexOf(searchWord) + searchWord.length)
  }

  return slicedNameBeginning.split(".")[0]
}

/**
 * Format the color for url parameter
 * @param text - string
 * @returns text - formatted color
 * */
export const formatColorForUrlParam = text => {
  if (!text.length) return

  // Add - instead of space
  let formattedColor = text.replaceAll(" ", "-")
  // Add _ instead of /
  if (formattedColor.toLowerCase() === "antique-denim-(grey)") {
    formattedColor = "antique-denim_grey"
  } else {
    formattedColor = formattedColor.replace(/\//g, "_")
  }

  return formattedColor.toLowerCase()
}

/**
 * Get url param for product color
 * @param shopifyProduct - shopifyProduct data
 * @param color - product color
 * @returns urlParam
 * */
export const getUrlParam = (shopifyProduct, color) => {
  if (!shopifyProduct || !color || !color.length) return

  let param = ""
  if (shopifyProduct.options.length && shopifyProduct.tags.indexOf("apparel") !== -1) {
    shopifyProduct.options.forEach(option => {
      if (option.name.toLowerCase() === "color" && option.values.length > 1) {
        param = `?color=${formatColorForUrlParam(color)}`
      }
    })
  }

  return param
}
