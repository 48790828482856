import React, { useEffect, useState } from "react"

// Utilities
import { extractSelectedOptionImageName } from "../../utilities/formatString"
import {
  formatProductDescriptionSidebarMessage,
  formatProductTitle,
  handleProductIconById,
} from "../../utilities/formatProductInfo"

// Components
import { Link } from "gatsby"
import Image from "../Image/Image"

// Data
import productData from "../../../static/data/product"
import Quantity from "../Quantity/Quantity"

/**
 * @param shopifyProduct - product object from Shopify
 * @param cartItem - cart item
 * @param theme - theme
 * **/
export default function SidebarItem({ shopifyProduct, cartItem, theme }) {
  /*====================================
  HANDLE PRODUCT DATA
  =====================================*/
  const title = formatProductTitle(shopifyProduct.title, shopifyProduct)
  // Get the icon for the product type
  const icon = handleProductIconById(
    productData.hasOwnProperty(cartItem.variantId) ? cartItem.variantId : cartItem.productId,
  )

  // Update image color
  useEffect(() => {
    if (!cartItem) return

    // Update image
    handleImageSrc(cartItem)
  }, [cartItem])

  // Set quantity
  useEffect(() => {
    if (!cartItem.quantity) return

    setQuantity(cartItem.quantity)
  }, [cartItem.quantity])

  /*====================================
    HANDLE IMAGE
  =====================================*/
  const [imageSrc, setImageSrc] = useState("")

  // Update image source according to selected options
  const handleImageSrc = cartItem => {
    if (cartItem.options && cartItem.options["color"]) {
      shopifyProduct.variants.forEach(variant => {
        if (variant.shopifyId === cartItem.variantId && variant.media.length) {
          setImageSrc(variant.media[0].image.originalSrc)
        }
      })
    } else {
      let imageSrc = shopifyProduct.media.length ? shopifyProduct.media[0].image.originalSrc : ""
      let variantImgName = ""

      shopifyProduct.variants.forEach(variant => {
        if (variant.shopifyId === cartItem.variantId && variant.media.length) {
          // Get the variant image src if it contains workout-bundle substring
          if (variant.media[0].image.originalSrc.indexOf("workout-bundle") !== -1) {
            variantImgName = variant.media[0].image.originalSrc
          }

          // Get the variant image src if the product is a promo offer
          if (shopifyProduct.tags.indexOf("promo") !== -1) {
            imageSrc = variant.media[0].image.originalSrc
          }
        }
      })

      // Get the corresponding gallery image for the selected variant
      if (variantImgName.length) {
        const optionType = extractSelectedOptionImageName(variantImgName)
        if (optionType.length) {
          shopifyProduct.media.forEach(media => {
            if (media.image.originalSrc.indexOf(`${optionType}-gallery`) !== -1) {
              imageSrc = media.image.originalSrc
            }
          })
        }
      }

      setImageSrc(imageSrc)
    }
  }

  /*====================================
    HANDLE QUANTITY
  =====================================*/
  const [quantity, setQuantity] = useState(cartItem.quantity)
  const [quantityEnabled, setQuantityEnabled] = useState(true)

  const handleQuantity = quantity => setQuantity(quantity)

  // On quantity update, update the cart item's quantity
  useEffect(() => {
    theme.updateQuantity(cartItem, quantity)
  }, [quantity])

  // Update product's quantity value
  useEffect(() => {
    setQuantity(cartItem.quantity)
  }, [cartItem.quantity])

  useEffect(() => {
    // Enable quantity change
    if (
      (productData.hasOwnProperty(cartItem.variantId) &&
        (cartItem.type.toLowerCase() === "supplements" || cartItem.type.toLowerCase() === "bundles") &&
        !productData[cartItem.variantId].hidden) ||
      !productData.hasOwnProperty(cartItem.variantId)
    ) {
      if (productData.hasOwnProperty(cartItem.variantId) && productData[cartItem.variantId].noQuantity) {
        setQuantityEnabled(false)
      } else {
        setQuantityEnabled(cartItem.productId !== theme.giftId)
      }
    } else {
      setQuantityEnabled(false)
    }
  }, [])

  /*====================================
    HANDLE PRODUCT DESCRIPTION MESSAGE
  =====================================*/
  const productDescriptionMessage = formatProductDescriptionSidebarMessage(cartItem, shopifyProduct)

  return (
    <div className="sidebar-item">
      <div className="row no-gutters">
        <div className="col-4">
          <Link to={`/products/${shopifyProduct.handle}`} onClick={theme.handleSidebar} className="sidebar-item__img">
            <span className="sidebar-item__badge">
              {icon === "tshirt" ? (
                <img loading="lazy" src="/images/heart_hanger.svg" alt="" />
              ) : icon === "tag" ? (
                <img src="/images/tag.svg" alt="" width={19} height={19} className="tag--sm" />
              ) : (
                <i className={`fas fa-${icon}`} />
              )}
            </span>

            {imageSrc.length ? (
              <img src={imageSrc} width="140" height="112" alt="" className="lazy" />
            ) : (
              <Image filename="default-img.png" />
            )}
          </Link>
        </div>
        <div className="col-8">
          <div className="sidebar-item__content">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="txt--18 fw--medium mb--5 txt--capitalize">
                <Link
                  to={`/products/${shopifyProduct.handle}`}
                  onClick={theme.handleSidebar}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </h6>
              <button
                className="sidebar__close"
                aria-label="Close Sidebar."
                onClick={() => theme.removeLineItemInCart(cartItem)}
              >
                <i className="fal fa-times" />
              </button>
            </div>
            <div className={`sidebar-item__price ${cartItem.selling_plan !== "No" ? "mb--5" : "mb--10"}`}>
              <p className="txt--16 txt--pink">
                <strong>
                  {cartItem.price > 0 ? `$${((Math.round(cartItem.price * 100) / 100) * quantity).toFixed(2)}` : "FREE"}
                </strong>
              </p>
            </div>
            {/*Apparel Description*/}
            {!productData.hasOwnProperty(cartItem.variantId) && (
              <div className="sidebar-item__options">
                <span>
                  <strong>Color:</strong> {cartItem.options.color}
                </span>
                <span>
                  <strong>Size:</strong> {cartItem.options.size}
                </span>
              </div>
            )}
            {/*Product Description*/}
            {productDescriptionMessage.length > 0 && (
              <span className="subscribed-label">{productDescriptionMessage}</span>
            )}
            {quantityEnabled && (
              <Quantity
                quantity={quantity}
                handleQuantity={handleQuantity}
                modifierClass="sidebar-item__subscribed"
                noLabel={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
