/**
 * Convert product Id to storefrontId
 * @param shopifyData - the fetched shopify data
 * @param id - product it
 * @returns legacyId - returns the storefrontId for the id sent as param
 * */
export const convertProductIdToStorefrontId = (shopifyData, id) => {
  if (!id || !shopifyData) return

  let legacyId

  if (shopifyData.filter(product => product.shopifyId === id).length) {
    legacyId = shopifyData.filter(product => product.shopifyId === id)[0]
      .storefrontId
  }

  return legacyId
}

/**
 * Convert variant Id to storefrontId
 * @param shopifyData - the fetched shopify data
 * @param id - product it
 * @returns legacyId - returns the storefrontId for the variant id sent as param
 * */
export const convertVariantIdToStorefrontId = (shopifyData, id) => {
  if (!id || !shopifyData) return

  let legacyId

  shopifyData.forEach(product => {
    if (product.variants.filter(variant => variant.shopifyId === id).length) {
      legacyId = product.variants.filter(variant => variant.shopifyId === id)[0]
        .storefrontId
    }
  })

  return legacyId
}

/**
 * Convert product Id to legacyResourceId
 * @param shopifyData - the fetched shopify data
 * @param id - product it
 * @returns legacyId - returns the legacyResourceId for the product id sent as param
 * */
export const convertProductIdToLegacyResourceId = (shopifyData, id) => {
  if (!id || !shopifyData) return

  let legacyId

  if (shopifyData.filter(product => product.shopifyId === id).length) {
    legacyId = shopifyData.filter(product => product.shopifyId === id)[0]
      .legacyResourceId
  }

  return legacyId
}

/**
 * Convert variant Id to legacyResourceId
 * @param shopifyData - the fetched shopify data
 * @param id - product it
 * @returns legacyId - returns the legacyResourceId for the variant id sent as param
 * */
export const convertVariantIdToLegacyResourceId = (shopifyData, id) => {
  if (!id || !shopifyData) return

  let legacyId

  shopifyData.forEach(product => {
    if (product.variants.filter(variant => variant.shopifyId === id).length) {
      legacyId = product.variants.filter(variant => variant.shopifyId === id)[0]
        .legacyResourceId
    }
  })

  return legacyId
}
