import React from "react"

/**
 * @param shopifyData - shopifyData
 * @param shopifyVariant - data for the shopify variant
 * @param product - data for the static product
 * */

const GiftProductPrice = ({ shopifyVariant, product }) => {
  return (
    <div className="product__price product__price--sm">
      {shopifyVariant && product ? (
        <div>
          <div className="price price--regular">
            <span className="price__text">Regular Price:</span>
            <span className="price--strikethrough">
              ${Number(shopifyVariant.compareAtPrice).toFixed(2)}
            </span>
          </div>
          <div className="price">
            <span className="price__text">
              <i className="fas fa-chevron-double-right txt--pink mr--5" />
              Sale Price:
            </span>
            <strong className="fw--black txt--pink txt--20">FREE</strong>
          </div>
        </div>
      ) : (
        <span className="txt-loader" />
      )}
    </div>
  )
}
export default GiftProductPrice
