import React, { useEffect, useState } from "react"
import { isObjectEmpty } from "../../utilities/checkValidity"
import ProductPrice from "../ProductInfo/ProductInfoComponents/ProductPrice"
import productData from "../../../static/data/product"
import ProductPaymentOptionsImage from "./ProductPaymentOptionsImage"
const ProductPaymentOptions = ({
  shopifyVariant,
  product,
  paymentType,
  bundle,
  orderId,
  isImgVisible = false,
  handlePaymentType,
  handleChange,
  isPaymentPlan = false,
  paymentPlanPrice = 0,
  paymentPlanInterval = "",
  paymentPlanMonthly = "",
}) => {
  const defaultVariantPrice = Number(shopifyVariant.price)
  let discountedVariantPrice = ((Number(shopifyVariant.price) * 89.99) / 100).toFixed(2)

  // If there is discount percentage item, use that to calculate the discounted price
  if (product.discountPercentage) {
    discountedVariantPrice = ((Number(shopifyVariant.price) * (100 - +product.discountPercentage)) / 100).toFixed(2)
  }

  const [optionDescription, setOptionDescription] = useState("")
  useEffect(() => {
    if (!shopifyVariant || shopifyVariant?.title?.toLowerCase().indexOf("workout bundle") === -1) return

    let proteinType =
      shopifyVariant.selectedOptions[0].value.toLowerCase().indexOf("chocolate") !== -1 ? " Chocolate" : " Vanilla"
    let freeProteinType =
      shopifyVariant.selectedOptions[0].value.toLowerCase().indexOf("vanilla") !== -1 ? " Vanilla" : " Chocolate"

    const description = `${proteinType} Protein x1, Rock and Restore x1, <br class="d-none d-md-block d-lg-none d-xl-block" /> Full Body Collagen x1 <span class="txt--pink">I <i class="fas fa-heart txt--pink"></i> ${freeProteinType} Protein <strong>x1 (FREE)</strong></span>`

    setOptionDescription(description)
  }, [shopifyVariant])

  return (
    <div className="product__type">
      <div
        role="button"
        aria-label="One Time Payment."
        tabIndex={0}
        onClick={() => handlePaymentType("one")}
        onKeyDown={() => handlePaymentType("one")}
        className={`product__type__box ${paymentType === "one" ? "active" : ""}`}
      >
        <div className="product__type__wrapper">
          <div className={`product__type__content d-flex d-sm-block ${isPaymentPlan ? `d-sm-flex` : ""}`}>
            <div className={`input-field ${!isPaymentPlan ? "mb--15" : ""}`}>
              <input
                type="radio"
                name={`product-type-${orderId}`}
                value="one"
                id="oneTime"
                checked={paymentType === "one"}
                onChange={e => handleChange(e)}
              />
              <label htmlFor="oneTime">One-time payment</label>
            </div>

            {optionDescription.length > 0 && (
              <div className="d-none d-sm-inline-block">
                <p className="txt--14" dangerouslySetInnerHTML={{ __html: optionDescription }}></p>
              </div>
            )}

            {shopifyVariant ? (
              <p className="mb--0">
                <strong>${defaultVariantPrice}</strong>
              </p>
            ) : (
              <span className="txt-loader txt-loader--sm" />
            )}
          </div>

          {optionDescription.length > 0 && (
            <div className="d-inline-block d-sm-none mb--20">
              <p className="txt--14" dangerouslySetInnerHTML={{ __html: optionDescription }}></p>
            </div>
          )}

          <ProductPaymentOptionsImage
            isVertical={shopifyVariant.title?.toLowerCase().indexOf("workout bundle") !== -1}
            isImgVisible={isImgVisible}
            product={product}
          />
        </div>
      </div>

      <div
        role="button"
        aria-label="Subscription Payment."
        tabIndex={0}
        onClick={() => handlePaymentType("two")}
        onKeyDown={() => handlePaymentType("two")}
        className={`product__type__box ${paymentType === "two" ? "active" : ""}`}
      >
        <div className={`product__type__wrapper ${isPaymentPlan ? "product__type__wrapper--column" : ""}`}>
          <div className={`product__type__content ${isPaymentPlan ? `d-flex` : ""}`}>
            <div className="d-flex justify-content-between">
              <div className={`input-field ${!isPaymentPlan ? "mb--15" : ""}`}>
                <input
                  type="radio"
                  name={`product-type-${orderId}`}
                  value="two"
                  id="twoTime"
                  checked={paymentType === "two"}
                  onChange={e => handleChange(e)}
                />
                <label htmlFor="twoTime">
                  {!isPaymentPlan ? (
                    <>
                      {product.type === "bundles" ? (
                        <>
                          Get this discount on repeat with <span className="d-inline-block">Subscribe & Save</span>
                        </>
                      ) : (
                        "Subscribe & Save 10%"
                      )}{" "}
                      <small className="txt--pink">
                        (<i className="fas fa-star" /> Recommended)
                      </small>
                    </>
                  ) : (
                    <>
                      <span className="mr--5">Payment Plan</span>
                      {paymentPlanPrice ? <small className="txt--pink">(Save ${Number(paymentPlanPrice)})</small> : ""}
                    </>
                  )}
                </label>
              </div>
              {!isPaymentPlan && (
                <div className="d-inline-block d-sm-none">
                  {shopifyVariant ? (
                    <p className="txt--pink mb--0">
                      {product.type === "bundles" ? (
                        <strong>${defaultVariantPrice}</strong>
                      ) : (
                        <strong>${discountedVariantPrice}</strong>
                      )}
                    </p>
                  ) : (
                    <span className="txt-loader txt-loader--sm" />
                  )}
                </div>
              )}
            </div>

            {!isPaymentPlan && (
              <>
                {optionDescription.length > 0 ? (
                  <p className="txt--14" dangerouslySetInnerHTML={{ __html: optionDescription }}></p>
                ) : (
                  <p className="txt--14 mb--5">
                    Products are automatically delivered on your schedule. No obligation, modify or cancel your
                    subscription anytime.
                  </p>
                )}
              </>
            )}

            {isPaymentPlan ? (
              <p className="txt--pink lh--1 mb--0 txt--right">
                <strong>${paymentPlanMonthly} </strong>
                <small className="d-inline-block">x {paymentPlanInterval} months</small>
              </p>
            ) : (
              <div className="d-none d-sm-block">
                {shopifyVariant ? (
                  <p className="txt--pink mb--0">
                    {product.type === "bundles" ? (
                      <strong>${defaultVariantPrice}</strong>
                    ) : (
                      <strong>${discountedVariantPrice}</strong>
                    )}
                  </p>
                ) : (
                  <span className="txt-loader txt-loader--sm" />
                )}
              </div>
            )}
          </div>

          {isPaymentPlan && (
            <p className="txt--14 mb--0 mt--5">
              You will be charged ${paymentPlanMonthly} today, and an additional ${paymentPlanMonthly} next{" "}
              {paymentPlanInterval - 1 > 1 ? `${paymentPlanInterval - 1} months` : " month"}.
            </p>
          )}

          <ProductPaymentOptionsImage
            isImgVisible={isImgVisible}
            product={product}
            isVertical={true}
            discountText="10%"
          />
        </div>
      </div>

      {!isObjectEmpty(bundle) && (
        <div
          role="button"
          aria-label="Select bundle."
          tabIndex={0}
          onClick={() => handlePaymentType("three")}
          onKeyDown={() => handlePaymentType("three")}
          className={`product__type__box ${paymentType === "three" ? "active" : ""}`}
        >
          <div className="product__type__wrapper">
            <div className={`product__type__content ${isPaymentPlan ? `d-flex` : ""}`}>
              <div className="input-field mb--15">
                <input
                  type="radio"
                  name={`product-type-${orderId}`}
                  value="three"
                  id="bundle"
                  checked={paymentType === "three"}
                  onChange={e => handleChange(e)}
                />
                <label htmlFor="bundle">
                  Stock Up & Save 10% plus <span className="d-inline-block txt--pink">FREE Gift!</span>
                </label>
              </div>

              <p className="txt--14">
                Get a FREE Shaker Bottle and SAVE 10% when <span className="d-inline-block"> you buy 3.</span>
              </p>

              <ProductPaymentOptionsImage
                isImgVisible={isImgVisible}
                filenameSuffix="-offer-option-sdbr-mobile"
                product={product}
                modifierClass="product__type__img--limited-lg d-block d-sm-none"
                discountText={`FREE ${productData[product.bundleId].freeGiftType}`}
              />

              <ProductPrice
                shopifyVariant={bundle.variants[0]}
                product={productData[product.bundleId]}
                modifierClass={"product__price--sm"}
                isOffer={!isObjectEmpty(bundle)}
              />
            </div>
            <ProductPaymentOptionsImage
              isImgVisible={isImgVisible}
              filenameSuffix="-offer-option-sdbr"
              product={product}
              modifierClass="d-none d-sm-block"
              discountText={`FREE ${productData[product.bundleId].freeGiftType}`}
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default ProductPaymentOptions
