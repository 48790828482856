import React from "react"

// Utilities
import { slideUp, slideToggle } from "../../utilities/slide"

/**
 * @param title - heading of the accordion
 * @param children - content of the accordion
 * @param counter - the counter of the accordion  (optional)
 * @param icon - icon of the accordion (optional)
 * @param modifierClasses - extra classes for specific style
 * @param open - state of the accordion
 * @param img - image of the accordion (optional)
 * @param imgWrapperClass - class for the img (optional)
 * @param pillar - if there is a pillar  (optional)
 * @param handleActiveEl - handle the active elements of the accordion
 * @param number - number of the active element
 */

const AccordionItem = ({
  title,
  children,
  counter,
  icon,
  modifierClasses,
  open,
  img,
  imgWrapperClass,
  pillar,
  handleActiveEl,
  number,
}) => {
  /*============================
   HANDLE ACCORDION TOGGLE
   ============================*/
  const toggleAccordion = e => {
    e.preventDefault()

    if (img) {
      handleActiveEl && handleActiveEl(title.toLowerCase())
    }
    if (number) {
      handleActiveEl(number)
    }

    let accordionItem = e.target.closest(".accordion")
    accordionItem.classList.toggle("open")
    slideToggle(
      accordionItem.querySelector(".accordion__content"),
      accordionItem,
    )

    for (let sibling of accordionItem.parentNode.children) {
      if (sibling !== accordionItem) {
        sibling.classList.remove("open")
        slideUp(sibling.querySelector(".accordion__content"), sibling)
      }
    }
  }

  return (
    <div
      tabIndex={0}
      role="button"
      aria-label="Toggle Accordion."
      onClick={toggleAccordion}
      onKeyDown={toggleAccordion}
      className={`accordion ${icon ? "accordion--" + icon : ""} ${
        open ? "open" : ""
      } ${modifierClasses ? modifierClasses : ""}`}
    >
      <div className="accordion__header">
        {counter && <span className="accordion-counter" />}

        {/* icon before title */}
        {img && img.length > 0 && (
          <div
            className={`d-flex align-items-center ${
              imgWrapperClass && imgWrapperClass.length > 0 && imgWrapperClass
            }`}
          >
            <img loading="lazy" src={img} className="mr--10" alt="" />
          </div>
        )}

        {/*pillar accordion*/}
        {pillar && (
          <div className="pillar-img">
            <img
              src={`/images/supplements/bundles/icon-${pillar}.png`}
              className="mr--10 no-shadow"
              alt=""
            />
          </div>
        )}
        <h6 className="h6--supplements m--0">
          <strong
            className="fw--bold"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </h6>
      </div>
      <div
        className="accordion__content"
        style={{ display: open ? "block" : "none" }}
      >
        {children}
      </div>
    </div>
  )
}
export default AccordionItem
