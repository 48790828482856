import React, { useState, useEffect } from "react"

// Utilities
import {
  formatColorForUrlParam,
  formatExtractedColor,
  formatShopifyId,
  getUrlParam,
} from "../../utilities/formatString"
import { isObjectEmpty } from "../../utilities/checkValidity"
import { checkURLParams } from "../../utilities/checkURLParams"
import { checkOutOfStock } from "../../utilities/checkOutOfStock"

// Components
import Slider from "../Sliders/Slider"
import { Link } from "gatsby"
import AccordionItem from "../Accordions/AccordionItem"
import OutOfStock from "./ProductInfoComponents/OutOfStock"
import ShippingIcons from "./ProductInfoComponents/ShippingIcons"
import ProductInformation from "./ProductInfoComponents/ProductInformation"
import ProductPrice from "./ProductInfoComponents/ProductPrice"

// Data
import apparelData from "../../../static/data/apparel"
import GiftProductInformation from "../Gift/GiftProductInformation"
import GiftProductPrice from "../Gift/GiftProductPrice"
import Quantity from "../Quantity/Quantity"

/**
 * @param theme - theme,
 * @param isPopup - is in popup or not,
 * @param handleCloseQuickBuyModal - close popup,
 * @param shopifyProduct - product object from Shopify,
 * @param product - product from local JSON file,
 * @param handleOpenModalSizeGuide - open size guide popup with the current product,
 * @param selectedProductColor - selected product color,
 * @param selectedCartItem - selected cart item
 * @param isCart - whether it's opened from the cart
 **/
const ProductInfoApparel = ({
  theme,
  isPopup,
  handleCloseQuickBuyModal,
  shopifyProduct,
  product,
  handleOpenModalSizeGuide,
  selectedProductColor,
  selectedCartItem = {},
  isCart,
}) => {
  /*===========================================
    PRODUCT OPTIONS
  ===========================================*/
  const [productId, setProductId] = useState("")
  const [size, setSize] = useState([])
  const [isSizeText, setIsSizeText] = useState(false)
  const [sizeText, setSizeText] = useState("")
  const [isOneSize, setIsOneSize] = useState(false)
  const [color, setColor] = useState([])
  const [material, setMaterial] = useState("")
  const [care, setCare] = useState("")
  const [variantInfo, setVariantInfo] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)

  // Set quantity
  const [quantity, setQuantity] = useState(1)
  const handleQuantity = quantity => setQuantity(quantity)

  // Selected options
  const [selectedSize, setSelectedSize] = useState("")
  const [queryColor, setQueryColor] = useState()
  const [giftColor, setGiftColor] = useState("")
  const [selectedColor, setSelectedColor] = useState({ name: "", color: "" })

  // Popup
  const [isSizeGuide, setIsSizeGuide] = useState(true)

  // Cart item
  const [isInCart, setIsInCart] = useState(false)

  // Check out of stock
  const isOutOfStock = checkOutOfStock(shopifyProduct)

  // Set product options: size, color, material, variant data
  const handleProductOptions = shopifyProduct => {
    shopifyProduct.options.forEach(item => {
      if (item.name.toLowerCase() === "size") {
        let dimensions = [
          { size: "XS", isSize: item.values.indexOf("XS") !== -1 },
          { size: "S", isSize: item.values.indexOf("S") !== -1 },
          { size: "M", isSize: item.values.indexOf("M") !== -1 },
          { size: "L", isSize: item.values.indexOf("L") !== -1 },
          { size: "XL", isSize: item.values.indexOf("XL") !== -1 },
          { size: "2XL", isSize: item.values.indexOf("2XL") !== -1 },
        ]
        if (item.values[0].toLowerCase() === "os" || item.values[0].toLowerCase() === "one size") {
          setIsOneSize(true)
          setSelectedSize(item.values[0])
        }
        setSize(dimensions)

        if (item.values[0].indexOf('"') !== -1) {
          setIsSizeText(true)
          setSizeText(item.values)
          setSelectedSize(item.values[0])
        }
      }

      if (item.name.toLowerCase() === "color") {
        handleProductOptionsColor(item.values)
      }

      if (item.name.toLowerCase() === "material") {
        setMaterial(item.values[0])
      }
    })

    handleVariantInfo(shopifyProduct)
  }

  // Set variant data: id, size, color, isAvailableForSale
  const handleVariantInfo = shopifyProduct => {
    let shopifyVariants = []
    shopifyProduct.variants.forEach(item => {
      // Get variant id
      const variantId = formatShopifyId(item.shopifyId)
      // Get variant availability for sale
      const variantAvailableForSale = item.availableForSale
      // Get variant color
      const variantColor = item.selectedOptions.filter(item => {
        return item.name.toLowerCase() === "color"
      })[0].value
      // Get variant size
      const variantSize = item.selectedOptions.filter(item => {
        return item.name.toLowerCase() === "size"
      })[0].value

      shopifyVariants.push({
        id: variantId,
        availableForSale: variantAvailableForSale,
        size: variantSize,
        color: variantColor,
      })
    })
    setVariantInfo(shopifyVariants)
  }

  // Handle selected size
  const handleSelectedSize = size => {
    setSelectedSize(size)
    setIsErrorSize(false)
  }

  // Set product id and product options
  useEffect(() => {
    if (!shopifyProduct) return

    setProductId(formatShopifyId(shopifyProduct.shopifyId))

    // Set product options
    handleProductOptions(shopifyProduct)
  }, [shopifyProduct])

  // Set is in cart, quantity and selected size
  useEffect(() => {
    if (isObjectEmpty(selectedCartItem)) return

    // Set a flag for whether the product is in cart
    setIsInCart(true)

    // Set quantity from cart
    setQuantity(selectedCartItem.quantity)

    // Set size from cart
    setSelectedSize(selectedCartItem.options.size)
  }, [selectedCartItem])

  // Set care text
  useEffect(() => {
    if (!productId || !apparelData) return

    if (apparelData[productId]) {
      setCare(apparelData[productId].care)

      // Set popup
      if (apparelData[productId].sizeGuide) {
        setIsSizeGuide(true)
      } else {
        setIsSizeGuide(false)
      }
    }

    if (productId === theme.giftId) {
      const selectedGift = theme.cart.filter(product => product.productId === theme.giftId)[0]
      setSelectedSize(selectedGift.options.size)
      setGiftColor(selectedGift.options.color)
      setIsInCart(true)
    }
  }, [productId])

  useEffect(() => {
    if (!quantity) return
    setTotalPrice(shopifyProduct.variants[0].price * quantity)
  }, [quantity])

  /*=========================
      URL PARAMETER
      - if the product has multiple colors, add ?color parameter to the link
   =========================*/
  const urlParam = getUrlParam(shopifyProduct, selectedColor.name)

  /*===========================================
   HANDLE MOBILE ACCORDION
 ===========================================*/
  const [accordionTitle, setAccordionTitle] = useState("")

  // Set mobile accordion title
  useEffect(() => {
    let title = ""

    if (material.length) {
      title = "Material"

      if (care && care.length) {
        title += " and Care"
      }
    } else {
      if (care && care.length) {
        title += "Care"
      }
    }
    setAccordionTitle(title)
  }, [material, care])

  /*===========================================
   HANDLE SUBMIT
   ===========================================*/
  // Set error
  const [isErrorSize, setIsErrorSize] = useState(false)
  const [isErrorColor, setIsErrorColor] = useState(false)

  const handleSubmit = theme => {
    let variantId = ""
    let price = ""

    // Setting errors
    if (!selectedSize.length) {
      setIsErrorSize(true)
    }
    if (!selectedColor.name) {
      setIsErrorColor(true)
    }

    if (selectedSize.length && selectedColor.name && quantity > 0) {
      shopifyProduct.variants.forEach((item, index) => {
        let isItem = true
        item.selectedOptions.forEach((option, index) => {
          if (option.name.toLowerCase() === "color") {
            if (option.value.toLowerCase() !== selectedColor.name.toLowerCase()) {
              isItem = false
            }
          }
          if (option.name.toLowerCase() === "size") {
            if (option.value.toLowerCase() !== selectedSize.toLowerCase()) {
              isItem = false
            }
          }
        })

        if (isItem) {
          variantId = formatShopifyId(item.shopifyId)
          price = item.price
        }
      })

      const title = shopifyProduct.title
      const sellingPlan = "No"
      const type = apparelData[productId].type

      const selectedOptions = {
        color: selectedColor.name,
        size: selectedSize,
        material: material,
      }

      theme.addVariantToCart(
        variantId,
        productId,
        quantity,
        price,
        sellingPlan,
        title,
        type,
        selectedOptions,
        selectedCartItem,
        isCart,
      )
      if (isPopup) {
        handleCloseQuickBuyModal()
      }
    }
  }

  // Remove overflow
  const handleOverflow = () => {
    document.body.classList.remove("overflow--hidden")
  }

  /*===========================================
   HANDLE URL PARAMS
   ===========================================*/
  useEffect(() => {
    const parameters = checkURLParams()

    if (parameters.hasOwnProperty("color")) {
      const color = formatExtractedColor(parameters["color"])
      setQueryColor(color)
    }
  }, [])

  /*===========================================
    HANDLE SELECTED COLOR
  ===========================================*/

  // Map color names to their hex
  const handleProductOptionsColor = item => {
    let colors = []
    item.forEach(color => {
      let colorHex = ""

      switch (color.toLowerCase()) {
        case "desert pink":
          colorHex = "#DCABB3"
          break
        case "tahiti blue":
          colorHex = "#00AEC6"
          break
        case "heathered grey/black":
          colorHex = "#868AA3"
          break
        case "dusty rose":
          colorHex = "#CB8283"
          break
        case "charcoal black":
          colorHex = "#212121"
          break
        case "royal blue":
          colorHex = "#4169E1"
          break
        case "athletic grey":
          colorHex = "#D1D2D4"
          break
        case "dark gray":
          colorHex = "#404040"
          break
        case "dark grey":
          colorHex = "#404040"
          break
        case "dark grey/black":
          colorHex = "#404040"
          break
        case "grey/white/pink ombre":
          colorHex = "#757682"
          break
        case "teal":
          colorHex = "#4A9FBE"
          break
        case "teal 1":
          colorHex = "#4A9FBE"
          break
        case "teal 2":
          colorHex = "#4A9FBE"
          break
        case "black":
          colorHex = "#000000"
          break
        case "vintage black":
          colorHex = "#060C1F"
          break
        case "vintage hot pink":
          colorHex = "#EA3B7A"
          break
        case "heather grey":
          colorHex = "#404040"
          break
        case "purple rush":
          colorHex = "#8268C4"
          break
        case "stonewash denim":
          colorHex = "#ACBDCF"
          break
        case "antique denim (grey)":
          colorHex = "#303848"
          break
        case "antique denim grey":
          colorHex = "#303848"
          break
        case "antique denim_grey":
          colorHex = "#303848"
          break
        case "storm grey":
          colorHex = "#79779D"
          break
        default:
          break
      }

      colors = [...colors, { name: color, color: colorHex }]
    })

    setColor(colors)
  }

  // Handle selected color
  const handleSelectedColor = item => {
    setSelectedColor(item)

    // Update URL color on single product
    if (!isPopup) {
      const params = new URLSearchParams(window.location.search)
      params.set("color", formatColorForUrlParam(item.name))
      window.history.replaceState({}, "", `${window.location.pathname}?${params}`)
    }

    const colorSizeChange = variantInfo.filter(
      variant => variant.color.toLowerCase() === item.name.toLowerCase() && selectedSize === variant.size,
    )

    if (colorSizeChange.length && !colorSizeChange[0].availableForSale) {
      setSelectedSize("")
    }
  }

  // Set the first color selected
  useEffect(() => {
    if (!color.length) return

    let defaultColor

    // If there is query parameter, set it as default color
    if (queryColor && queryColor.length) {
      if (color.filter(item => item.name.toLowerCase() === queryColor.toLowerCase()).length) {
        defaultColor = queryColor
      }
    }

    // If there is state selectedProductColor, set it as default
    if (selectedProductColor && selectedProductColor.length) {
      defaultColor = selectedProductColor
    }

    // If there is state giftColor, set it as default
    if (giftColor && giftColor.length) {
      defaultColor = giftColor
    }

    if (defaultColor) {
      color.forEach(item => {
        if (item.name.toLowerCase() === defaultColor.toLowerCase()) {
          setSelectedColor({ name: item.name, color: item.color })
        }
      })
      // If there is neither, set the first as default
    } else {
      setSelectedColor({ name: color[0].name, color: color[0].color })
    }
  }, [color, selectedProductColor, giftColor, queryColor])

  /*===========================================
    PRODUCT GALLERY
  ===========================================*/
  const [gallery, setGallery] = useState([])
  const [model, setModel] = useState("")
  const [selectedGalleryItem, setSelectedGalleryItem] = useState(0)
  const [showThumbs, setShowThumbs] = useState(true)
  const [showIndicators, setShowIndicators] = useState(false)

  // Set gallery images
  const handleGalleryImages = (shopifyProduct, selectedColor) => {
    let images = []
    setGallery(images)
    if (shopifyProduct.media.length > 1) {
      shopifyProduct.media.forEach(media => {
        let formattedColor = formatExtractedColor(media.image.originalSrc)

        if (product.type === "apparel") {
          if (formattedColor.toLowerCase() === selectedColor.toLowerCase()) {
            images = [...images, media.image]
          }
        } else {
          images = [...images, media.image]
        }
      })
      setModel(images[0].altText ? images[0].altText : "")
      setSelectedGalleryItem(0)
      setGallery(images)
    }
  }

  // Update gallery according to color
  useEffect(() => {
    if (!selectedColor.name.length || !shopifyProduct) return
    // Set gallery
    handleGalleryImages(shopifyProduct, selectedColor.name)
  }, [selectedColor.name])

  // Set model by getting current slide
  const slideChange = (index, item) => {
    setModel(item.props.alt ? item.props.alt : "")
    setSelectedGalleryItem(index)
    updateThumbVisibility(index, item)
  }

  // Move the clicked thumb to be visible
  const onClickThumb = (index, item) => {
    updateThumbVisibility(index, item)
  }

  // Update the thumb visibility and move them up/down
  const updateThumbVisibility = (index, item) => {
    if (showThumbs) {
      const thumbsAnimated = document.querySelectorAll(".thumbs-wrapper .thumbs")[0]
      const thumbs = document.querySelectorAll(".thumbs-wrapper .thumb")

      const thumbsAnimatedHeight = thumbsAnimated.clientHeight

      let selectedItem
      thumbs.forEach((thumb, i) => {
        if (i === index) {
          selectedItem = thumb
        }
      })

      let difference = thumbsAnimatedHeight - (selectedItem.offsetTop + selectedItem.clientHeight)

      let itemsSize = thumbs.length * selectedItem.clientHeight

      if (selectedItem.offsetTop === 0) {
        thumbsAnimated.style.marginTop = `0px`
      }

      if (difference < 90 && itemsSize > thumbsAnimatedHeight) {
        thumbsAnimated.style.marginTop = `${difference - 90}px`
      }

      if (difference >= thumbsAnimatedHeight / 2) {
        thumbsAnimated.style.marginTop = `0px`
      }
    }
  }

  // Hide/show dots/thumbs on resize
  useEffect(() => {
    window.addEventListener("resize", checkWidth)
    checkWidth()
  })

  // Check screen width in order to hide/show dots/thumbs
  const checkWidth = () => {
    if (window.innerWidth < 576) {
      setShowIndicators(true)
      setShowThumbs(false)
    } else {
      setShowIndicators(false)
      setShowThumbs(true)
    }
  }

  return (
    <>
      <div className="row">
        {/*gallery*/}
        <div className="col-12 col-lg-6">
          <div className="product-images product-images--apparel">
            {shopifyProduct.variants[0].compareAtPrice && shopifyProduct.shopifyId !== theme.giftId && (
              <div className="product-onsale">
                <i className="fas fa-siren-on" /> sale
              </div>
            )}

            {gallery.length > 0 && (
              <>
                {gallery.length === 1 && (
                  <img loading="lazy" src={gallery[0].originalSrc} alt={gallery[0].altText} className="no-shadow" />
                )}
                {gallery.length > 1 && (
                  <Slider
                    axis={"horizontal"}
                    modifierClass="product-carousel product-carousel--vertical"
                    showIndicators={showIndicators}
                    selectedItem={selectedGalleryItem}
                    autoPlay={false}
                    showThumbs={showThumbs}
                    thumbWidth={70}
                    thumbHeight={90}
                    onChange={slideChange}
                    onClickThumb={onClickThumb}
                  >
                    {gallery.map((item, index) => (
                      <img
                        key={`slider-${index}`}
                        src={item.originalSrc}
                        alt={item.altText}
                        width={480}
                        height={660}
                        className="no-shadow"
                      />
                    ))}
                  </Slider>
                )}
              </>
            )}
          </div>
        </div>
        {/*content*/}
        <div className="col-12 col-lg-6">
          <div className="product">
            {product && (
              <>
                {shopifyProduct.shopifyId !== theme.giftId ? (
                  <ProductInformation product={product} shopifyProduct={shopifyProduct} />
                ) : (
                  <>
                    <h2 className="product__title" dangerouslySetInnerHTML={{ __html: shopifyProduct.title }} />
                    <GiftProductInformation product={product} shopifyProduct={shopifyProduct} />
                  </>
                )}
              </>
            )}

            {/*PRICE*/}
            {shopifyProduct.shopifyId !== theme.giftId ? (
              <ProductPrice
                shopifyVariant={shopifyProduct.variants[0]}
                product={product}
                totalPrice={totalPrice}
                sellingPlan="No"
              />
            ) : (
              <GiftProductPrice shopifyVariant={shopifyProduct.variants[0]} product={product} />
            )}

            {!isOutOfStock ? (
              <>
                {/*PRODUCT OPTIONS*/}
                <div className={"apparel-info"}>
                  {/*SIZE */}
                  <div className="apparel-info__item">
                    <div className="apparel-info__label mt--5">Size:</div>
                    {!isSizeText ? (
                      <div className="apparel-info__content">
                        {/*SIZE*/}
                        <div className="apparel-info__size">
                          {isOneSize ? (
                            <button
                              className={`apparel-info__size__btn active ${
                                variantInfo.filter(
                                  variant =>
                                    "OS" === variant.size &&
                                    selectedColor.name.toLowerCase() === variant.color.toLowerCase(),
                                ).length &&
                                !variantInfo.filter(
                                  variant =>
                                    "OS" === variant.size &&
                                    selectedColor.name.toLowerCase() === variant.color.toLowerCase(),
                                )[0].availableForSale
                                  ? "tooltip tooltip--xs"
                                  : ""
                              }`}
                              data-tooltip={
                                variantInfo.filter(
                                  variant =>
                                    "OS" === variant.size &&
                                    selectedColor.name.toLowerCase() === variant.color.toLowerCase(),
                                ).length &&
                                !variantInfo.filter(
                                  variant =>
                                    "OS" === variant.size &&
                                    selectedColor.name.toLowerCase() === variant.color.toLowerCase(),
                                )[0].availableForSale
                                  ? "Out of Stock"
                                  : ""
                              }
                              disabled={
                                variantInfo.filter(
                                  variant =>
                                    "OS" === variant.size &&
                                    selectedColor.name.toLowerCase() === variant.color.toLowerCase(),
                                ).length &&
                                !variantInfo.filter(
                                  variant =>
                                    "OS" === variant.size &&
                                    selectedColor.name.toLowerCase() === variant.color.toLowerCase(),
                                )[0].availableForSale
                              }
                            >
                              One Size
                            </button>
                          ) : (
                            <>
                              {size.map((item, index) => (
                                <React.Fragment key={`size-${index}`}>
                                  {item.isSize && (
                                    <button
                                      key={index}
                                      className={`apparel-info__size__btn ${
                                        selectedSize === item.size ? "active" : ""
                                      } ${
                                        variantInfo.filter(
                                          variant =>
                                            item.size === variant.size &&
                                            selectedColor.name.toLowerCase() === variant.color.toLowerCase(),
                                        ).length &&
                                        !variantInfo.filter(
                                          variant =>
                                            item.size === variant.size &&
                                            selectedColor.name.toLowerCase() === variant.color.toLowerCase(),
                                        )[0].availableForSale
                                          ? "tooltip tooltip--xs out-of-stock"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        handleSelectedSize(item.size)
                                      }}
                                      data-tooltip={
                                        variantInfo.filter(
                                          variant =>
                                            item.size === variant.size &&
                                            selectedColor.name.toLowerCase() === variant.color.toLowerCase(),
                                        ).length &&
                                        !variantInfo.filter(
                                          variant =>
                                            item.size === variant.size &&
                                            selectedColor.name.toLowerCase() === variant.color.toLowerCase(),
                                        )[0].availableForSale
                                          ? "Out of Stock"
                                          : ""
                                      }
                                      disabled={
                                        variantInfo.filter(
                                          variant =>
                                            item.size === variant.size &&
                                            selectedColor.name.toLowerCase() === variant.color.toLowerCase(),
                                        ).length &&
                                        !variantInfo.filter(
                                          variant =>
                                            item.size === variant.size &&
                                            selectedColor.name.toLowerCase() === variant.color.toLowerCase(),
                                        )[0].availableForSale
                                      }
                                    >
                                      {item.size}
                                    </button>
                                  )}
                                </React.Fragment>
                              ))}
                            </>
                          )}
                        </div>
                        {isErrorSize && (
                          <div className="apparel-error">Please select from the available size options</div>
                        )}
                        {/*SIZE GUIDE*/}
                        {isSizeGuide && (
                          <button
                            onClick={() => handleOpenModalSizeGuide(shopifyProduct)}
                            className="link link--xs link--noline font-lato mb--10"
                          >
                            <u>Size guide</u>
                          </button>
                        )}
                        {/*IMG MODEL*/}
                        {model && model.length > 0 && (
                          <p className="txt--14">
                            <i className="fas fa-user txt--pink mr--5" /> {model}
                          </p>
                        )}
                      </div>
                    ) : (
                      <div className="apparel-info__content">{sizeText}</div>
                    )}
                  </div>

                  {/*COLOR*/}
                  <div className={`apparel-info__item`}>
                    <div className="apparel-info__label">Color:</div>
                    <div>
                      <div className="apparel-info__content d-flex flex-wrap">
                        {!isSizeText && (
                          <div className="apparel-info__color">
                            {color.map((item, index) => (
                              <button
                                key={`color-${index}`}
                                className={`apparel-info__color__btn ${
                                  selectedColor.name === item.name ? "active" : ""
                                }`}
                                style={{ borderColor: item.color }}
                                onClick={() => handleSelectedColor(item)}
                              >
                                <span style={{ background: item.color }} />
                              </button>
                            ))}
                          </div>
                        )}
                        <span className="txt--capitalize">{selectedColor.name}</span>
                      </div>
                      {isErrorColor && (
                        <div className="apparel-error">Please select from the available color options</div>
                      )}
                    </div>
                  </div>

                  {/*QUANTITY*/}
                  {shopifyProduct.shopifyId !== theme.giftId && (
                    <>
                      <Quantity
                        quantity={quantity}
                        handleQuantity={handleQuantity}
                        modifierClass="apparel-info"
                        size="md"
                      />
                      {showThumbs ? (
                        <>
                          {/*MATERIAL*/}
                          {material && material.length > 0 && (
                            <div className="apparel-info__item">
                              <div className="apparel-info__label">Material:</div>
                              <div className="apparel-info__content">{material}</div>
                            </div>
                          )}

                          {/*CARE*/}
                          {care && care.length > 0 && (
                            <div className="apparel-info__item">
                              <div className="apparel-info__label">Care:</div>
                              <div className="apparel-info__content">{care}</div>
                            </div>
                          )}
                        </>
                      ) : (
                        ((material && material.length > 0) || (care && care.length > 0)) && (
                          <AccordionItem
                            title={accordionTitle}
                            counter={false}
                            icon={"plus"}
                            modifierClasses="accordion-apparel accordion--xs"
                          >
                            <div className="accordion-apparel__content">
                              {/*MATERIAL*/}
                              {material && material.length > 0 && (
                                <div className="apparel-info__item">
                                  <div className="apparel-info__label">Material:</div>
                                  <div className="apparel-info__content">{material}</div>
                                </div>
                              )}

                              {/*CARE*/}
                              {care && care.length > 0 && (
                                <div className="apparel-info__item">
                                  <div className="apparel-info__label">Care:</div>
                                  <div className="apparel-info__content">{care}</div>
                                </div>
                              )}
                            </div>
                          </AccordionItem>
                        )
                      )}
                    </>
                  )}
                </div>

                {isPopup ? (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex align-items-center justify-content-center info-buttons">
                      <button onClick={() => handleSubmit(theme)} className="btn btn--md mb--10 full-width-xs">
                        {isInCart ? "update cart" : "add to cart"}
                      </button>

                      <Link
                        to={`/products/${shopifyProduct.handle}${urlParam || ""}`}
                        state={{ selectedProductColor: selectedColor.name }}
                        onClick={handleOverflow}
                        className="btn btn--md btn--outline ml--10 mb--10"
                      >
                        Learn More
                      </Link>
                    </div>
                    <div className="d-flex justify-content-between align-items-center flex-wrap flex-column flex-sm-row">
                      <img
                        src="/images/credit-cards.png"
                        alt=""
                        width="300"
                        height="39"
                        className="modal__credit-cards no-shadow"
                      />
                      <ShippingIcons modifierClass="ml--10" />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center flex-column flex-sm-row flex-wrap justify-content-center justify-content-lg-start">
                    <button onClick={() => handleSubmit(theme)} className="btn btn--md mb--10 full-width-xs">
                      {isInCart ? "update cart" : "add to cart"}
                    </button>
                    <ShippingIcons modifierClass="ml--sm-30 mb--10" />
                  </div>
                )}
              </>
            ) : (
              <OutOfStock box={true} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default ProductInfoApparel
