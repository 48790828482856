import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// Component
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Image = props => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  `)

  const image = data.images.edges.filter(n => props.filename === n.node.name)
  if (!image.length) return

  return (
    <>
      {image.length && image[0].node.childImageSharp && (
        <GatsbyImage
          image={getImage(image[0].node)}
          alt={`${image[0].name}.`}
          objectFit="cover"
          objectPosition="50% 50%"
          className={props.className}
        ></GatsbyImage>
      )}
    </>
  )
}

export default Image
