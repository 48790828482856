import React from "react"

const GiftProductInformation = () => {
  return (
    <>
      <p className="gift__product__text m--0">
        Thanks for being an amazing customer! Select your size then Add to Cart.{" "}
        <strong className="d-inline-block txt--pink">It's 100% FREE!</strong>
      </p>
      <p className="gift__product__text--sm txt--gray mb--10">
        *while supplies last
      </p>
    </>
  )
}
export default GiftProductInformation
