// Data
import productData from "../../static/data/product"
import apparelData from "../../static/data/apparel"

/**
 * @param id - product id
 * @returns icon - the corresponding icon for the current product type
 * */
export const handleProductIconById = id => {
  let icon = ""

  if (productData.hasOwnProperty(id)) {
    icon =
      (productData[id].type.toLowerCase() === "workout plan" && "flex") ||
      (productData[id].type.toLowerCase() === "meal plan" && "utensils") ||
      (productData[id].type.toLowerCase() === "supplements" && "leaf") ||
      (productData[id].type.toLowerCase() === "bundles" && "leaf")
  }

  if (apparelData.hasOwnProperty(id)) {
    icon =
      (apparelData[id].type.toLowerCase() === "apparel" && "tshirt") ||
      (apparelData[id].type.toLowerCase() === "merchandise" && "tag")
  }

  return icon
}

/**
 * @param type - product type
 * @returns icon - the corresponding icon for the current product type
 * */
export const handleProductIconByType = type => {
  return (
    (type.toLowerCase() === "workout plan" && "flex") ||
    (type.toLowerCase() === "meal plan" && "utensils") ||
    (type.toLowerCase() === "supplements" && "leaf") ||
    (type.toLowerCase() === "bundles" && "leaf") ||
    (type.toLowerCase() === "apparel" && "tshirt") ||
    (type.toLowerCase() === "merchandise" && "tag")
  )
}

/**
 * @param tab - product title
 * @returns tab - removes dashes and replaces with empty places and adds heart if vanilla or chocolate protein
 * */
export const formatTabs = tab => {
  // if the tab is a vanilla or chocolate protein
  let formattedTab = tab.replaceAll("-", " ")

  if (formattedTab === "vanilla protein" || formattedTab === "chocolate protein") {
    formattedTab = `I <i class="fas fa-heart txt--pink"></i> ${formattedTab}`
  }

  return formattedTab
}

/**
 * @param title - product title
 * @param shopifyProduct - the current shopify product
 * @returns title - replace heart with font awesome icon or returns original title
 * */
export const formatProductTitle = (title, shopifyProduct) => {
  // If the title contains heart, replace the heart with fontawesome heart
  if (title.indexOf("❤") !== -1) {
    title = title.replace("❤", '<i class="fas fa-heart txt--pink"></i>')
  }

  // If the title is workout bundle, add color to + free protein
  if (title.indexOf("Workout Bundle") !== -1) {
    const titleParts = title.split("(")
    title = `${titleParts[0]} <span class="product__title--sm txt--pink">(${titleParts[1]}</span>`
  }

  // Remove Aminos from Rock and Restore title
  if (title.toLowerCase().includes("rock and restore aminos")) {
    title = title.toLowerCase().replace("rock and restore aminos", "rock and restore")
  }

  // If the title contains buy 3 get one free, add color to it
  if (title.toLowerCase().includes("buy 3 get one free")) {
    title = title
      .toLowerCase()
      .replace("(buy 3 get one free)", '<span class="product__title--sm txt--pink">(Buy 3 Get One Free)</span>')
  }

  // if the title contains buy 3 get 1 free, add color to it
  if (title.toLowerCase().includes("buy 3 get 1 free")) {
    title = title
      .toLowerCase()
      .replace("(buy 3 get 1 free)", '<span class="product__title--sm txt--pink">(Buy 3 Get One Free)</span>')
  }

  // if the title contains protein bundle
  if (shopifyProduct.tags.indexOf("promo") !== -1 && title.toLowerCase().includes("protein bundle")) {
    title = title
      .toLowerCase()
      .replace("protein bundle", '<span class="d-inline-block txt--capitalize txt--pink">Protein Bundle</span>')
    title = `<span class="txt--uppercase">${title}</span>`
  }

  return title
}

/**
 * @returns title - remove trademark in title
 * */
export const removeTM = title => {
  return title.replace("™", "")
}

/**
 * @param shopifyProduct - the data of the product
 * @param cartItem - the data from the localStorage
 * @returns message - formatted product description
 * */
export const formatProductDescriptionCartMessage = (cartItem, shopifyProduct) => {
  let message = ""

  if (cartItem.type !== "apparel" && cartItem.type !== "merchandise") {
    // If there are subscription options, set the message according to the selected option (subscribed or payment plan)
    if (cartItem.selling_plan !== "No") {
      if (productData.hasOwnProperty(cartItem.variantId)) {
        if (productData[cartItem.variantId].sellingPlans) {
          productData[cartItem.variantId].sellingPlans.forEach(plan => {
            if (Number(cartItem.selling_plan) === Number(plan.sellingPlanId)) {
              if (
                productData[cartItem.variantId].type === "supplements" ||
                productData[cartItem.variantId].type === "bundles"
              ) {
                message = `<strong>Subscribed:</strong> ${plan.sellingPlanName}`
              } else {
                message = `<strong>Payment Plan:</strong> ${plan.months} ${
                  plan.months > 1 ? "months" : "month"
                } x $${Math.round(plan.price / plan.months).toFixed(2)}`
              }
            }
          })
        }
      }
    } else {
      // If there is no subscription, but there are options, check if any is selected, and use the name of it for the message
      shopifyProduct.variants.forEach(variant => {
        if (cartItem.variantId === variant.shopifyId && variant.selectedOptions.length > 0) {
          message = variant.selectedOptions[0].value

          // Add a second selected option if there is one
          if (variant.selectedOptions[1]) message += ` / ${variant.selectedOptions[1].value}`
        }
      })

      // If there is no selected option and no subscription plan, set it to one type payment
      if (message.toLowerCase() === "default title") message = "One Type Payment Plan"
    }
  }

  return message
}

/**
 *  @param cartItem - the data from the localStorage
 *  @param shopifyProduct - the data from the shopify product
 *  @returns message - formatted product description
 * */
export const formatProductDescriptionSidebarMessage = (cartItem, shopifyProduct) => {
  let message = ""

  if (productData.hasOwnProperty(cartItem.variantId)) {
    // If the product has selling plans
    if (productData[cartItem.variantId].sellingPlans) {
      productData[cartItem.variantId].sellingPlans.forEach(plan => {
        if (Number(cartItem.selling_plan) === Number(plan.sellingPlanId)) {
          if (
            productData[cartItem.variantId].type === "supplements" ||
            productData[cartItem.variantId].type === "bundles"
          ) {
            message = `Subscribed - ${plan.sellingPlanName}`
          } else {
            message = plan.sellingPlanName
          }
        }
      })
    } else {
      // If there are options, check if any is selected, and use the name of it for the message
      shopifyProduct.variants.forEach(variant => {
        if (cartItem.variantId === variant.shopifyId && variant.selectedOptions.length > 0) {
          message = variant.selectedOptions[0].value

          // Add a second selected option if there is one
          if (variant.selectedOptions[1]) message += ` / ${variant.selectedOptions[1].value}`
        }
      })

      if (message.toLowerCase() === "default title") message = ""
    }
  }

  return message
}
