import React, { useEffect } from "react"

// Providers
import ThemeContext from "../../context/ThemeContext"

// Components
import ProductInfo from "../ProductInfo/ProductInfo"
import ProductInfoApparel from "../ProductInfo/ProductInfoApparel"

/**
 * Quick Buy Modal
 * @param handleCloseQuickBuyModal - close the quick buy modal
 * @param shopifyProduct - shopify data for the product
 * @param product - product from {product/apparel}Data for the product
 * @param handleOpenModalSizeGuide - open the size quide modal
 * @param selectedProductColor - selected color of the product
 * @param selectedCartItem - selected cart item (if on cart page)
 * @param isCart - is the modal being opened from the cart or not
 * */
const ModalQuickBuy = ({
  handleCloseQuickBuyModal,
  shopifyProduct,
  product,
  handleOpenModalSizeGuide,
  selectedProductColor,
  selectedCartItem,
  isCart,
}) => {
  // Check if it's product or apparel/merchandise
  product =
    Object.keys(product).length > 1 ? product : Object.values(product)[0]

  /*========================
    CUSTOM GTM EVENT
  ========================*/
  useEffect(() => {
    if (typeof window !== "undefined") {
      document.body.classList.add("overflow--hidden")

      // push custom event to GTM
      if (window.dataLayer) {
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: "view_item",
          ecommerce: {
            currency: "USD",
            value: 0.0,
            items: [
              {
                item_id: shopifyProduct.variants[0].shopifyId,
                item_name: shopifyProduct.title,
                currency: "USD",
                item_brand:
                  product.type === "supplements" || product.type === "bundles"
                    ? "Whole Betty"
                    : product.type === "apparel" ||
                        product.type === "merchandise"
                      ? "Betty Rocker"
                      : "The Betty Rocker",
                item_category: product.type,
                price: +shopifyProduct.variants[0].price,
                quantity: 1,
              },
            ],
          },
        })
      }
    }
  }, [])

  return (
    <ThemeContext.Consumer>
      {theme => (
        <>
          <span
            role="button"
            onKeyDown={handleCloseQuickBuyModal}
            onClick={handleCloseQuickBuyModal}
            tabIndex={0}
            aria-label="Close Modal."
            className="overlay"
          />
          <div className="modal modal--xl modal-product">
            <button
              onClick={handleCloseQuickBuyModal}
              aria-label="Close Modal."
              className="modal__close"
            >
              <i className="fal fa-times" />
            </button>
            {product.collection === "apparel" ||
            product.collection === "merchandise" ? (
              <ProductInfoApparel
                theme={theme}
                isPopup={true}
                handleCloseQuickBuyModal={handleCloseQuickBuyModal}
                shopifyProduct={shopifyProduct}
                product={product}
                handleOpenModalSizeGuide={handleOpenModalSizeGuide}
                selectedProductColor={
                  product.collection === "apparel" ? selectedProductColor : ""
                }
                selectedCartItem={selectedCartItem}
                isCart={isCart}
              />
            ) : (
              <ProductInfo
                theme={theme}
                isPopup={true}
                handleCloseQuickBuyModal={handleCloseQuickBuyModal}
                shopifyProduct={shopifyProduct}
                product={product}
                selectedCartItem={selectedCartItem}
                isCart={isCart}
              />
            )}
          </div>
        </>
      )}
    </ThemeContext.Consumer>
  )
}
export default ModalQuickBuy
