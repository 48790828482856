import React from "react"

// Provider
import ThemeContext, { ThemeProvider } from "./src/context/ThemeContext"

// Hooks
import queryString from "query-string"

// Components
import ErrorBoundary from "./src/components/ErrorBoundary/ErrorBoundaryContainer"
import Layout from "./src/components/Layout/Layout"
import Loader from "./src/components/Loader/Loader"

const isBrowser = typeof window !== "undefined"

export const onClientEntry = () => {
  // Handle the route parameter destructuring
  const { query } = isBrowser && queryString.parse(window.location.href)

  // If the query has a "loc" object, use it
  if (query && query.loc) {
    if (query.loc === "US") {
      window.localStorage.setItem("country", "US")
      window.localStorage.setItem("shipping", true)
    } else if (query.loc === "CA") {
      window.localStorage.setItem("country", "CA")
      window.localStorage.setItem("shipping", true)
    } else if (query.loc === "GB") {
      window.localStorage.setItem("country", "GB")
      window.localStorage.setItem("shipping", true)
    } else {
      window.localStorage.setItem("country", "worldwide")
      window.localStorage.setItem("shipping", false)
    }
  }

  // Read the saved country (if it exists) from local storage
  const country = window.localStorage.getItem("country") || ""
  const shipping = window.localStorage.getItem("shipping")

  // If there's no user location saved in the local storage, fetch it from the API
  if (
    !country ||
    !country.length ||
    shipping === null ||
    shipping === undefined
  ) {
    fetch(
      "https://api.ipstack.com/check?access_key=a633d969f5f75cdd67c3b53fc4225ebf&fields=country_code",
      {
        method: "GET",
      },
    )
      .then(res => res.json())
      .then(data => {
        if (data.country_code === "US") {
          window.localStorage.setItem("country", "US")
          window.localStorage.setItem("shipping", true)
        } else if (data.country_code === "CA") {
          window.localStorage.setItem("country", "CA")
          window.localStorage.setItem("shipping", true)
        } else if (data.country_code === "GB") {
          window.localStorage.setItem("country", "GB")
          window.localStorage.setItem("shipping", true)
        } else {
          window.localStorage.setItem("country", "worldwide")
          window.localStorage.setItem("shipping", false)
        }
      })
      .catch(rejected => {
        if (rejected) {
          window.localStorage.setItem("country", "US")
          window.localStorage.setItem("shipping", true)
        }
      })
  }
}

const Wrapper = ({ element, props }) => {
  return (
    <ErrorBoundary>
      <ThemeProvider>
        <ThemeContext.Consumer>
          {theme => (
            <React.Fragment>
              {theme.pageLoader && <Loader isLoader={theme.pageLoader} />}
              {element && (
                <Layout theme={theme} {...props}>
                  {element}
                </Layout>
              )}
            </React.Fragment>
          )}
        </ThemeContext.Consumer>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export const wrapPageElement = Wrapper
