import React from "react"

// Data
import productData from "../../../../static/data/product"

// Components
import ProductPaymentOptions from "../../ProductPaymentOptions/ProductPaymentOptions"
import Image from "../../Image/Image"

/**
 * @param shopifyProduct - data for the shopify product
 * @param product - static data for the product
 * @param orderId - the order of the component
 * @param isImgVisible - is the image visible
 * @param selectedVariant - the currently selected variant
 * @param selectedVariantId - the currently selected variant id
 * @param paymentType - the currently selected payment type
 * @param handleVariantSelect - handling the variant selection
 * @param handlePaymentType - handling selecting payment type
 * @param handleChange - handle payment type change
 * */
const WorkoutBundleOptions = ({
  shopifyProduct,
  product,
  orderId,
  isImgVisible,
  selectedVariant,
  selectedVariantId,
  paymentType,
  handleVariantSelect,
  handlePaymentType,
  handleChange,
}) => {
  return (
    <>
      <div className="row gutters-5 mb--10">
        {Object.keys(productData).map(item => (
          <React.Fragment key={`bundle-option-${item}`}>
            {shopifyProduct.variants.map(variant => (
              <React.Fragment key={`${variant.shopifyId}-${orderId}`}>
                {variant.shopifyId === item && (
                  <div className="col-4 mb--10">
                    <div
                      role="button"
                      aria-label="Product option."
                      tabIndex={0}
                      onClick={() => handleVariantSelect(variant)}
                      onKeyDown={() => handleVariantSelect(variant)}
                      className={`product__type__box product__type__box--image ${
                        selectedVariantId === variant.shopifyId ? "active" : ""
                      }`}
                    >
                      <div className="product__type">
                        <Image
                          filename={productData[variant.shopifyId].sellingPlansImg.cover}
                          className={`product__type__img`}
                        />
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="input-field input-field--hidden">
                            <input
                              type="radio"
                              name={`product-option-${orderId}`}
                              value={variant}
                              id={variant.selectedOptions[0].value}
                              checked={selectedVariantId === variant.shopifyId}
                              onChange={e => handleVariantSelect(e.target.value)}
                              className="d-none"
                            />
                            <label htmlFor={variant.selectedOptions[0].value}>{variant.selectedOptions[0].value}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </div>
      <h6 className="txt--18 txt--left font-lato mb--10">
        <strong className="fw--black">Choose your preferred payment</strong>
      </h6>
      <ProductPaymentOptions
        shopifyVariant={selectedVariant}
        product={product}
        paymentType={paymentType}
        bundle={{}}
        orderId={orderId}
        isImgVisible={isImgVisible}
        handlePaymentType={type => handlePaymentType(type)}
        handleChange={e => handleChange(e)}
      />
    </>
  )
}
export default WorkoutBundleOptions
