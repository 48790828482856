import React from "react"
import Image from "../Image/Image"
const ProductPaymentOptionsImage = ({
  isImgVisible,
  product,
  filenameSuffix = "",
  modifierClass = "",
  discountText = "",
  isVertical = false,
}) => {
  const isSellingPlanOption = Object.keys(product).filter(key => key === "sellingPlansImg").length

  return (
    <>
      {isImgVisible && (
        <>
          {isSellingPlanOption ? (
            <>
              {isVertical ? (
                <>
                  <Image
                    filename={product.sellingPlansImg?.vertical}
                    className={`product__type__img d-none d-sm-block`}
                  />
                  <Image
                    filename={product.sellingPlansImg?.horizontal}
                    className={`product__type__img d-block d-sm-none`}
                  />
                </>
              ) : (
                <Image filename={product.sellingPlansImg?.horizontal} className={`product__type__img`} />
              )}
            </>
          ) : (
            <div
              className={`${discountText.length > 0 ? "product__type__img--discount" : ""} ${modifierClass} ${
                filenameSuffix.length ? "product__type__img--discount-bundles" : ""
              }`}
              data-text={discountText}
            >
              <Image
                filename={`${product.img[0]}${filenameSuffix.length ? filenameSuffix : ""}`}
                className={`product__type__img product__type__img--limited ${modifierClass}`}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}
export default ProductPaymentOptionsImage
