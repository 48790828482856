import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"

// Style
import "../../styles/scss/app.scss"

// Components
import Header from "../Header/Header"
import Preheader from "../Header/Preheader"
import Sidebar from "../Sidebar/Sidebar"
import Footer from "../Footer/Footer"
import ModalCountry from "../Modals/ModalCountry"
import ModalQuickBuy from "../Modals/ModalQuickBuy"
import ModalSizeGuide from "../Modals/ModalSizeGuide"
import DiscountHelloBar from "../Discount/DiscountHelloBar"

const Layout = ({ theme, children }) => {
  /*====================================================
    HANDLE PREHEADER
    - hide it for mobile
  ====================================================*/
  const [isPreheader, setIsPreheader] = useState(true)

  useEffect(() => {
    window.addEventListener("resize", checkPreheader)
    checkPreheader()
  })

  const checkPreheader = () => setIsPreheader(window.innerWidth >= 992)

  /*====================================================
    QUICK BUY MODAL
  ====================================================*/
  const selectedShopifyProduct = useState("")
  const selectedProduct = useState("")

  const [isQuickBuyModalOpen, setIsQuickBuyModalOpen] = useState(false)

  // Add/remove overflow hidden when modal is opened/closed
  useEffect(() => {
    if (isQuickBuyModalOpen) {
      document.body.classList.add("overflow--hidden")
    } else {
      document.body.classList.remove("overflow--hidden")
    }
  }, [isQuickBuyModalOpen])

  /*============================
    SIZE GUIDE MODAL
  =============================*/
  const [isModalSizeGuideOpen, setIsModalSizeGuideOpen] = useState(false)

  // Add/remove overflow hidden when modal is opened/closed
  useEffect(() => {
    if (isModalSizeGuideOpen) {
      document.body.classList.add("overflow--hidden")
    } else {
      document.body.classList.remove("overflow--hidden")
    }
  }, [isModalSizeGuideOpen])

  return (
    <>
      {/*head*/}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />,
        <meta name="theme-color" content="#e54e89" />,<title>The Betty Rocker</title>
        <meta name="robots" content="index" />,
      </Helmet>

      {/*discount banner*/}
      {theme.isDiscountActive && <DiscountHelloBar theme={theme} />}

      {/*preheader*/}
      {isPreheader && <Preheader theme={theme} />}

      {/*header*/}
      <Header
        modifierClass={`${!theme.shipping ? "worldwide-shop" : ""} ${theme.isDiscountActive ? "discount-active" : ""}`}
        theme={theme}
      />

      {/*sidebar*/}
      <Sidebar theme={theme} />

      {/*content*/}
      <main className={`${!theme.shipping ? "worldwide-shop" : ""} ${theme.isDiscountActive ? "discount-active" : ""}`}>
        {children}
      </main>

      {/*footer*/}
      <Footer pageLocation={children.props.location} />

      <ModalCountry />

      {isQuickBuyModalOpen && (
        <ModalQuickBuy
          handleCloseQuickBuyModal={() => setIsQuickBuyModalOpen(false)}
          shopifyProduct={selectedShopifyProduct}
          product={selectedProduct}
          handleOpenModalSizeGuide={() => setIsModalSizeGuideOpen(true)}
          selectedProductColor={""}
          isCart={false}
        />
      )}

      {isModalSizeGuideOpen && (
        <ModalSizeGuide
          shopifyProduct={selectedShopifyProduct}
          handleCloseModalSizeGuide={() => setIsModalSizeGuideOpen(false)}
        />
      )}
    </>
  )
}
export default Layout
