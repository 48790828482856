import React from "react"

const Country = ({ theme }) => {
  return (
    <div className="preheader__tabs">
      <button
        aria-label="Go to US Shop."
        onClick={() => theme.handleChangeModalCountry(true)}
        className={`preheader__tab ${
          theme.country !== "worldwide" ? "active" : ""
        }`}
      >
        <div className="preheader__tab__item">
          <img src="/images/usa-icon.png" width="15" height="15" alt="" />
          <span>USA</span>
        </div>
        <div className="preheader__tab__item">
          <img src="/images/uk-icon.png" width="15" height="15" alt="" />
          <span>UK</span>
        </div>
        <div className="preheader__tab__item">
          <img src="/images/canada-icon.png" width="15" height="15" alt="" />
          <span>CAN</span>
        </div>
      </button>
      <button
        aria-label="Go to Worldwide Shop."
        onClick={() => theme.handleChangeModalCountry(true)}
        className={`preheader__tab ${
          theme.country === "worldwide" ? "active" : ""
        }`}
      >
        <div className="preheader__tab__item">
          <img src="/images/worldwide-icon.png" width="15" height="15" alt="" />
          <span>WORLDWIDE</span>
        </div>
      </button>
    </div>
  )
}
export default Country
