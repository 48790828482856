import React from "react"
// Components
import AccordionItem from "../../Accordions/AccordionItem"

const ProductInfoFourPillarsAccordion = () => {
  return (
    <div className="mb--20">
      <AccordionItem
        title="SLEEP"
        counter={false}
        icon="angle"
        modifierClasses="accordion-pillar"
        open={false}
        pillar="sleep"
      >
        <p className="p--supplements">
          A natural blend of herbs and organic compounds for a better night's
          rest with our signature Whole Sleep product!
        </p>
      </AccordionItem>
      <AccordionItem
        title="NUTRITION"
        counter={false}
        icon="angle"
        modifierClasses="accordion-pillar"
        open={false}
        pillar="nutri"
      >
        <p className="p--supplements">
          Support your daily protein intake with smooth and delicious organic
          vanilla protein powder - made from a blend of organic pea, pumpkin
          seed, cranberry seed and chia seed protein!
        </p>
      </AccordionItem>
      <AccordionItem
        title="STRESS MANAGEMENT"
        counter={false}
        icon="angle"
        modifierClasses="accordion-pillar"
        open={false}
        pillar="stress"
      >
        <p className="p--supplements">
          Support for busy and stressful days with a balancing herbal blend
          designed to support you feeling serene and calm. A great complement to
          Whole Sleep, these products can be used together to improve deeper
          sleep.
        </p>
      </AccordionItem>
      <AccordionItem
        title="EXERCISE"
        counter={false}
        icon="angle"
        modifierClasses="accordion-pillar"
        open={false}
        pillar="exercise"
      >
        <p className="p--supplements">
          Support your workouts with free form amino acids, containing all 9
          essential aminos your body can't make on its own and the BCAA's for
          muscle support, recovery and decreased soreness.
        </p>
      </AccordionItem>
    </div>
  )
}
export default ProductInfoFourPillarsAccordion
