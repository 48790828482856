const legalData = {
  terms: {
    title: "Terms of Service",
    content:
      "<p>The web sites www.thebettyrocker.com and www.makefatcrychallenge.com, all of the various pages contained therein, including subdomains (for example members.thebettyrocker.com, or shop.thebettyrocker.com), as well as your use of the products and services offered therein are maintained and operated by Betty Rocker, which is defined below.</p>\n" +
      "    <p>Please read these Terms of Service (“Terms”) carefully as they describe your relationship with us and govern your use of www.thebettyrocker.com, www.makefatcrychallenge.com and the products and services offered by us.  These Terms will also govern your use of any other web sites that currently exist or may exist or be created by us in the future, associated widgets, mobile applications and other distribution platforms.  (All of the foregoing shall hereafter be referred to as the “Site.”)</p>\n" +
      "    <p>Feel free to print a copy of these Terms for future reference.</p>\n" +
      "    <p>We may from time to time amend these Terms. Those changes will be posted here and may also be communicated to you by any written contact method we have with you. However, these changes take effect on the date posted on our Site.</p>\n" +
      "    <p><strong>1. Legal Agreement</strong></p>\n" +
      "    <p>These Terms are a legal agreement between you and us and contain important information regarding your legal rights, remedies and obligations. By accessing, browsing or otherwise using the Site, you: (i) acknowledge you have read, understand, and agree to be bound by these Terms; (ii) agree to comply with all applicable laws, rules and regulations with respect to your use of the Site; and (iii) represent you are an adult and have the legal capacity to enter into contracts in the jurisdiction where you reside.</p>\n" +
      "    <p>Our permitting you access to our Site is conditioned on your agreeing to all of the Terms, including your compliance with our policies, including our Privacy Policy, as well as our agreement to arbitrate individual disputes, instead of using class actions or jury trials.</p>\n" +
      "    <p>The Site may not be used in any way that is not expressly permitted by these Terms and our related Privacy Policy found here.</p>\n" +
      "    <p>IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS, BROWSE OR USE OUR SITE, AND SHOULD DISCONTINUE YOUR ACTIVITIES IMMEDIATELY.</p>\n" +
      "    <p>IF YOU ACCESS THE SITE, YOU ARE AGREEING TO THESE TERMS, OUR PRIVACY POLICY AND/OR ANY OF THEIR PARTS, AS UPDATED OR REVISED, FROM TIME TO TIME.</p>\n" +
      "    <p><strong>2. Definitions</strong></p>\n" +
      "    <p>“Betty Rocker,” the “Company,” “we,” “us,” or “our” includes The Betty Rocker, Inc., and its trustees, officers, directors, shareholders, employees, attorneys and agents (current and past) and any related individuals or entities.</p>\n" +
      "    <p>Service or Services means any of the Site or other products or services provided or offered by us, including those described in these Terms, whether through a website owned, maintained or controlled by us, through a social network, a mobile application, on a cellular telephone or otherwise.</p>\n" +
      "    <p>Content means all the content and materials that appear on the Site.</p>\n" +
      "    <p>Lifetime: To the extent that you are “A Lifetime” customer or been afforded “lifetime access,” you are entitled to use a program for so long as the program is made available by Betty Rocker.</p>\n" +
      "    <p><strong>3. You Must Be 18 Years Old To Use Our Site</strong></p>\n" +
      "    <p>We require an individual to be at least eighteen (18) years old to be a user on our Site.  This Site is not directed toward children under 13 years of age, nor do we knowingly collect information about children under 13.  If you are under 13 years of age, you are not permitted to submit personal information to us.  If we find out you are under 13 years of age, we will immediately, upon notice, cancel your account and delete all of your Content.  If a parent becomes aware any of his/her children has submitted any personally identifying information (“PII”) to us, please immediately notify us at support@thebettyrocker.com or at The Betty Rocker, Inc. 301 West Platt St STE A #23, Tampa, FL 33606.</p>\n" +
      "    <p><strong>4. You Understand And Agree That Physical Exercise Is Strenuous.</strong></p>\n" +
      "    <p>This Site is intended for use only by healthy adult individuals. The Site is not intended for use by minors, pregnant women, or individuals with any type of health condition. Such individuals are specifically warned to seek professional medical advice prior to initiating any form of weight loss effort or regimen.</p>\n" +
      "    <p>If you use this Site, you understand that physical exercise can be strenuous and can expose you to the risk of serious injury. As with all programs, techniques and materials related to health, exercise and fitness, we urge you to obtain a physical examination from a doctor before participating in any exercise activity.</p>\n" +
      "    <p>You acknowledge that your use of our Site may present certain risks, and hereby assume any and all risks associated therewith, including, without limitation, the risk of physical or mental or emotional injury, minor and/or severe bodily harm, death, and/or illness, which arise by any means, including, without limitation: acts, omissions, recommendations or advice given by us. By using our Site, you hereby agree to voluntarily accept and assume any and all such risks as well as any risks not mentioned herein that are in any way associated with your use of our Site. You agree that by participating in physical exercise or training activities you do so entirely at your own risk.</p>\n" +
      "    <p><strong>5. You Understand And Agree That We Are Not Giving Health Advice</strong></p>\n" +
      "    <p>This site offers health, wellness, fitness, exercise and nutritional information and is designed for educational purposes only. The nutritional, exercise, and other information on this Site is not intended to be and does not constitute health care or medical advice and must not be used to make any diagnosis specific to you. We do not employ dietitians or any other health care professionals.  You should not rely on this information as a substitute for, nor does it replace, professional medical advice, diagnosis or treatment. If you have any concerns or questions about your health, you should always consult with a physician or other health-care professional.  Do not disregard, avoid, or delay obtaining medical or health-related advice from a health-care professional because of something you may have read on this site.</p>\n" +
      "    <p>This Site makes no warranty of any kind, implied or express, that you will lose weight, build muscle, or otherwise receive any health benefits as a result of using our Site. Individual results will vary. Moreover, because fitness and nutritional research is always ongoing and developing and subject to disagreement in the field, we cannot make any assurances that the information provided in our Site will be up-to-date, accurate, or complete.</p>\n" +
      "    <p>Your use of any information contained on this Site is solely at your own risk.</p>\n" +
      "    <p><strong>6. You Understand And Agree That Your Use Of The Site Is Restricted</strong></p>\n" +
      "    <p>You may access, browse and use the Site and its content only for limited purposes. Your use is limited to your personal, non-commercial use of the Site. If you are posting content on our Site, or submitting any material (including, without limitation, comments, blog entries, Facebook postings, photos and videos) to us via the Site, internet groups, social media venues, or to any of our staff via email, text or otherwise, you are representing: (i) that you are the owner of the material, or are making your posting or submission with the express consent of the owner of the material; and (ii) that you are thirteen years of age or older. In addition, when you submit, email, text or deliver or post any material, including photographs you are granting us, and anyone authorized by us, a royalty-free, perpetual, irrevocable, non-exclusive, unrestricted, worldwide license to use, copy, modify, transmit, sell, exploit, create derivative works from, distribute, and/or publicly perform or display such material, in whole or in part, in any manner or medium, now known or hereafter developed, for any purpose. The foregoing grant shall include the right to exploit any proprietary rights in such posting or submission, including, but not limited to, rights under copyright, trademark, service mark or patent laws under any relevant jurisdiction. Also, in connection with the exercise of such rights, you grant us, and anyone authorized by us, the right to identify you as the author of any of your postings or submissions by name, email address or screen name, as we deem appropriate.</p>\n" +
      "    <p>You acknowledge and agree that any contributions originally created by you for us, including social media posts, shall be deemed a “work made for hire” when the work performed is within the scope of the definition of a work made for hire in Section 101 of the United States Copyright Law, as amended. As such, the copyrights in those works shall belong to Company from their creation. Thus, Company shall be deemed the author and exclusive owner thereof and shall have the right to exploit any or all of the results and proceeds in any and all media, now known or hereafter devised, throughout the universe, in perpetuity, in all languages, as Company determines. In the event that any of the results and proceeds of your submissions hereunder are not deemed a “work made for hire” under Section 101 of the Copyright Act, as amended, you hereby, without additional compensation, irrevocably assign, convey and transfer to Company all proprietary rights, including without limitation, all copyrights and trademarks throughout the universe, in perpetuity in every medium, whether now known or hereafter devised, to such material and any and all right, title and interest in and to all such proprietary rights in every medium, whether now known or hereafter devised, throughout the universe, in perpetuity. Any posted material which are reproductions of prior works by you shall be co-owned by us.</p>\n" +
      "    <p>You acknowledge that Company has the right but not the obligation to use and display any postings or contributions of any kind and that Company may elect to cease the use and display of any such materials (or any portion thereof), at any time for any reason whatsoever.</p>\n" +
      "    <p>You may access portions of the Site without purchasing anything or creating an account. There is, however, Content on this Site which is available only if you create an account or make a purchase.  When any of the Services on the Site require you to open an account or otherwise provide user or registration information, including user name and password (“User Information”), you must complete the registration process by providing us with complete, truthful and accurate information.  You are responsible for maintaining the confidentiality of your username, password and other information used to register and sign in to the Site, and you are fully responsible for all activities that occur under this password and username. Please immediately notify us of any unauthorized use of your account or any other breach of security by contacting us at support@thebettyrocker.com or at The Betty Rocker, Inc. 301 West Platt St STE A #23, Tampa, FL 33606. In the event you use the Site over any cellular/mobile devices, you hereby acknowledge that your carrier’s normal rates and fees, such as excess broadband fees, will still apply. We cannot and will not be liable for any loss or damage arising from your failure to protect your password or account information.</p>\n" +
      "    <p>When using the Site, you shall be subject to all displayed rules and policies.  Such rules and policies are hereby incorporated by reference into these Terms. We may also offer other content and/or services that are governed by different Terms.</p>\n" +
      "    <p><strong>7. We Respect Your Privacy</strong></p>\n" +
      "    <p>To access certain features of the Site, we may ask you to provide certain demographic information including your gender, year of birth, zip code and country. In addition, if you elect to sign-up for a particular feature of the Site, such as chat rooms, web logs, or bulletin boards, you may also be asked to register with us on the form provided and such registration may require you to provide personally identifiable information such as your name and email address. You agree to provide true, accurate, current and complete information about yourself as prompted by the Site’s registration form. If we have reasonable grounds to suspect that such information is untrue, inaccurate, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof). Our use of any personally identifiable information you provide to us as part of the registration process is governed by the terms of our Privacy Policy.</p>\n" +
      "    <p>We respect your privacy.  Our Privacy Policy, located here, constitutes a part of these Terms and explains how we collect, use and protect your personal information.  By accepting these Terms, you agree to our collection and use of your personal information as described in our Privacy Policy.</p>\n" +
      "    <p><strong>8. We Own Our Intellectual Property</strong></p>\n" +
      "    <p>Betty Rocker is the exclusive owner or licensee of all the Content, and of all related intellectual property rights therein, including, but not limited to, all copyrights, moral rights, trademark and patent rights (the “IP”).  Our Content includes, but is not limited to, all features, functions, services, software, algorithms, designs, objects, documentation, know-how, code, data, art, graphics, animation, photographs, images, text, music, sound effects, audio and/or audio-visual elements, downloadable materials, look-and-feel, design, layout, organization, presentation, user interface, navigation and stylistic convention of the Site.</p>\n" +
      "    <p>All trademarks, trade names, copyrights, logos and service-marks, whether or not registered (collectively, the “Marks”) relating to or constituting our IP are our exclusive property.  The Site and our Services and Content are protected by copyright, trademark, and other laws of the United States and other countries. Unauthorized use of any of the Marks is strictly prohibited by law.  All third-party trade names, trademarks, logos and service marks, if any, that appear in or on the Site are the property of their respective owners.</p>\n" +
      "    <p>You do not acquire any ownership interest in the IP or the Marks by accessing, browsing or otherwise using the Site.  You may not reproduce, copy, modify, distribute, transmit, display, perform, publish or otherwise exploit, through any means or media, any of our IP or Marks. This Site and all the materials available on the Site are the property of us and/or our affiliates or licensors, and are protected by copyright, trademark, and other intellectual property laws. The Site is provided solely for your personal noncommercial use. You may not use the Site or the materials available on the Site in a manner that constitutes an infringement of our rights or that has not been authorized by us. More specifically, unless explicitly authorized in these Terms of Service or by the owner of the materials, you may not modify, copy, reproduce, republish, upload, post, transmit, translate, sell, create derivative works, exploit, or distribute in any manner or medium (including by email or other electronic means) any material from the Site. You may, however, from time to time, download and/or print one copy of individual pages of the Site for your personal, non-commercial use, provided that you keep intact all copyright and other proprietary notices.</p>\n" +
      "    <p>You may establish a hypertext link to the Site so long as the link does not state or imply any sponsorship of your site by us or by the Site. However, you may not, without our prior written permission, frame or inline link any of the content of the Site, or incorporate into another website or other service any of our material, content or intellectual property.</p>\n" +
      "    <p>We may prevent unauthorized use of the Site via technological means.  You agree not to circumvent or attempt to circumvent these means.  You agree that any attempted or actual circumvention, or otherwise unauthorized use, by you or anyone on your behalf will result, at minimum, in the termination of all your rights to our products and Services under these Terms.</p>\n" +
      "    <p>The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under the U.S. copyright law. If you believe in good faith that materials hosted by Company infringe your copyright, you, or your agent may send to Company a notice requesting that the material be removed or access to it be blocked. Any notification by a copyright owner or a person authorized to act on its behalf that fails to comply with requirements of the DMCA shall not be considered sufficient notice and shall not be deemed to confer upon Company actual knowledge of facts or circumstances from which infringing material or acts are evident. If you believe in good faith that a notice of copyright infringement has been wrongly filed against you, the DMCA permits you to send to Company a counter-notice. All notices and counter notices must meet the then current statutory requirements imposed by the DMCA; see http://www.loc.gov/copyright for details. Company’s Copyright Agent for notice shall be support@thebettyrocker.com</p>\n" +
      "    <p><strong>9. Please Use Our Site Responsibly</strong></p>\n" +
      "    <p>The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, “Communication Services”). You agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service. Further, you agree that Your Use of the Site is solely for private and personal purposes. You further warrant and represent that you will not engage in any of the activities that expose you to civil and/or criminal liability.</p>\n" +
      "    <p>We do not control the messages, information or files that you or others may provide through the Site. It is a condition of your use of the Site that you do not:        </p>\n" +
      "\n" +
      "    <ul>\n" +
      "        <li>Restrict or inhibit any other user from using and enjoying the Site.</li>\n" +
      "        <li>Use the Site to impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity.</li>\n" +
      "        <li>Interfere with or disrupt any servers or networks used to provide the Site or its features, or disobey any requirements, procedures, policies or regulations of the networks we use to provide the Site.</li>\n" +
      "        <li>Use the Site to instigate or encourage others to commit illegal activities or cause injury or property damage to any person.</li>\n" +
      "        <li>Gain unauthorized access to the Site, or any account, computer system, or network connected to this Site, by means such as hacking, password mining or other illicit means.</li>\n" +
      "        <li>Obtain or attempt to obtain any materials or information through any means not intentionally made available through this Site.</li>\n" +
      "        <li>Use the Site to post or transmit any unlawful, threatening, abusive, libelous, defamatory, obscene, vulgar, pornographic, profane or indecent information of any kind, including without limitation any transmissions constituting or encouraging conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any local, state, national or international law.</li>\n" +
      "        <li>Use the Site to post or transmit any information, software or other material that violates or infringes upon the rights of others, including material that is an invasion of privacy or publicity rights or that is protected by copyright, trademark or other proprietary right, or derivative works with respect thereto, without first obtaining permission from the owner or rights holder.</li>\n" +
      "        <li>Use the Site to post or transmit any information, software or other material that contains a virus or other harmful component.</li>\n" +
      "        <li>Use the Site to post, transmit or in any way exploit any information, software or other material for commercial purposes, or that contains advertising.</li>\n" +
      "        <li>Use the Site to advertise or solicit to anyone to buy or sell products or services, or to make donations of any kind, without our express written approval.</li>\n" +
      "        <li>Gather for marketing purposes any email addresses or other personal information that has been posted by other users of the Site.</li>\n" +
      "    </ul>\n" +
      "\n" +
      "    <p>Company may host message boards, chats and other private/public forums on its Sites and on other platforms. Any user failing to comply with the terms and conditions of this Agreement may be expelled from and refused continued access to, the message boards, groups, chats or other such forums in the future. Company or its designated agents may remove or alter any user-created content at any time for any reason. Message boards, chats and other public forums are intended to serve as discussion centers for users and subscribers. Information and content posted within these public forums may be provided by Company staff, Company’s outside contributors, or by users not connected with Company, some of whom may employ anonymous user names. Company expressly disclaims all responsibility and endorsement and makes no representation as to the validity of any opinion, advice, information or statement made or displayed in these forums by third parties, nor are we responsible for any errors or omissions in such postings, or for hyperlinks embedded in any messages. Under no circumstances will we, our affiliates, suppliers or agents be liable for any loss or damage caused by your reliance on information obtained through these forums. The opinions expressed in these forums are solely the opinions of the participants, and do not reflect the opinions of Company or any of its subsidiaries or affiliates.</p>\n" +
      "    <p>Company has no obligation whatsoever to monitor any of the content or postings on the message boards, chat rooms or other public forums on the Sites. However, you acknowledge and agree that we have the absolute right to monitor the same at our sole discretion. In addition, we reserve the right to alter, edit, refuse to post or remove any postings or content, in whole or in part, for any reason and to disclose such materials and the circumstances surrounding their transmission to any third party in order to satisfy any applicable law, regulation, legal process or governmental request and to protect ourselves, our clients, sponsors, users and visitors.</p>\n" +
      "    <p><strong>10. To The Extent You Pay For Content, Please Read This</strong></p>\n" +
      "    <p>Some of the Content on our Site can only be viewed if you purchase it or pay for a meal plan or workout program. The details of these products and programs are available in various areas of the Site and allow you to purchase products, features or services.</p>\n" +
      "    <p><strong>Product/Service Pricing:</strong> All currency references on our website are in U.S. Dollars. For international customers, you will be billed in your local currency the equivalent of USD price based on the conversion rate at time of billing. Currency conversion rates are not within our control and may vary over time depending on market conditions.</p>\n" +
      '    <p>Promotional offers are redeemable one time per customer. Promotions cannot be combined or "stacked" with other promotions. This includes existing promotional pricing e.g. Subscribe and Save.</p>\n' +
      "    <p>We may, from time to time, modify, amend, or supplement our fees and fee-billing methods, and such changes shall be effective immediately upon posting on the pricing page, in these Terms, or elsewhere on the Site.</p>\n" +
      "    <p>Please refer to the refund policy that is provided with the plan, program, or content that you have purchased. You understand and agree that we are only obligated to refund you as stated in the applicable refund policy.</p>\n" +
      '    <p><span class="txt--underline">Cancellation of Recurring Charges:</span>  If you purchase a program that has recurring monthly charges, you may cancel future billing and terminate your program participation by contacting us at <a href="mailto:support@thebettyrocker.com">support@thebettyrocker.com</a>. Your request will be effective for the next billing cycle.</p>\n' +
      "    <p><strong>11. Third-Party Websites</strong></p>\n" +
      "    <p>The Site may contain links to websites of third parties. If you click these links, you will leave our Site.</p>\n" +
      "    <p>These third parties and their websites are not under our control.  We are not responsible for their content or operation.  By providing links to these websites, we do not approve, warrant or endorse, or otherwise make any representation about them or their owners or operators, and do not assume any related responsibility or liability.  You should exercise your own judgment in evaluating and using these websites.  When you link to these websites, you become subject to their terms and conditions of use and privacy policies and should review them carefully before entering any such websites.  We do not endorse these websites and our Terms and Privacy Policy do not apply to them.</p>\n" +
      "    <p>You expressly release us from any and all liability arising from your use of any third-party website, service or content.  You specifically agree that we are not responsible for any loss or damage of any sort resulting or arising from your dealings with those third-party websites and/or their providers.</p>\n" +
      "    <p><strong>12. Credit Card Processing</strong></p>\n" +
      "    <p>The payments for our Services are processed by a third party, using the third party’s secure software.  That third party is responsible for the processing of credit card payments and the storage of credit card information for the Site.</p>\n" +
      "    <p>You expressly release us from any and all liability arising from the credit card processor’s processing and storage of your credit card information.</p>\n" +
      "    <p><strong>13. Geographic Reach of the Site</strong></p>\n" +
      "    <p>We administer, control and operate the Site from Tampa, State of Florida, United States of America.  The Site is accessible worldwide.  However, some of its features or functions may not be available or appropriate for use outside of the United States and/or may not be available to all persons or in all geographic locations.  We make no representation that the Site or our Content and/or Services are appropriate or authorized for use in all countries, states, provinces, counties, localities or any other jurisdictions.  Your access and use of the Site may not be legal in your jurisdiction.  If you choose to access, browse or use the Site, you do so on your own initiative and at your own risk, and you are responsible for compliance with all applicable laws if, and to the full extent that, such laws and restrictions are applicable.  We reserve the right to limit, in our sole discretion, the provision of any feature or function of the Site to any person and/or geographic area.  Any offer for any feature or function made on the Site is void where prohibited.</p>\n" +
      "    <p><strong>14. Our Liability Is Limited</strong></p>\n" +
      "\n" +
      "    <p><strong>AS A USER, YOU ACKNOWLEDGE AND AGREE THAT YOUR SOLE REMEDY FOR ANY PROBLEMS OR DISSATISFACTION WITH THE SITE IS TO TERMINATE YOUR ACCOUNT AND DISCONTINUE ALL USE OF THE SITE.</strong></p>\n" +
      "    <p><strong>THUS, EXCEPT AS SET OUT BELOW, UNDER NO CIRCUMSTANCES, INCLUDING ANY BREACH OF CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ARISING UNDER ANY OTHER LEGAL OR EQUITABLE THEORY WILL BETTY ROCKER, ITS SUBSIDIARY OR PARENT COMPANIES OR AFFILIATES, BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, EXEMPLARY, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES THAT ARISE OUT OF OR IN CONNECTION WITH THE USE OF OR INABILITY TO USE THE SITE (INCLUDING WITHOUT LIMITATION USE OF OR RELIANCE ON INFORMATION AVAILABLE ON OR IN THE SITE OR OTHERWISE RELIED ON OR USED BY US IN DELIVERING THE SITE, AND/OR ANY INTERRUPTIONS, ERRORS, DEFECTS, MISTAKES, OMISSIONS, DELETIONS OF FILES, DELAYS IN OPERATION OR TRANSMISSION, NONDELIVERY OF INFORMATION, DISCLOSURE OF COMMUNICATIONS, AND/OR ANY OTHER FAILURE OF PERFORMANCE), OR FOR ANY CONTENT, INFORMATION, SOFTWARE, PRODUCTS OR SERVICES OBTAINED OR ACCESSED THROUGH THE SITE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</strong></p>\n" +
      "    <p><strong>YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT WE ARE NOT LIABLE FOR ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY USER.</strong></p>\n" +
      "    <p><strong>THIS SITE IS CONTINUALLY UNDER DEVELOPMENT AND COMPANY MAKES NO WARRANTY OF ANY KIND, IMPLIED OR EXPRESS, AS TO ITS ACCURACY, COMPLETENESS OR APPROPRIATENESS FOR ANY PURPOSE.</strong></p>\n" +
      "    <p><strong>IN NO EVENT SHALL THE TOTAL LIABILITY OF BETTY ROCKER TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED, IN THE AGGREGATE, THE AMOUNT PAID BY YOU TO US FOR YOUR USE OF THE SITE DURING THE THREE (3) MONTHS IMMEDIATELY PRECEDING YOUR CLAIM.</strong></p>\n" +
      "\n" +
      "    <p>Some jurisdictions do not allow the disclaimer of implied warranties, so the foregoing disclaimers may not apply to you.</p>\n" +
      "    <p>Some states or jurisdictions do not allow the exclusion or limitation of consequential or incidental damages and, in such states or jurisdictions, our liability shall be limited to the fullest extent permitted by law.</p>\n" +
      "    <p>If any particular type of damage cannot be waived or disclaimed under the law, then the remainder of the disclaimer will remain valid and continue to disclaim every other form of damage that may be disclaimed under the law.</p>\n" +
      "    <p><strong>15. Procedure for Making Complaints</strong></p>\n" +
      "    <p>If you believe that your rights, or the rights of a third party, are being violated in any way by any content accessible on or through the Site, including copyrights, please contact us at support@thebettyrocker.com or at The Betty Rocker, Inc. 301 West Platt St STE A #23, Tampa, FL 33606.</p>\n" +
      "    <p><strong>16. Statute of Limitations</strong></p>\n" +
      "    <p>You and we agree that, regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to our Site, or our Content and/or Services, Terms and/or Privacy Policy must be filed within one (1) year after such claim or cause of action arose or be forever barred.</p>\n" +
      "    <p><strong>17. Assignment</strong></p>\n" +
      "    <p>You agree that we may assign any of our rights, and/or transfer, sub-contract or delegate any of our obligations, under these Terms.  You may not transfer or assign your rights and obligations under these Terms to any third party without our prior written consent.</p>\n" +
      "    <p><strong>18. Modifications</strong></p>\n" +
      "    <p>We reserve the right to modify these Terms and/or any other guidelines or policies affecting the Site at any time, and such revisions will become effective upon the earlier of (i) posting of the revisions to the Site, or (ii) distribution of the revisions by electronic mail. For this reason, please visit this page on a regular basis and check the “Last Updated” date at the top of these Terms to ensure you are familiar with the most recent version of these Terms. Your continued use of the Site after the effective date of the revisions signifies your acceptance of any such revisions. You will have no claim, complaint or demand against us for applying such changes or for failures incidental to such changes.</p>\n" +
      "    <p><strong>19. Termination</strong></p>\n" +
      "    <p>You may discontinue your use of our Site at any time without informing us. We may, without prior notice, change the Site and any of our Content and/or Services, stop providing them or any of their features to you or to users generally, or create usage limits for our Site. We may permanently or temporarily terminate or suspend your access to our Site without notice and without liability for any or no reason, including if, in our sole determination, you violate any provision of these Terms. In particular, we may immediately terminate or suspend any Account that has been flagged for repeat infringement of any Marks or violation of any other laws or our Terms and/or Privacy Policy.</p>\n" +
      "    <p>Upon termination of your access to or ability to use our Site, including but not limited to suspension of your Account, your right to use or access any Service and/or any Content will immediately cease. All provisions of this Agreement that by their nature should survive termination, shall survive termination, including, but not limited to, ownership provisions, warranty disclaimers, limitations of liability, the dispute resolution (arbitration) and class waiver provisions. Termination of your access to and use of our Site shall not relieve you of any obligations arising or accruing prior to such termination or limit any liability which you otherwise may have to us or any third party.</p>\n" +
      "    <p>On termination of your Account or upon your deletion of any particular Service or Content, you acknowledge and agree that: (a) caching of, copies of, or references to the Content may not be immediately removed; and (b) such removed Content may persist in backups (not available to others) for reasonable periods of time. You agree to release and indemnify us from all claims related to the retention of deleted Content.</p>\n" +
      "    <p><strong>20. Indemnity</strong></p>\n" +
      "    <p>You agree to defend, indemnify and hold harmless us and our employees, contractors, agents, professional advisers, members, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to actual attorney’s fees and costs) arising from: (i) your use of and access to the Site, including any data or Content transmitted, submitted, viewed, or received by you; (ii) your violation of any provision of these Terms and/or our Privacy Policy, including your breach of any of the representations and/or warranties contained in these Terms; (iii) your violation of any third party right, including any right of privacy or intellectual property rights; (iv) your violation of any applicable law, rule or regulation; (v) any claim or damages that arise as a result of any of your Content or any that is submitted via your Account; and/or (vi) any other party’s access and use of the Site and any of the Site’s Services and/or Content with your unique username, password or other appropriate security code.</p>\n" +
      "    <p>You hereby agree that we shall have the sole right and obligation to control the legal defense against any such claims, demands, or litigation, including the right to select counsel of our choice and to compromise or settle any such claims, demands, or litigation.</p>\n" +
      "    <p><strong>21. Released Parties</strong></p>\n" +
      "    <p>To the fullest extent permitted by law, you release us and any of our trustees, members, directors, officers, shareholders, employees, representatives, consultants, attorneys, agents, suppliers, distributors, licensees and/or licensors from any and all responsibility, liability, claims, demands, and/or damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with the following: (i) disputes between users, including those between you and other users; (ii) third-party sites and services, including Content found on such sites and services; (iii) disputes concerning any use of or action taken using your Account; and (iv) claims relating to the unauthorized access to any data communications or Content stored under or relating to your Account, including unauthorized use or alteration of such communications or your Content.</p>\n" +
      "    <p>You further waive any and all benefits and rights that would otherwise accrue to you by reason of the provisions of any federal or state statute or principle of common law of any state of the United States, or any political entity or nation, province or local law or regulation that may govern this release, which statute, regulation, law or principle.</p>\n" +
      "    <p><strong>22. Governing Laws</strong></p>\n" +
      "    <p>You agree the laws of the United States of America and, more specifically, the laws of the State of Florida, without regard to any principles of conflicts of laws, will govern these Terms, your use of the Site, and all matters relating to your access to, and/or use of, the Site, including all disputes between you and us.  You also agree that: (i) the Site shall be deemed solely based in Florida; and (ii) the Site shall be a passive site that does not give rise to personal jurisdiction over us, either specific or general, in any jurisdiction other Florida.</p>\n" +
      "    <p><strong>23. Dispute Resolution</strong></p>\n" +
      "    <p>Any dispute, claim or controversy arising out of or relating to the Site, our Privacy Policy, and these Terms, as well as any claim relating to the interpretation or validity of this Agreement, including but not limited to the determination of the scope or applicability of this agreement to arbitrate, shall be brought and be determined by final, binding arbitration in Tampa, Florida, before one arbitrator.  The arbitration shall be administered by JAMS pursuant to its arbitration rules.  These rules may be found at https://www.jamsadr.com/adr-rules-procedures.</p>\n" +
      "    <p>You and we waive our respective rights, if any, to bring any claim that is subject to this arbitration provision as a class action or otherwise on a representative basis.  In the event this provision is held unenforceable and the matter is permitted to proceed in Arbitration as a class or representative action, then the entirety of this arbitration agreement (including all subparts) shall be void and of no further effect, and either party may proceed to pursue the action in court. Notwithstanding the foregoing binding arbitration procedure, either party may bring an individual action in small claims court (provided the claim otherwise qualifies for such a program) as an alternative to proceeding with arbitration.</p>\n" +
      "    <p>You may elect to opt out (exclude yourself) from the final, binding arbitration procedure and the class action waiver specified in these Terms by doing the following:  Within 15 days of your first accessing the Site, you must send a letter to us at: The Betty Rocker, Inc., 301 West Platt St STE A #23, Tampa, FL 33606, specifying (1) your name, (2) your IP address(es), (3) your email address, (4) your mailing address, and (5) your request to be excluded from the final, binding arbitration provision and class action waiver specified in these Terms.  You are not required to send the letter by certified mail, return receipt requested or overnight courier, but it is recommended that you do so.  Your request to be excluded will only be effective and enforceable if you can prove the request was postmarked within the applicable 15-day deadline.</p>\n" +
      "    <p><strong>24. Alternative Jurisdiction</strong></p>\n" +
      "    <p>If any court of competent jurisdiction finds these arbitration and/or class action waiver provisions invalid or inapplicable, you agree to the exclusive jurisdiction of the federal and state courts located in Tampa, Florida, and the related appellate courts, in any related action or proceeding.  Further, you irrevocably submit to the exclusive jurisdiction of, and venue in, the state and federal courts in Tampa, Florida, and the related appellate courts, in any related action or proceeding and agree shall to not raise any claims as to Tampa, Florida being an inconvenient forum.</p>\n" +
      "    <p><strong>25. Waiver</strong></p>\n" +
      "    <p>Our failure to enforce any rights or provisions of these Terms shall not be deemed a waiver or relinquishment of such rights or provisions. Any waiver of any right or provision of these Terms must be in writing.</p>\n" +
      "    <p><strong>26. Severability</strong></p>\n" +
      "    <p>If any part of these Terms is determined to be invalid or unenforceable under applicable law, including the warranty disclaimers and liability limitations stated elsewhere herein, then the invalid or unenforceable provision(s) will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision, and the remainder of these Terms shall continue in full effect.</p>\n" +
      "    <p><strong>27. Entire Agreement</strong></p>\n" +
      "    <p>These Terms shall be deemed to include all other notices, policies, disclaimers and other terms and conditions contained in the Site, including our Privacy Policy; provided, however, these Terms shall prevail in the event of a conflict with any such other documents. Any rights not expressly granted in these Terms are reserved to us.</p>\n" +
      "    <p>These Terms constitute the entire agreement between you and us and supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and us, with respect to your use of the Site and all matters relating to your access to, and/or use of, the Site, our Services and our Content.</p>\n" +
      "    <p><strong>28. Electronic Record keeping</strong></p>\n" +
      "    <p>A printed version of these Terms, our Privacy Policy, and of any notice given in electronic form shall be admissible in any and all judicial, arbitration, or administrative proceedings based upon or relating to these Terms to the same extent as other business documents and records originally generated and maintained in printed form.        </p>\n" +
      "    <p><strong>29. Miscellaneous</strong></p>\n" +
      "\n" +
      "    <ul>\n" +
      "        <li>No party, nor any of the parties’ respective attorneys, shall be deemed the drafter of this agreement for purposes of interpreting any provision hereof in any judicial or other proceeding that may arise between the parties.</li>\n" +
      "        <li>These Terms and the rights, benefits and obligations contained herein are fully assignable by us and will be binding upon and inure to the benefit of our successors and assigns.</li>\n" +
      "        <li>Except as otherwise expressly provided in these Terms, there shall be no third party beneficiaries to this agreement.</li>\n" +
      "        <li>The headings in these Terms are for convenience only and have no legal or contractual effect.</li>\n" +
      "        <li>We may terminate these Terms for any or no reason at any time by notifying you through a notice on the Site or by any other method of communication provided elsewhere herein. Any such termination will be without prejudice to our rights, remedies, claims, or defenses hereunder.</li>\n" +
      "        <li>“Include,” “Includes,” “Including,” “include,” “includes,” and “including” herein mean including without limitation.</li>\n" +
      "        <li>These Terms and your use of the Site, including the submission of Content in or onto the Site, do not, and shall not be construed as creating any relationship, partnership, joint venture, employer-employee, agency, or franchisor-franchisee relationship in any way and/or of any kind between the parties hereto. Your use of the Site is intended for your personal enjoyment and benefit, and the provision of the Site to you (subject to your compliance with these Terms and our Privacy Policy) constitutes the sole and sufficient consideration that you are entitled to receive for any Content or other contributions you have made to us and our products and Services.</li>\n" +
      "    </ul>\n" +
      "\n" +
      "    <p><strong>30. Questions?</strong></p>\n" +
      "    <p>You may contact us concerning any question about the Site or these Terms at support@thebettyrocker.com or at The Betty Rocker, Inc. 301 West Platt St STE A #23, Tampa, FL 33606.</p>",
  },
  privacy: {
    title: "Privacy Policy",
    content:
      "<p>Last Updated: December, 2023</p>\n" +
      "    <p>The following Privacy Policy governs the online information collection practices of The Betty Rocker, Inc. (“we” or “us”). Specifically, it outlines the types of information that we gather about you while you are using the thebettyrocker.com, makefatcrychallenge.com, and all the various pages contained therein, including subdomains such as members.thebettyrocker.com (the “Site”) and the ways in which we use this information. This Privacy Policy, including our children’s privacy statement, does not apply to any information you may provide to us or that we may collect offline and/or through other means (for example, at a live event, via telephone, or through the mail).</p>\n" +
      "    <p>Please read this Privacy Policy carefully. By visiting and using the Site, you agree that your use of our Site, and any dispute over privacy, is governed by this Privacy Policy. Because the Web is an evolving medium, we may need to change our Privacy Policy at some point in the future, in which case we’ll post the changes to this Privacy Policy on this website and update the Effective Date of the policy to reflect the date of the changes. By continuing to use the Site after we post any such changes, you accept the Privacy Policy as modified.</p>\n" +
      "    <p><strong>How We Collect and Use Information</strong></p>\n" +
      "\n" +
      "    <p>We may collect and store personal or other information that you voluntarily supply to us online while using the Site (e.g., while on the Site or in responding via email to a feature provided on the Site). The Site only contacts individuals who specifically request that we do so or in the event that they have signed up to receive our messaging, attended one of our events, or have purchased one of our products. The Site collects personally identifying information from our users during online registration and online purchasing. Generally, this information includes name and e-mail address for registration or opt-in purposes and name, postal address, and credit card information when registering for our events or purchasing our products. We also may collect gender, year of birth, zip code and country information.</p>\n" +
      "\n" +
      "    <p>We also collect and store information that is generated automatically as you navigate online through the Site. For example, we may collect information about your computer’s connection to the Internet, which allows us, among other things, to improve the delivery of our web pages to you and to measure traffic on the Site. We do this via two main methods: 1. Server-side tracking, which means requests made to our server, and 2. Cookies, a standard feature found in browser software to enhance your experience with the Site. Cookies are small files that your web browser places on your hard drive for record-keeping purposes. Cookies are small files that your web browser places on your hard drive for record-keeping purposes. By showing how and when visitors use the Site, cookies and server-side tracking help us deliver advertisements, identify how many unique users visit us, and track user trends and patterns. They also prevent you from having to re-enter your preferences on certain areas of the Site where you may have entered preference information before. The Site also may use web beacons (single-pixel graphic files also known as “transparent GIFs”) to access cookies and to count users who visit the Site or open HTML-formatted email messages.</p>\n" +
      "\n" +
      "    <p>We use the information we collect from you while you are using the Site in a variety of ways, including using the information to customize features; advertising that appear on the Site; and, making other offers available to you via email, direct mail or otherwise. We also may provide your information to third parties, such as service providers, contractors and third-party publishers and advertisers for a variety of purposes. Unless you inform us in accordance with the process described below, we reserve the right to use, and to disclose to third parties, all of the information collected from and about you while you are using the Site in any way and for any purpose, such as to enable us or a third party to provide you with information about products and services. If you do not wish your information to be used for these purposes, you must email or send a letter to the address listed at the end of this Privacy Policy requesting to be taken off any lists of information that may be used for these purposes or that may be given or sold to third-parties.</p>\n" +
      "\n" +
      "    <p>Please keep in mind that whenever you voluntarily make your personal information available for viewing by third parties online – for example on message boards, web logs, through email, or in chat areas – that information can be seen, collected and used by others besides us. We cannot be responsible for any unauthorized third-party use of such information.</p>\n" +
      "\n" +
      "    <p>Some of our third-party advertisers and ad servers that place and present advertising on the Site also may collect information from you via server-side tracking, cookies, web beacons or similar technologies. These third-party advertisers and ad servers may use the information they collect to help present their advertisements, to help measure and research the advertisements’ effectiveness, or for other purposes. The use and collection of your information by these third-party advertisers and ad servers is governed by the relevant third-party’s privacy policy and is not covered by our Privacy Policy. Indeed, the privacy policies of these third-party advertisers and ad servers may be different from ours. If you have any concerns about a third party’s use of cookies or web beacons or use of your information, you should visit that party’s website and review its privacy policy.</p>\n" +
      "\n" +
      "    <p>Some of our service providers may use cookies or other methods to gather information regarding your use of the Site, and may combine the information in these cookies with any personal information about you that they may have. The use of such tracking information by a third party depends on the privacy policy of that third party.</p>\n" +
      "\n" +
      "    <p>The Site also includes links to other websites and provides access to products and services offered by third parties, whose privacy policies we do not control. When you access another website or purchase third-party products or services through the Site, use of any information you provide is governed by the privacy policy of the operator of the site you are visiting or the provider of such products or services.</p>\n" +
      "\n" +
      "    <p>We may also make some content, products and services available through our Site or by emailing messages to you through cooperative relationships with third-party providers, where the brands of our provider partner appear on the Site in connection with such content, products and/or services. We may share with our provider partner any information you provide, or that is collected, in the course of visiting any pages that are made available in cooperation with our provider partner. In some cases, the provider partner may collect information from you directly, in which cases the privacy policy of our provider partner may apply to the provider partner’s use of your information. The privacy policy of our provider partners may differ from ours. If you have any questions regarding the privacy policy of one of our provider partners, you should contact the provider partner directly for more information.</p>\n" +
      "\n" +
      "    <p>Be aware that we may occasionally release information about our visitors when release is appropriate to comply with law or to protect the rights, property or safety of users of the Site or the public.</p>\n" +
      "\n" +
      "    <p>Please also note that as our business grows, we may buy or sell various assets (e.g., in the course of a transaction like a merger, acquisition, bankruptcy, dissolution, or liquidation). In the unlikely event that we sell some or all of our assets, or one or more of our websites is acquired by another company, information about our users may be among the transferred assets.</p>\n" +
      "\n" +
      "    <p><strong>How We Protect the Confidentiality of Personal Information</strong></p>\n" +
      "\n" +
      "    <p>We protect the confidentiality and security of information we obtain in the course of business. We use commercially reasonable safeguards, such as industry-standard encryption technology, to help keep the information collected through the Site secure.</p>\n" +
      "\n" +
      "    <p>Despite these efforts to store personal information in a secure operating environment, we cannot guarantee the security of personal information during its transmission or its storage on our systems. Further, while we attempt to ensure the integrity and security of personal information, we cannot guarantee that our security measures will prevent third parties such as hackers from illegally obtaining access to personal information. We do not represent or warrant that personal information about you will be protected against, loss, misuse, or alteration by third parties.</p>\n" +
      "\n" +
      "    <p><strong>International Transfers</strong></p>\n" +
      "\n" +
      "    <p>We, as the owner of the Site, are based in the USA and operate the Site in accordance with applicable State and Federal laws of the USA. We make no claims that the Site or any of its content is accessible or appropriate outside of the USA. If you access the Site from outside the USA and/or transmit any information or data from outside the USA, you do so on your own initiative and are solely responsible for compliance with all local laws.</p>\n" +
      "\n" +
      "    <p>By providing us with your information, you acknowledge and agree that your information may be transferred outside of the country from which you are accessing the Site. Each of these countries has different privacy laws that afford varying levels of protection for personal information, and such laws may not be as comprehensive as those that exist in your country.</p>\n" +
      "\n" +
      "    <p><strong>Accessing, Updating, or Deleting Personal Information</strong></p>\n" +
      "\n" +
      "    <p>Depending on where you are located, you may have the right to access, update, or delete personal information about you. If you would like to exercise these rights, you may submit a request to support@thebettyrocker.com. We will promptly review all such requests in accordance with applicable law.</p>\n" +
      "\n" +
      "    <p>If you are a resident of California, please see the information below regarding California Residents for more information regarding your rights.</p>\n" +
      "    \n" +
      "    <p><strong>Google Analytics</strong></p>\n" +
      "    <p>We also use Google Analytics Advertiser Features to optimize our business. Advertiser features include:</p>\n" +
      "\n" +
      "    <ul>\n" +
      "        <li>Remarketing with Google Analytics</li>\n" +
      "        <li>Google Display Network Impression Reporting</li>\n" +
      "        <li>DoubleClick Platform integrations</li>\n" +
      "        <li>Google Analytics Demographics and Interest Reporting</li>\n" +
      "    </ul>\n" +
      "\n" +
      "    <p>By enabling these Google Analytics Display features, we are required to notify our visitors by disclosing the use of these features and that we and third-party vendors use first-party cookies (such as the Google Analytics cookie) or other first-party identifiers, and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to gather data about your activities on our Site. Among other uses, this allows us to contact you if you begin to fill out our check-out form but abandon it before completion with an email reminding you to complete your order. The “Remarketing” feature allows us to reach people who previously visited our Site, and match the right audience with the right advertising message.</p>\n" +
      "\n" +
      '    <p>You can opt out of Google’s use of cookies by visiting Google’s ad settings and/or you may opt out of a third-party vendor’s use of cookies by visiting the <a href="https://www.networkadvertising.org/managing/opt_out.asp" target="_blank">Network Advertising Initiative opt-out page</a>. For more information on Google Analytics’ processing of your information, please see <a href="https://www.google.com/policies/privacy/partners/" target="_blank">"How Google uses data when you use our partners\' sites or apps."</a></p>\n' +
      "\n" +
      "    <p><strong>Facebook</strong></p>\n" +
      "    <p>As advertisers on Facebook and through our Facebook page, we, (not Facebook) may collect content or information from a Facebook user and such information may be used in the same manner specified in this Privacy Policy. You consent to our collection of such information.</p>\n" +
      "\n" +
      "    <p>We abide by Facebook’s Data Use Restrictions.</p>\n" +
      "\n" +
      "    <ul>\n" +
      "        <li>Any ad data collected, received or derived from our Facebook ad (“Facebook advertising data”) is only shared with someone acting on our behalf, such as our service provider. We are responsible for ensuring that our service providers protect any Facebook advertising data or any other information obtained from us, limit our use of all of that information, and keep it confidential and secure.</li>\n" +
      "        <li>We do not use Facebook advertising data for any purpose (including retargeting, commingling data across multiple advertisers’ campaigns, or allowing piggybacking or redirecting with tags), except on an aggregate and anonymous basis (unless authorized by Facebook) and only to assess the performance and effectiveness of our Facebook advertising campaigns.</li>\n" +
      "        <li>We do not use Facebook advertising data, including the targeting criteria for a Facebook ad, to build, append to, edit, influence, or augment user profiles, including profiles associated with any mobile device identifier or other unique identifier that identifies any particular user, browser, computer or device.</li>\n" +
      "        <li>We do not transfer any Facebook advertising data (including anonymous, aggregate, or derived data) to any ad network, ad exchange, data broker or other advertising or monetization related service.</li>\n" +
      "    </ul>\n" +
      "    <p><strong>Microsoft</strong></p>\n" +
      "    <p>We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps, and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies and other tracking technologies to determine the popularity of products/services and online activity. Additionally, we use this information for site optimization, fraud/security purposes, and advertising. For more information about how Microsoft collects and uses your data, visit the\n" +
      '                <a href="https://privacy.microsoft.com/en-US/privacystatement" target="_blank" rel="noopener">Microsoft Privacy Statement.</a></p>' +
      "\n" +
      "    <p><strong>California Residents</strong></p>\n" +
      "\n" +
      "    <p>This section applies to our collection and use of personal information, as defined under California law, if you are a resident of California.</p>\n" +
      "\n" +
      "    <p><strong>Categories of personal information Collected, Used, and Disclosed</strong></p>\n" +
      "\n" +
      "    <p>In accordance with California law, we collected the following categories of personal information within the preceding 12 months:</p>\n" +
      "\n" +
      "    <ul>\n" +
      "        <li>Identifiers such as your name, alias, email address, mailing address, IP address, and online identifiers;</li>\n" +
      "        <li>Certain categories of personal information described in subdivision (e) of California Civil Code Section 1798.80;</li>\n" +
      "        <li>Internet or other electronic network activity information, including information on your usage of our Website (“<strong>Usage Information</strong>”);</li>\n" +
      "        <li>Information used to create a profile about a consumer reflecting the consumer’s preferences or behavior;</li>\n" +
      "        <li>Electronic, audio, visual, thermal, olfactory, or similar information;</li>\n" +
      "        <li>Commercial information, including records of products or services purchased or other purchasing histories; and</li>\n" +
      "        <li>Professional or employment-related information.</li>\n" +
      "    </ul>\n" +
      "\n" +
      "    <p>We share each of these categories of personal information with our service providers to the extent necessary for them to facilitate our business purposes (including any purpose specified above).</p>\n" +
      "\n" +
      "    <p>Additionally, within the past 12 months, some of our online advertisers may have used and disclosed Usage Information collected automatically from the Service. This may be a “sale” as broadly defined under the CCPA. Therefore, we provide you the right to opt out of this “sale” of personal information as described, below. </p>\n" +
      "\n" +
      "    <p><strong>Your California Privacy Rights</strong></p>\n" +
      "\n" +
      "    <p>If you are a resident of the California, you may have the following rights:</p>\n" +
      "\n" +
      "    <ul>\n" +
      "        <li><strong>Right to Know.</strong> You may have the right to request information on the categories of personal information that we collected in the previous twelve (12) months, the categories of sources from which the personal information was collected, the specific pieces of personal information we have collected about you, and the business purposes for which such personal information is collected and shared. You also have the right to request information on the categories of personal information which were disclosed for business purposes, and the categories of third parties in the twelve (12) months preceding your request for your personal information.</li>\n" +
      "\n" +
      "        <li><strong>Right to Delete.</strong> You may have a right to request us to delete personal information that we collected from you.</li>\n" +
      "\n" +
      "        <li><strong>Right to Opt-Out.</strong> You have a right to opt-out of certain disclosures of personal information to third parties, if such disclosures constitute a “sale” under California law. As noted above, in the past twelve (12) months we enabled advertisers to collect certain information from the Site, which the advertisers may use to improve their interest-based advertising networks. Regardless of whether this is a “sale,” you may opt-out of interest-based advertising as described above.</li>\n" +
      "    </ul>\n" +
      "\n" +
      "    <p>If you would like to exercise your rights listed above, please contact (or have your authorized agent contact) us at support@thebettyrocker.com or 720-432-3522. When doing so, please tell us which right you are exercising and provide us with contact information to direct our response.</p>\n" +
      "\n" +
      "    <p>We must verify your identity before fulfilling your requests. If we cannot initially verify your identity, we may request additional information to complete the verification process. Any personal information you disclose to us for purposes of verifying your identity will solely be used for the purpose of verification.</p>\n" +
      "\n" +
      "    <p>You have a right not to receive discriminatory treatment by any business when you exercise your California privacy rights.</p>\n" +
      "\n" +
      "    <p><strong>Children’s Privacy Statement</strong></p>\n" +
      "    <p>This children’s privacy statement explains our practices with respect to the online collection and use of personal information from children under the age of thirteen, and provides important information regarding their rights under federal law with respect to such information.</p>\n" +
      "\n" +
      "    <ul>\n" +
      "        <li>This Site is not directed to children under the age of thirteen and we do NOT knowingly collect personally identifiable information from children under the age of thirteen as part of the Site. We screen users who wish to provide personal information in order to prevent users under the age of thirteen from providing such information. If we become aware that we have inadvertently received personally identifiable information from a user under the age of thirteen as part of the Site, we will delete such information from our records. If we change our practices in the future, we will obtain prior, verifiable parental consent before collecting any personally identifiable information from children under the age of thirteen as part of the Site.</li>\n" +
      "        <li>Because we do not collect any personally identifiable information from children under the age of thirteen as part of the Site, we also do NOT knowingly distribute such information to third parties.</li>\n" +
      "        <li>We do NOT knowingly allow children under the age of thirteen to publicly post or otherwise distribute personally identifiable contact information through the Site.</li>\n" +
      "        <li>Because we do not collect any personally identifiable information from children under the age of thirteen as part of the Site, we do NOT condition the participation of a child under thirteen in the Site’s online activities on providing personally identifiable information.</li>\n" +
      "        <li>If you are under 13 years of age, you are not permitted to submit personal information to us. If we find out you are under 13 years of age, we will immediately, upon notice, cancel your account and delete all of your personal information. If a parent becomes aware any of his/her children has submitted any personal information to us, please immediately notify us at support@thebettyrocker.com or at The Betty Rocker, Inc. 301 West Platt St STE A #23. Tampa, FL 33606</li>\n" +
      "    </ul>\n" +
      "\n" +
      "    <p><strong>How do we store your information?</strong></p>\n" +
      "    <p>Your information is stored at the list server that delivers the Site content and messaging. Your information can only be accessed by those who help manage those lists in order to deliver e-mail to those who would like to receive the Site material.</p>\n" +
      "    <p>All of the messaging or emails that are sent to you by the Site include an unsubscribe link in them. You can remove yourself at any time from our mailing list by clicking on the unsubscribe link that can be found in every communication that we send you.</p>\n" +
      "\n" +
      "    <p><strong>Disclaimer</strong></p>\n" +
      "    <p>This policy may be changed at any time at our discretion so please review it periodically. If we should update this policy, we will post the material updates to this page on our Website. Unless otherwise indicated, any changes to this Privacy Policy will apply immediately upon posting to the Site. </p>\n" +
      "    <p>If you have any questions or concerns regarding our privacy policy please direct them to: support@thebettyrocker.com or at The Betty Rocker, Inc., 301 West Platt St STE A #23 Tampa, FL 33606.</p>",
  },
  return: {
    title: "Return Policy - Updated 10/25/21",
    content:
      '          <p>Please reach out to us at <a href="mailto:support@thebettyrocker.com" target="_blank" rel="noopener noreferrer">support@thebettyrocker.com</a> in the event you would like to return a program or product, and review the following policies about returns.</p>\n' +
      "          <h5><strong>Digital Products</strong></h5>\n" +
      "          <p>Digital Products are products delivered via the internet. These include meal plans and workout programs. Digital products are backed by our money back satisfaction guarantee. Length of guarantee varies, and is generally related to program length.\n</p>\n" +
      '          <p>If you are not happy with your purchase of one of your digital products please contact us at <a href="mailto:support@thebettyrocker.com" target="_blank" rel="noopener noreferrer">support@thebettyrocker.com</a> to request a refund within the return timeframe.\n</p>\n' +
      "          <table>\n" +
      "          <thead><tr><td>30-Day Money Back Guarantee</td><td>60-Day Money Back Guarantee</td></tr></thead>" +
      "          <tbody><tr>" +
      "               <td><ul><li>7-Day Meal Plan</li><li>30-Day Meal Plan</li><li>30-Day Booty and Abs Challenge</li><li>My Dinner Plan</li></ul></td>" +
      "               <td><ul><li>Body Fuel System</li><li>90-Day Challenge</li><li>Home Workout Domination</li><li>Home Workout Domination 2</li><li>Lioness</li></ul></td>" +
      "           </tr></tbody>" +
      "          </table>" +
      "          <h5><strong>Physical Products</strong></h5>\n" +
      "          <p>Physical products are products delivered to a physical address. These include Whole Betty products and Betty Rocker apparel and merchandise.\n</p>" +
      '          <p>If you’re unhappy with a physical product, please contact us within 30 days of purchase at <a href="mailto:support@thebettyrocker.com" target="_blank" rel="noopener noreferrer">support@thebettyrocker.com</a>, and see below for instructions regarding specific products.\n</p>' +
      "          <h5><strong>Whole Betty Supplements</strong></h5>\n" +
      "          <p>In the event of a damaged product, we will send a replacement at no extra charge. For all other returns, please note there is a $5 restocking fee and shipping costs are at your expense.\n</p>" +
      '          <p>Contact us at <a href="mailto:support@thebettyrocker.com" target="_blank" rel="noopener noreferrer">support@thebettyrocker.com</a> within 30 days of your original purchase and we’ll be happy to help you.</p>' +
      "          <h5><strong>Betty Rocker Apparel</strong></h5>\n" +
      '          <p>If your apparel doesn’t fit, please contact us at <a href="mailto:support@thebettyrocker.com" target="_blank" rel="noopener noreferrer">support@thebettyrocker.com</a> within 30 days of purchase to request a refund. \n</p>' +
      "          <p>Please be sure to carefully read our sizing guide prior to purchase to ensure you get the right size. To return your product and receive a refund, it must be unworn and in new condition.\n</p>" +
      "          <p>We currently cannot do exchanges, however our friendly customer support team will be happy to help you choose a better size so you can place a new order.\n</p>" +
      "          <p>Return shipping is your responsibility. We do not refund your original purchase shipping cost. All refunds are issued minus a $5 restocking fee.\n</p>" +
      "          <h5><strong>Betty Rocker Merchandise</strong></h5>\n" +
      '          <p>Please contact us at <a href="mailto:support@thebettyrocker.com" target="_blank" rel="noopener noreferrer">support@thebettyrocker.com</a> within 30 days of your original purchase for a refund if you are unhappy with your merchandise. Return your product in unused and new condition to qualify for a refund. \n</p>' +
      "          <p>Return shipping is your responsibility. We do not refund your original purchase shipping cost. All refunds are issued minus a $5 restocking fee.\n</p>",
  },
  shipping: {
    title: "Shipping Information",
    content:
      "                 <p>Our <strong>digital products</strong> are instantly available to anyone around the world, our <strong>physical products</strong> are currently available to customers in the USA, Canada and the UK.</p>\n" +
      "                 <p>Canadian and UK customers, please refer to the International Shipping policies below before placing an order for a physical product.</p>\n" +
      "                 <h5>Digital Products</h5>\n" +
      "                 <p>Digital Products are products delivered via the internet.  These include meal plans and workout programs. Digital products are not shipped in the mail.</p>\n" +
      "                 <p>Following purchase, you will receive an email including your secured login information. This will be sent to the email used during purchase.</p>\n" +
      "                 <p>Once logged in to the member’s area, you can access your program anytime and from any device.</p>\n" +
      "                 <h5>Physical Products</h5>\n" +
      "                 <p>Physical products are products delivered to a physical address.  These include Whole Betty supplements and Betty Rocker apparel and merchandise.  We are currently able to ship to the mainland USA, Alaska, Hawaii, Canada and the United Kingdom.</p>\n" +
      "                 <h5>Order Processing</h5>\n" +
      "                 <p>It typically takes 1–3 business days for orders to process and leave our warehouse. Processing times do not account for weekends or holidays. Orders are processed in the order in which they are received.</p>\n" +
      "                 <h5>Shipping Rates - Mainland USA</h5>\n" +
      "                 <p>Shipping to all 48 mainland USA states is flat rate of just $10.95.</p>\n" +
      "                 <p>To keep shipping costs to a minimum and reduce packaging waste, we sometimes combine orders that are shipping to the same address and scheduled closely together.</p>\n" +
      "                 <h5>Shipping Rates - Canada, United Kingdom, Hawaii & Alaska</h5>\n" +
      "                 <p>In order to calculate shipping rates for your area, our system searches the best rates from the available shipping methods including USPS, Fed Ex and UPS to find the cheapest option based on order weight and delivery location. Shipping rates are displayed during checkout after entering your address.</p>\n" +
      "                 <h5>Order Tracking</h5>\n" +
      "                 <p>When your order has been fulfilled, you will receive a shipment confirmation email. The email will contain shipment details and your tracking number. To track the order, click on the tracking number located near the bottom of the email. This link will provide the most up-to-date order information from the time your order is shipped until the time it is received.</p>\n" +
      "                 <h5>Delivery Times</h5>\n" +
      "                 <p>Orders placed by 2 PM EST will start processing the next business day. Processing usually takes 1–3 business days and orders shipped to a USA address typically arrive within 3–5 business days.</p>\n" +
      "                 <p>Please note: these delivery times are estimates and may not allow for unforeseen circumstances outside of our control, such as a strike, natural disaster, or severe winter storm.</p>\n" +
      "                 <h5>Business Days and Holidays</h5>\n" +
      "                 <p>All orders process and ship Monday through Friday, excluding federal holidays within the United States. If the order is placed after the cut-off time, it will be processed the following business day. Packages do not ship from our distribution centers on weekends or the following US holidays:</p>\n" +
      "                 <p class='m--0'><strong>Jan 1:</strong> New Year’s Day</p>\n" +
      "                 <p class='m--0'><strong>Third Monday in January:</strong> Martin Luther King Jr. Day</p>\n" +
      "                 <p class='m--0'><strong>Third Monday in February:</strong> President's Day</p>\n" +
      "                 <p class='m--0'><strong>Last Monday of May:</strong> Memorial Day</p>\n" +
      "                 <p class='m--0'><strong>July 4th:</strong> 4th of July</p>\n" +
      "                 <p class='m--0'><strong>First Monday of September:</strong> Labor Day</p>\n" +
      "                 <p class='m--0'><strong>Second Monday of October:</strong> Columbus Day</p>\n" +
      "                 <p class='m--0'><strong>November 11:</strong> Veteran's Day</p>\n" +
      "                 <p class='m--0'><strong>Fourth Thursday of November:</strong> Thanksgiving</p>\n" +
      "                 <p><strong>December 25:</strong> Christmas</p>\n" +
      '                 <p>If you have any questions please feel free to contact us at <a href="mailto:support@thebettyrocker.com">support@thebettyrocker.com</a></p>\n' +
      "                 <h5>International Orders</h5>\n" +
      "                 <p>We are pleased to be able to ship our <strong>physical products</strong> to Canada and the UK, however we want you to be aware that international shipping does come with a few additional conditions that we want you to be aware of so you have all of the information you need.</p>\n" +
      "                 <p><strong>Please only place your international order if you are comfortable with the following:</strong></p>\n" +
      "                 <ul>\n" +
      "                     <li>We are not liable for and will not refund any orders lost, damaged or confiscated by customs; you are purchasing at your own risk.</li>\n" +
      "                     <li>Some duty tax fees may apply, however <strong>we cannot predict them in advance</strong>. Please refer to your Customs agency for more information before ordering.</li>\n" +
      "                     <li>We do not consider packages lost in transit until 45 days after shipment. If determined lost, we must open a tracer claim with the shipping carrier before taking any corrective action. If your package is determined lost, a lost package affidavit email will be sent from our third-party claims company to confirm non-delivery. All lost package claims must be reported within 90 days of the original purchase date.</li>\n" +
      "                     <li>If a package is returned by Customs in good condition, we will refund you for the cost of the product minus shipping costs.</li>\n" +
      "                 </ul>\n" +
      "                 <h5>International Order Tracking</h5>\n" +
      "                 <p>Tracking may vary depending on the delivery provider used.  For example, UPS and  FedEx provide visibility of the complete route (i.e. within the USA, through customs and into Canada/UK).</p>\n" +
      "                 <p>USPS tracking works up to customs.  Once the package clears customs, Canada Post/Royal Post handles final delivery and USPS tracking no longer works, so we may not be able to provide tracking information up to delivery once Canada Post/Royal Post takes over.</p>\n" +
      "                 <h5>International Delivery Times</h5>\n" +
      "                 <p>Orders placed by 2 PM EST will start processing the next business day. Processing usually takes 1–3 business days.</p>\n" +
      "                 <p>International orders are usually delivered within 1-2 weeks. However, due to ongoing impacts of the pandemic and customs procedures, international shipments may experience delays and delivery may take up to 3-6 weeks. We thank you for your patience and understanding.</p>\n" +
      "                 <p>Please note: these delivery times are estimates and may not allow for unforeseen circumstances outside of our control, such as a strike, natural disaster, or severe winter storm.</p>\n" +
      "                 <h5>Business Days and Holidays</h5>\n" +
      "                 <p>All orders process and ship Monday through Friday, excluding federal holidays within the United States. If the order is placed after the cut-off time, it will be processed the following business day. Packages do not ship from our distribution centers on weekends or the following US holidays:</p>\n" +
      "                 <p class='m--0'><strong>Jan 1:</strong> New Year’s Day</p>\n" +
      "                 <p class='m--0'><strong>Third Monday in January:</strong> Martin Luther King Jr. Day</p>\n" +
      "                 <p class='m--0'><strong>Third Monday in February:</strong> President's Day</p>\n" +
      "                 <p class='m--0'><strong>Last Monday of May:</strong> Memorial Day</p>\n" +
      "                 <p class='m--0'><strong>July 4th:</strong> 4th of July</p>\n" +
      "                 <p class='m--0'><strong>First Monday of September:</strong> Labor Day</p>\n" +
      "                 <p class='m--0'><strong>Second Monday of October:</strong> Columbus Day</p>\n" +
      "                 <p class='m--0'><strong>November 11:</strong> Veteran's Day</p>\n" +
      "                 <p class='m--0'><strong>Fourth Thursday of November:</strong> Thanksgiving</p>\n" +
      "                 <p><strong>December 25:</strong> Christmas</p>\n" +
      '                 <p>If you have any questions please feel free to contact us at <a href="mailto:support@thebettyrocker.com">support@thebettyrocker.com</a></p>',
  },
  "international-shipping": {
    title: "International Shipping",
    content:
      "<h5>International Orders</h5>\n" +
      "                 <p>We are pleased to be able to ship our <strong>physical products</strong> to Canada and the UK, however we want you to be aware that international shipping does come with a few additional conditions that we want you to be aware of so you have all of the information you need.</p>\n" +
      "                 <p><strong>Please only place your international order if you are comfortable with the following:</strong></p>\n" +
      "                 <ul>\n" +
      "                     <li>We are not liable for and will not refund any orders lost, damaged or confiscated by customs; you are purchasing at your own risk.</li>\n" +
      "                     <li>Some duty tax fees may apply, however <strong>we cannot predict them in advance</strong>. Please refer to your Customs agency for more information before ordering.</li>\n" +
      "                     <li>We do not consider packages lost in transit until 45 days after shipment. If determined lost, we must open a tracer claim with the shipping carrier before taking any corrective action. If your package is determined lost, a lost package affidavit email will be sent from our third-party claims company to confirm non-delivery. All lost package claims must be reported within 90 days of the original purchase date.</li>\n" +
      "                     <li>If a package is returned by Customs in good condition, we will refund you for the cost of the product minus shipping costs.</li>\n" +
      "                 </ul>\n" +
      "                 <h5>International Order Tracking</h5>\n" +
      "                 <p>Tracking may vary depending on the delivery provider used.  For example, UPS and  FedEx provide visibility of the complete route (i.e. within the USA, through customs and into Canada/UK).</p>\n" +
      "                 <p>USPS tracking works up to customs.  Once the package clears customs, Canada Post/Royal Post handles final delivery and USPS tracking no longer works, so we may not be able to provide tracking information up to delivery once Canada Post/Royal Post takes over.</p>\n" +
      "                 <h5>International Delivery Times</h5>\n" +
      "                 <p>Orders placed by 2 PM EST will start processing the next business day. Processing usually takes 1–3 business days.</p>\n" +
      "                 <p>International orders are usually delivered within 1-2 weeks. However, due to ongoing impacts of the pandemic and customs procedures, international shipments may experience delays and delivery may take up to 3-6 weeks. We thank you for your patience and understanding.</p>\n" +
      "                 <p>Please note: these delivery times are estimates and may not allow for unforeseen circumstances outside of our control, such as a strike, natural disaster, or severe winter storm.</p>\n" +
      "                 <h5>Business Days and Holidays</h5>\n" +
      "                 <p>All orders process and ship Monday through Friday, excluding federal holidays within the United States. If the order is placed after the cut-off time, it will be processed the following business day. Packages do not ship from our distribution centers on weekends or the following US holidays:</p>\n" +
      "                 <p class='m--0'><strong>Jan 1:</strong> New Year’s Day</p>\n" +
      "                 <p class='m--0'><strong>Third Monday in January:</strong> Martin Luther King Jr. Day</p>\n" +
      "                 <p class='m--0'><strong>Third Monday in February:</strong> President's Day</p>\n" +
      "                 <p class='m--0'><strong>Last Monday of May:</strong> Memorial Day</p>\n" +
      "                 <p class='m--0'><strong>July 4th:</strong> 4th of July</p>\n" +
      "                 <p class='m--0'><strong>First Monday of September:</strong> Labor Day</p>\n" +
      "                 <p class='m--0'><strong>Second Monday of October:</strong> Columbus Day</p>\n" +
      "                 <p class='m--0'><strong>November 11:</strong> Veteran's Day</p>\n" +
      "                 <p class='m--0'><strong>Fourth Thursday of November:</strong> Thanksgiving</p>\n" +
      "                 <p><strong>December 25:</strong> Christmas</p>\n" +
      '                 <p>If you have any questions please feel free to contact us at <a href="mailto:support@thebettyrocker.com">support@thebettyrocker.com</a></p>',
  },
}
export default legalData
