import React from "react"

// Components
import ShippingIcons from "../ProductInfoComponents/ShippingIcons"
// Data
import productData from "../../../../static/data/product"

const VanillaProteinChallengerAddToCart = ({
  alreadyInCart,
  theme,
  variantId,
  productId,
  quantity,
  price,
  title,
  isCart,
}) => {
  return (
    <div>
      <h5 className="txt--center mb--15">
        <strong>
          Exclusive offer for <span className="d-inline-block">Challengers ONLY!</span>
        </strong>
      </h5>

      <div className="product__description--prices">
        <div className="row gutters-7_5 justify-content-center align-items-end">
          <div className="col-9 col-sm-6 col-md-5 col-lg-7 col-xl-6 d-flex flex-column align-items-end txt--right">
            <h5 className="mb--10">
              <strong className="fw--medium">
                I <i className="fas fa-heart txt--pink" /> Vanilla Protein:{" "}
                <span className="d-inline-block">Regular Price -</span>
              </strong>
            </h5>
          </div>

          <div className="col-3 col-sm-2">
            <h5 className="mb--10">
              <strong>
                <span>$44.95</span>
              </strong>
            </h5>
          </div>
        </div>

        <div className="row gutters-7_5 justify-content-center align-items-end">
          <div className="col-9 col-sm-6 col-md-5 col-lg-7 col-xl-6 d-flex flex-column align-items-end txt--right">
            <h5 className="mb--10">
              <strong>
                30 Day Calendar Board: <span className="d-inline-block">Value -</span>
              </strong>
            </h5>
          </div>

          <div className="col-3 col-sm-2">
            <h5 className="mb--10">
              <strong>
                <span>$14.95</span>
              </strong>
            </h5>
          </div>
        </div>

        <div className="row gutters-7_5 justify-content-center align-items-end">
          <div className="col-9 col-sm-6 col-md-5 col-lg-7 col-xl-6 d-flex flex-column align-items-end txt--right">
            <h5 className="mb--10">
              <strong className="fw--regular">Total Value - </strong>
            </h5>
          </div>

          <div className="col-3 col-sm-2">
            <h5 className="mb--10">
              <strong>
                <span>$59.90</span>
              </strong>
            </h5>
          </div>
        </div>

        <div className="row gutters-7_5 justify-content-center align-items-end">
          <div className="col-9 col-sm-6 col-md-5 col-lg-7 col-xl-6 d-flex flex-column align-items-end txt--right">
            <h4 className="txt--pink">
              <strong className="fw--medium">Your Price:</strong> <strong>Today Only - </strong>
            </h4>
          </div>

          <div className="col-3 col-sm-2">
            <h4 className="txt--pink">
              <strong>
                <span>$37.00</span>
              </strong>
            </h4>
          </div>
        </div>
      </div>

      <div className="product__img-price">
        <h6>
          <strong className="fw--regular">You Save - </strong>
          <strong>$22.90</strong>
        </h6>
      </div>

      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center flex-wrap info-buttons">
          {alreadyInCart ? (
            <button aria-label="Added to Cart." disabled className="btn btn--md mb--10">
              added to cart
            </button>
          ) : (
            <button
              aria-label="Add to Cart."
              className="btn btn--md btn--xs-full mb--10 js-shopify-cart"
              onClick={() =>
                theme.addVariantToCart(
                  variantId,
                  productId,
                  quantity,
                  price,
                  "No",
                  title,
                  productData[variantId].type,
                  {},
                  {},
                  isCart,
                )
              }
            >
              add to cart
            </button>
          )}
        </div>

        <div className="d-flex justify-content-between align-items-center flex-wrap flex-column flex-sm-row mb--20 mb--xs-10">
          <img
            src="/images/credit-cards.png"
            alt=""
            width="300"
            height="39"
            className="modal__credit-cards no-shadow"
          />
          <ShippingIcons modifierClass="ml--10" />
        </div>
      </div>
    </div>
  )
}
export default VanillaProteinChallengerAddToCart
