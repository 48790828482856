// PROMO PRODUCTS OFFER CONTENT ---------------------------------------
const bfsOfferContent = {
  offerProduct: "bfs",
  img: "offer-bfs",
  singleProduct: "Body Fuel System",
  offerBundle: "Body Fuel System <strong>Protein Bundle</strong>",
}
const hwdOfferContent = {
  offerProduct: "hwd",
  img: "offer-hwd",
  singleProduct: "Home Workout Domination",
  offerBundle: "Home Workout Domination <strong>Protein Bundle</strong>",
}

const baachOfferContent = {
  offerProduct: "bac",
  img: "offer-baach",
  singleProduct: "Booty and Abs Challenge",
  offerBundle: "Booty and Abs Challenge <strong>Protein Bundle</strong>",
}
const thirtyDmpOfferContent = {
  offerProduct: "30dmp",
  img: "offer-30dmp",
  singleProduct: "30 Day Meal Plan",
  offerBundle: "30 Day Meal Plan <strong>Protein Bundle</strong>",
}
const lionessOfferContent = {
  offerProduct: "lioness",
  img: "offer-lioness",
  singleProduct: "Lioness",
  offerBundle: "Lioness <strong>Protein Bundle</strong>",
}
const ninetyDchOfferContent = {
  offerProduct: "90dch",
  img: "offer-90dch",
  singleProduct: "90 Day Challenge",
  offerBundle: "90 Day Challenge <strong>Protein Bundle</strong>",
}
const mdpOfferContent = {
  offerProduct: "mdp",
  img: "offer-mdp",
  singleProduct: "My Dinner Plan",
  offerBundle: "My Dinner Plan <strong>Protein Bundle</strong>",
}

// SHOPIFY PRODUCTS CONTENT ---------------------------------------
const productData = {
  // SUPPLEMENTS ---------------------------------------

  // I Love Vanilla
  "gid://shopify/ProductVariant/37751712415936": {
    shopifyId: "gid://shopify/Product/6121460564160",
    type: "supplements",
    title: "I ❤ Vanilla Protein",
    notice: "",
    img: ["vanilla"],
    // quote:
    //   "This is literally the BEST vanilla protein powder I have ever tasted, and I'm super picky!",
    // quoteAuthor: "Heather M",
    collection: "proteins",
    paymentOptions: 2,
    discountPercentage: 11.13,
    sellingPlans: [
      {
        sellingPlanId: 2055667904,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 2055700672,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 2055733440,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    bundleId: "gid://shopify/ProductVariant/41240315855040", // I Love Vanilla Bundle,
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // I Love Chocolate Protein
  "gid://shopify/ProductVariant/43069137518784": {
    shopifyId: "gid://shopify/Product/7330569388224",
    type: "supplements",
    title: "I ❤ Chocolate Protein",
    notice: "",
    img: ["chocolate"],
    // quote:
    //   "It's so good - it just tastes like chocolate! What I usually don't like about protein powders is that they're too grainy - but in this you can really taste the flavor and you're not distracted by the texture - it just tastes likes a smoothie.",
    // quoteAuthor: "Adei G",
    collection: "proteins",
    paymentOptions: 2,
    discountPercentage: 11.13,
    sellingPlans: [
      {
        sellingPlanId: 1239548096,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1239580864,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1239613632,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    bundleId: "gid://shopify/ProductVariant/43274468491456", // I love Chocolate Protein Bundle
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // I Love Vanilla (challenger special)
  "gid://shopify/ProductVariant/42182264029376": {
    shopifyId: "gid://shopify/Product/7199137923264",
    type: "supplements",
    title: "I ❤ Vanilla Protein",
    notice: "",
    img: ["vanilla"],
    // quote:
    //   "This is literally the BEST vanilla protein powder I have ever tasted, and I'm super picky!",
    // quoteAuthor: "Heather M",
    hidden: true,
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Berry Green Protein
  "gid://shopify/ProductVariant/20808707866694": {
    shopifyId: "gid://shopify/Product/2088294514758",
    type: "supplements",
    title: "Berry Green Protein",
    notice: "",
    img: ["bgp"],
    // quote:
    //  "I love that the Berry Green Protein is delicious just with water when I’m in a hurry or I can put it in my smoothies - and I’m going to bake with it next!",
    // quoteAuthor: "Heather M",
    collection: "proteins",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 555286720,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 555319488,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 555352256,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    bundleId: "gid://shopify/ProductVariant/41240314347712", // Berry Green Protein Bundle,
    references: false,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Rock and Restore
  "gid://shopify/ProductVariant/40147557646528": {
    shopifyId: "gid://shopify/Product/6752033734848",
    type: "supplements",
    title: "Rock and Restore",
    notice: "sale",
    img: ["rock-and-restore"],
    // quote:
    //   "I notice a difference in my recovery time for my workouts, and my muscle definition seems to be popping more.",
    // quoteAuthor: "Amanda M.",
    collection: "proteins",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 554991808,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 555024576,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 555057344,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    bundleId: "gid://shopify/ProductVariant/41240317132992", // Rock and Restore Bundle
    references: false,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Full Body Collagen
  "gid://shopify/ProductVariant/31275282399302": {
    shopifyId: "gid://shopify/Product/4368664854598",
    type: "supplements",
    title: "Full Body Collagen",
    notice: "sale",
    img: ["fbc"],
    // quote:
    //   "I have used other collagens in the past and this one is by far the cleanest and the smoothest - it’s completely tasteless and it’s very versatile!",
    // quoteAuthor: "Stephenie M",
    collection: "proteins",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 555188416,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 555221184,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 555253952,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    bundleId: "gid://shopify/ProductVariant/41240318050496", // Full Body Collagen Bundle
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // SereniCalm
  "gid://shopify/ProductVariant/32084349157446": {
    shopifyId: "gid://shopify/Product/4679620034630",
    type: "supplements",
    title: "SereniCalm",
    notice: "",
    img: ["serenicalm"],
    quote:
      "I feel so much lighter, like a burden has been lifted from me. Serenicalm works extremely well for me, and I would highly recommend this product to anyone who suffers any form of anxiety or stress.",
    quoteAuthor: "Patricia B",
    collection: "other",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 554893504,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 554926272,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 554959040,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Whole Sleep
  "gid://shopify/ProductVariant/31431700545606": {
    shopifyId: "gid://shopify/Product/4423895580742",
    type: "supplements",
    title: "Whole Sleep",
    notice: "sale",
    img: ["wholesleep"],
    quote:
      "Taking this product I was able to get to sleep no problem, I stayed asleep and I didn't wake up throughout the night like I usually do.",
    quoteAuthor: "Sherri J",
    collection: "other",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 554795200,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 554827968,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 554860736,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },

  // MEAL PLANS ---------------------------------------

  // --- DINNER PLAN ---------------------------------------
  "gid://shopify/ProductVariant/43693693960384": {
    shopifyId: "gid://shopify/Product/7492241817792",
    type: "meal plan",
    notice: "new",
    title: "My Dinner Plan",
    img: ["mdp"],
    quote:
      "All the meals have been so delicious! I found the program very user friendly and the grocery lists are awesome!!",
    quoteAuthor: "Aimee J.",
    subscribed: true,
    collection: "meal plan",
    paymentOptions: 2,
    video: 829476253,
    sellingPlans: [
      {
        sellingPlanId: 1649213632,
        sellingPlanName: "You will be charged $49 today, and an additional $49 next 2 months",
        price: 147,
        months: 3,
      },
    ],
    tabs: [],
  },
  // BFS
  "gid://shopify/ProductVariant/39790736146624": {
    shopifyId: "gid://shopify/Product/6667451465920",
    type: "meal plan",
    notice: "sale",
    title: "Body Fuel System",
    img: ["bfs"],
    quote: "The Body Fuel System was everything I needed to get over my weight plateau!",
    quoteAuthor: "Jenna T.",
    collection: "meal plans",
    video: "249962643",
    paymentOptions: 1,
    tabs: ["included", "results", "faq", "start-now"],
  },
  // 30 DCMP
  "gid://shopify/ProductVariant/39790695678144": {
    shopifyId: "gid://shopify/Product/6667428495552",
    type: "meal plan",
    notice: "",
    title: "30-Day Challenge Meal Plan",
    img: ["30dmp"],
    quote: "I got the 30 day meal plan and have been loving it! I am now down a total of 14 pounds as of this morning!",
    quoteAuthor: "Richelle J.",
    collection: "meal plans",
    video: "142589434",
    paymentOptions: 1,
    tabs: ["included", "results", "faq", "start-now"],
  },
  // 7 DMP
  "gid://shopify/ProductVariant/39790129905856": {
    shopifyId: "gid://shopify/Product/6667192467648",
    type: "meal plan",
    notice: "sale",
    title: "7-Day Meal Plan",
    img: ["7dmp"],
    quote: "Great coaching, great recipes, and down to earth mentoring. This rocks!",
    quoteAuthor: "Riona H.",
    collection: "meal plans",
    paymentOptions: 1,
    tabs: ["included", "results", "faq", "start-now"],
  },

  // WORKOUT PLANS ---------------------------------------

  // 90DCH
  "gid://shopify/ProductVariant/39790766915776": {
    shopifyId: "gid://shopify/Product/6667468341440",
    type: "workout plan",
    notice: "",
    title: "90-Day Challenge",
    img: ["90dch"],
    quote:
      "I’ve said it before and I’ll say it again – doing your programs has given me so much confidence. I think this is the best I have felt EVER!",
    quoteAuthor: "Angela H.",
    collection: "workout program",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 544964800,
        sellingPlanName: "You will be charged $59 today, and an additional $59 next 2 months",
        price: 177,
        months: 3,
      },
    ],
    tabs: ["included", "results", "faq", "start-now"],
  },
  // HWD
  "gid://shopify/ProductVariant/39790786740416": {
    shopifyId: "gid://shopify/Product/6667479253184",
    type: "workout plan",
    notice: "sale",
    title: "Home Workout Domination",
    img: ["hwd"],
    quote:
      "I’ve been working out and eating well for a long time, but nothing had changed – I think I’ve hit the winning combination with Home Workout Domination!",
    quoteAuthor: "Jennifer H.",
    subscribed: true,
    collection: "workout program",
    video: "229374313",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 555450560,
        sellingPlanName: "You will be charged $39 today, and an additional $39 next 2 months",
        price: 117,
        months: 3,
      },
    ],
    tabs: ["included", "results", "faq", "start-now"],
  },
  // HWD 2
  "gid://shopify/ProductVariant/42865298374848": {
    shopifyId: "gid://shopify/Product/7282410389696",
    type: "workout plan",
    notice: "sale",
    title: "Home Workout Domination 2",
    img: ["hwd2"],
    quote:
      "I'm super stoked on how much I've achieved! I did a lot of cardio  (biking) before this and would loose weight but wasn't getting toned or  seeing the results I wanted to see. Thank you Bree and all the Betty  Rocker coaches!",
    quoteAuthor: "Jenna B.",
    subscribed: true,
    collection: "workout program",
    video: "716580684",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 1207009472,
        sellingPlanName: "You will be charged $33 today, and an additional $33 next 2 months",
        price: 99,
        months: 3,
      },
    ],
    tabs: ["included", "results", "faq", "start-now"],
  },
  // 30 Day Booty Abs Challenge
  "gid://shopify/ProductVariant/39790823768256": {
    shopifyId: "gid://shopify/Product/6667486855360",
    type: "workout plan",
    notice: "sale",
    title: "30-Day Booty + Abs Challenge",
    img: ["bootyabs"],
    quote:
      "I’m REALLY pleased with the changes my body is making! I feel stronger, sexier, lighter, and generally better inside and out.",
    quoteAuthor: "Laura M.",
    collection: "workout program",
    video: "142302588",
    paymentOptions: 1,
    tabs: ["included", "results", "faq", "start-now"],
  },
  // Lioness
  "gid://shopify/ProductVariant/39790810300608": {
    shopifyId: "gid://shopify/Product/6667482759360",
    type: "workout plan",
    notice: "",
    title: "Lioness",
    img: ["lioness"],
    quote:
      "After years of trying to get my body back, the Lioness Program gave me the structure, motivation, and ease of use I’ve been searching for.",
    quoteAuthor: "Julie M.",
    collection: "workout program",
    video: "257596208",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 555483328,
        sellingPlanName: "You will be charged $49 today, and an additional $49 next 2 months.",
        price: 147,
        months: 3,
      },
    ],
    tabs: ["included", "results", "faq", "start-now"],
  },

  // Perimenofit
  "gid://shopify/ProductVariant/45133771964608": {
    shopifyId: "gid://shopify/Product/7928701649088",
    type: "workout plan",
    notice: "",
    title: "PerimenoFit",
    img: ["perimenofit"],
    quote: "",
    quoteAuthor: "",
    collection: "workout program",
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 2097545408,
        sellingPlanName: "You will be charged $57 today, and an additional $57 next 2 months.",
        price: 171,
        months: 3,
      },
    ],
    tabs: ["included", "bonuses", "faq", "start-now"],
  },

  // -------- BUY 3 GET 1 FREE BUNDLES

  // Neapolitan Bundle
  "gid://shopify/ProductVariant/43749167071424": {
    shopifyId: "gid://shopify/Product/7502990639296",
    type: "bundles",
    title: "Neapolitan Bundle",
    notice: "",
    img: ["neapolitan"],
    collection: "proteins",
    paymentOptions: 2,
    noRegularText: true,
    saveText: "(Save Over 25%)",
    freeGift: false,
    references: false,
    tabs: ["berry-green-protein", "vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/20808707866694", // Berry Green Protein
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    sellingPlans: [
      {
        sellingPlanId: 1946550464,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1946583232,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1946616000,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    sellingPlansImg: {
      horizontal: "neapolitan-bundle",
      vertical: "neapolitan-bundle",
    },
  },

  // Workout Bundle - Workout Bundle
  "gid://shopify/ProductVariant/43141487755456": {
    shopifyId: "gid://shopify/Product/7345480204480",
    type: "bundles",
    title: "Workout Bundle (+ Free Protein)",
    notice: "",
    img: ["workout-promo"],
    collection: "proteins",
    freeGift: 44.95,
    freeGiftType: "protein",
    noRegularText: true,
    saveText: "(Save Over 23%)",
    references: false,
    tabs: ["chocolate-protein", "vanilla-protein", "full-body-collagen", "rock-and-restore"],
    paymentOptions: 2,
    sellingPlans: [
      {
        sellingPlanId: 1946419392,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1946484928,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1946517696,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    sellingPlansImg: {
      cover: "workout-bundle-option",
      horizontal: "workout-bundle-option-payment-horizontal",
      vertical: "workout-bundle-option-payment-vertical",
    },
  },
  // Workout Bundle - Vanilla Workout Bundle
  "gid://shopify/ProductVariant/43141487788224": {
    shopifyId: "gid://shopify/Product/7345480204480",
    type: "bundles",
    title: "Workout Bundle (+ Free Protein)",
    notice: "",
    img: ["workout-promo"],
    collection: "proteins",
    paymentOptions: 2,
    freeGift: 44.95,
    freeGiftType: "protein",
    noRegularText: true,
    saveText: "(Save Over 23%)",
    references: false,
    tabs: ["chocolate-protein", "vanilla-protein", "full-body-collagen", "rock-and-restore"],
    sellingPlans: [
      {
        sellingPlanId: 1946419392,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1946484928,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1946517696,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    sellingPlansImg: {
      cover: "vanilla-workout-bundle-option",
      horizontal: "vanilla-workout-bundle-option-payment-horizontal",
      vertical: "vanilla-workout-bundle-option-payment-vertical",
    },
  },
  // Workout Bundle - Chocolate Workout Bundle
  "gid://shopify/ProductVariant/43141487853760": {
    shopifyId: "gid://shopify/Product/7345480204480",
    type: "bundles",
    title: "Workout Bundle (+ Free Protein)",
    notice: "",
    img: ["workout-promo"],
    collection: "proteins",
    paymentOptions: 2,
    freeGift: 44.95,
    freeGiftType: "protein",
    noRegularText: true,
    saveText: "(Save Over 23%)",
    references: false,
    tabs: ["chocolate-protein", "vanilla-protein", "full-body-collagen", "rock-and-restore"],
    sellingPlans: [
      {
        sellingPlanId: 1946419392,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1946484928,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1946517696,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    sellingPlansImg: {
      cover: "chocolate-workout-bundle-option",
      horizontal: "chocolate-workout-bundle-option-payment-horizontal",
      vertical: "chocolate-workout-bundle-option-payment-vertical",
    },
  },

  // I love Vanilla Protein Buy 3 Get 1 Free
  "gid://shopify/ProductVariant/43274364387520": {
    shopifyId: "gid://shopify/Product/7396430971072",
    type: "bundles",
    title: "I ❤ Vanilla Protein (Buy 3 get 1 free)",
    notice: "",
    img: ["vanilla-buy-3-get-1-free"],
    quote: "This is literally the BEST vanilla protein powder I have ever tasted, and I'm super picky!",
    quoteAuthor: "Heather M",
    collection: "proteins",
    paymentOptions: 2,
    freeGift: 44.95,
    freeGiftType: "protein",
    noRegularText: true,
    optionDescription: "Stock up and Save 25% when you buy 3 and get One FREE protein.",
    saveText: "(Save 27%)",
    saveOfferText: "**BEST DEAL - Save 27%**",
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
    sellingPlans: [
      {
        sellingPlanId: 1946648768,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1946681536,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1946714304,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    sellingPlansImg: {
      horizontal: "vanilla-bundle-option-horizontal",
      vertical: "vanilla-bundle-option-vertical",
    },
  },
  // I love Chocolate Protein Buy 3 Get 1 Free
  "gid://shopify/ProductVariant/43274380247232": {
    shopifyId: "gid://shopify/Product/7396440572096",
    type: "bundles",
    title: "I ❤ Chocolate Protein (Buy 3 get 1 free)",
    notice: "",
    img: ["chocolate-buy-3-get-1-free"],
    quote:
      "It's so good - it just tastes like  chocolate! What I usually don't like about protein powders is that  they're too grainy - but in this you can really taste the flavor and  you're not distracted by the texture - it just tastes likes a smoothie.",
    quoteAuthor: "Adei G",
    collection: "proteins",
    paymentOptions: 2,
    freeGift: 44.95,
    freeGiftType: "protein",
    noRegularText: true,
    optionDescription: "Stock up and Save 25% when you buy 3 and get One FREE protein.",
    saveText: "(Save 27%)",
    saveOfferText: "**BEST DEAL - Save 27%**",
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
    sellingPlans: [
      {
        sellingPlanId: 1955365056,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1955561664,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1955594432,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    sellingPlansImg: {
      horizontal: "chocolate-bundle-option-horizontal",
      vertical: "chocolate-bundle-option-vertical",
    },
  },
  // Rock and Restore Buy 3 Get 1 Free
  "gid://shopify/ProductVariant/43274408263872": {
    shopifyId: "gid://shopify/Product/7396462231744",
    type: "bundles",
    title: "Rock and Restore Aminos (Buy 3 get 1 free)",
    notice: "",
    img: ["rock-and-restore-buy-3-get-1-free"],
    quote:
      "I notice a difference in my recovery time for my workouts, and my muscle definition seems to be popping more.",
    quoteAuthor: "Amanda M.",
    collection: "proteins",
    paymentOptions: 2,
    freeGift: 59.95,
    freeGiftType: "protein",
    noRegularText: true,
    optionDescription: "Stock up and Save 25% when you buy 3 and get One FREE Rock and Restore.",
    saveText: "(Save 25%)",
    saveOfferText: "**BEST DEAL - Save 25%**",
    references: false,
    tabs: ["about", "nutrition", "why-buy", "faq"],
    sellingPlans: [
      {
        sellingPlanId: 1955397824,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1955627200,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1955659968,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    sellingPlansImg: {
      horizontal: "rock-restore-bundle-option-horizontal",
      vertical: "rock-restore-bundle-option-vertical",
    },
  },
  // Full Body Collagen Buy 3 Get 1 Free
  "gid://shopify/ProductVariant/43274401710272": {
    shopifyId: "gid://shopify/Product/7396457218240",
    type: "bundles",
    title: "Full Body Collagen (Buy 3 get 1 free)",
    notice: "",
    img: ["fbc-buy-3-get-1-free"],
    quote:
      "I have used other collagens in the past and this one is by far the cleanest and the smoothest - it’s completely tasteless and it’s very versatile!",
    quoteAuthor: "Stephenie M",
    collection: "proteins",
    paymentOptions: 2,
    freeGift: 59.95,
    freeGiftType: "protein",
    noRegularText: true,
    optionDescription: "Stock up and Save 25% when you buy 3 and get One FREE Full Body Collagen.",
    saveText: "(Save 25%)",
    saveOfferText: "**BEST DEAL - Save 25%**",
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
    sellingPlans: [
      {
        sellingPlanId: 1955332288,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1955496128,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1955528896,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    sellingPlansImg: {
      horizontal: "full-body-collagen-bundle-option-horizontal",
      vertical: "full-body-collagen-bundle-option-vertical",
    },
  },
  // Berry Green Protein Buy 3 Get 1 Free
  "gid://shopify/ProductVariant/43274391945408": {
    shopifyId: "gid://shopify/Product/7396449091776",
    type: "bundles",
    title: "Berry Green Protein (Buy 3 get 1 free)",
    notice: "",
    img: ["bgp-buy-3-get-1-free"],
    quote:
      "I love that the Berry Green Protein is delicious just with water when I’m in a hurry or I can put it in my smoothies - and I’m going to bake with it next!",
    quoteAuthor: "Holly L",
    collection: "proteins",
    paymentOptions: 2,
    freeGift: 54.95,
    freeGiftType: "protein",
    noRegularText: true,
    optionDescription: "Stock up and Save 25% when you buy 3 and get One FREE protein.",
    saveText: "(Save 25%)",
    saveOfferText: "**BEST DEAL - Save 25%**",
    references: false,
    tabs: ["about", "nutrition", "why-buy", "faq"],
    sellingPlans: [
      {
        sellingPlanId: 1955299520,
        orderIntervalFrequency: 30,
        sellingPlanName: "Deliver every 30 Days",
      },
      {
        sellingPlanId: 1955430592,
        orderIntervalFrequency: 60,
        sellingPlanName: "Deliver every 60 Days",
      },
      {
        sellingPlanId: 1955463360,
        orderIntervalFrequency: 90,
        sellingPlanName: "Deliver every 90 Days",
      },
    ],
    sellingPlansImg: {
      horizontal: "berry-green-bundle-option-horizontal",
      vertical: "berry-green-bundle-option-vertical",
    },
  },

  // 4 Pillars of Health Bundle
  "gid://shopify/ProductVariant/41240319426752": {
    shopifyId: "gid://shopify/Product/7004128313536",
    type: "bundles",
    title: "4 Pillars of Health Bundle",
    notice: "",
    img: ["pillars"],
    collection: "proteins",
    paymentOptions: 1,
    freeGift: 12.95,
    freeGiftType: "gift",
    noRegularText: false,
    saveText: "(Save Over 13%)",
    references: false,
    tabs: ["rock-and-restore", "vanilla-protein", "whole-sleep", "serenicalm"],
  },
  // I love Vanilla Protein Bundle
  "gid://shopify/ProductVariant/41240315855040": {
    shopifyId: "gid://shopify/Product/7004126970048",
    type: "bundles",
    title: "I ❤ Vanilla Protein Bundle",
    notice: "",
    img: ["vanilla-promo"],
    quote: "This is literally the BEST vanilla protein powder I have ever tasted, and I'm super picky!",
    quoteAuthor: "Heather M",
    collection: "proteins",
    paymentOptions: 1,
    freeGift: 14.95,
    freeGiftType: "gift",
    noRegularText: false,
    saveOfferText: "(Save Over 10%)",
    saveText: "(Save Over 10%)",
    optionDescription: "Stock up and Save 10% when you buy 3 and get a FREE 30-day challenge calendar tracker.",
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // I love Chocolate Protein Bundle
  "gid://shopify/ProductVariant/43274468491456": {
    shopifyId: "gid://shopify/Product/7396508893376",
    type: "bundles",
    title: "I ❤ Chocolate Protein Bundle",
    notice: "",
    img: ["chocolate-promo"],
    quote:
      "It's so good - it just tastes like chocolate! What I usually don't like about protein powders is that they're too grainy - but in this you can really taste the flavor and you're not distracted by the texture - it just tastes likes a smoothie.",
    quoteAuthor: "Adei G",
    collection: "proteins",
    paymentOptions: 1,
    freeGift: 14.95,
    freeGiftType: "gift",
    noRegularText: false,
    saveOfferText: "(Save Over 10%)",
    saveText: "(Save Over 10%)",
    optionDescription: "Stock up and Save 10% when you buy 3 and get a FREE 30-day challenge calendar tracker.",
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Berry Green Protein Bundle
  "gid://shopify/ProductVariant/41240314347712": {
    shopifyId: "gid://shopify/Product/7004126478528",
    type: "bundles",
    title: "Berry Green Protein Bundle",
    notice: "",
    img: ["bgp-promo"],
    quote:
      "I love that the Berry Green Protein is delicious just with water when I’m in a hurry or I can put it in my smoothies - and I’m going to bake with it next!",
    quoteAuthor: "Holly L",
    collection: "proteins",
    paymentOptions: 1,
    freeGift: 14.95,
    saveOfferText: "(Save Over 10%)",
    saveText: "(Save Over 10%)",
    noRegularText: false,
    freeGiftType: "gift",
    optionDescription: "Stock up and Save 10% when you buy 3 and get a FREE 30-day challenge calendar tracker.",
    references: false,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Rock and Restore Bundle
  "gid://shopify/ProductVariant/41240317132992": {
    shopifyId: "gid://shopify/Product/7004127494336",
    type: "bundles",
    title: "Rock and Restore Bundle",
    notice: "",
    img: ["rock-and-restore-promo"],
    quote:
      "I notice a difference in my recovery time for my workouts, and my muscle definition seems to be popping more.",
    quoteAuthor: "Amanda M.",
    collection: "proteins",
    paymentOptions: 1,
    freeGift: 14.95,
    freeGiftType: "gift",
    noRegularText: false,
    saveOfferText: "(Save Over 10%)",
    saveText: "(Save Over 10%)",
    optionDescription: "Stock up and Save 10% when you buy 3 and get a FREE Stop, Drop and Betty Rock shaker bottle.",
    references: false,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },
  // Full Body Collagen Bundle
  "gid://shopify/ProductVariant/41240318050496": {
    shopifyId: "gid://shopify/Product/7004127854784",
    type: "bundles",
    title: "Full Body Collagen Bundle",
    notice: "",
    img: ["fbc-promo"],
    quote:
      "I have used other collagens in the past and this one is by far the cleanest and the smoothest - it’s completely tasteless and it’s very versatile!",
    quoteAuthor: "Stephenie M",
    collection: "proteins",
    paymentOptions: 1,
    freeGift: 14.95,
    freeGiftType: "gift",
    noRegularText: false,
    saveOfferText: "(Save Over 10%)",
    saveText: "(Save Over 10%)",
    optionDescription: "Stock up and Save 10% when you buy 3 and get a FREE All or Something grocery tote.",
    references: true,
    tabs: ["about", "nutrition", "why-buy", "faq"],
  },

  // Home Workout Domination Protein Bundles ------
  // 30 day All Vanilla
  "gid://shopify/ProductVariant/44562535678144": {
    shopifyId: "gid://shopify/Product/7729718329536",
    type: "bundles",
    title: "Home Workout Domination Protein Bundle",
    notice: "",
    img: ["home-workout-domination-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: hwdOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day All Chocolate
  "gid://shopify/ProductVariant/44562535710912": {
    shopifyId: "gid://shopify/Product/7729718329536",
    type: "bundles",
    title: "Home Workout Domination Protein Bundle",
    notice: "",
    img: ["home-workout-domination-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: hwdOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day Half and Half
  "gid://shopify/ProductVariant/44562535743680": {
    shopifyId: "gid://shopify/Product/7729718329536",
    type: "bundles",
    title: "Home Workout Domination Protein Bundle",
    notice: "",
    img: ["home-workout-domination-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: hwdOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Vanilla
  "gid://shopify/ProductVariant/44562535776448": {
    shopifyId: "gid://shopify/Product/7729718329536",
    type: "bundles",
    title: "Home Workout Domination Protein Bundle",
    notice: "",
    img: ["home-workout-domination-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: hwdOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Chocolate
  "gid://shopify/ProductVariant/44562535809216": {
    shopifyId: "gid://shopify/Product/7729718329536",
    type: "bundles",
    title: "Home Workout Domination Protein Bundle",
    notice: "",
    img: ["home-workout-domination-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: hwdOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day Half and Half
  "gid://shopify/ProductVariant/44562535841984": {
    shopifyId: "gid://shopify/Product/7729718329536",
    type: "bundles",
    title: "Home Workout Domination Protein Bundle",
    notice: "",
    img: ["home-workout-domination-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: hwdOfferContent,
    noQuantity: true,
    hidden: true,
  },

  // Body Fuel System Protein Bundles ------
  // 30 day All Vanilla
  "gid://shopify/ProductVariant/44657971560640": {
    shopifyId: "gid://shopify/Product/7754890674368",
    type: "bundles",
    title: "Body Fuel System Protein Bundle",
    notice: "",
    img: ["body-fuel-system-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: bfsOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day All Chocolate
  "gid://shopify/ProductVariant/44657971527872": {
    shopifyId: "gid://shopify/Product/7754890674368",
    type: "bundles",
    title: "Body Fuel System Protein Bundle",
    notice: "",
    img: ["body-fuel-system-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: bfsOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day Half and Half
  "gid://shopify/ProductVariant/44657971495104": {
    shopifyId: "gid://shopify/Product/7754890674368",
    type: "bundles",
    title: "Body Fuel System Protein Bundle",
    notice: "",
    img: ["body-fuel-system-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: bfsOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Vanilla
  "gid://shopify/ProductVariant/44657971658944": {
    shopifyId: "gid://shopify/Product/7754890674368",
    type: "bundles",
    title: "Body Fuel System Protein Bundle",
    notice: "",
    img: ["body-fuel-system-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: bfsOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Chocolate
  "gid://shopify/ProductVariant/44657971626176": {
    shopifyId: "gid://shopify/Product/7754890674368",
    type: "bundles",
    title: "Body Fuel System Protein Bundle",
    notice: "",
    img: ["body-fuel-system-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: bfsOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day Half and Half
  "gid://shopify/ProductVariant/44657971593408": {
    shopifyId: "gid://shopify/Product/7754890674368",
    type: "bundles",
    title: "Body Fuel System Protein Bundle",
    notice: "",
    img: ["body-fuel-system-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: bfsOfferContent,
    noQuantity: true,
    hidden: true,
  },

  // Booty and Abs Challenge Protein Bundle ------
  // 30 day All Vanilla
  "gid://shopify/ProductVariant/45002039984320": {
    shopifyId: "gid://shopify/Product/7874233270464",
    type: "bundles",
    title: "Booty and Abs Challenge Protein Bundle",
    notice: "",
    img: ["baach-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: baachOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day All Chocolate
  "gid://shopify/ProductVariant/45002039951552": {
    shopifyId: "gid://shopify/Product/7874233270464",
    type: "bundles",
    title: "Booty and Abs Challenge Protein Bundle",
    notice: "",
    img: ["baach-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: baachOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day Half and Half
  "gid://shopify/ProductVariant/45002039918784": {
    shopifyId: "gid://shopify/Product/7874233270464",
    type: "bundles",
    title: "Booty and Abs Challenge Protein Bundle",
    notice: "",
    img: ["baach-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: baachOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Vanilla
  "gid://shopify/ProductVariant/45002040082624": {
    shopifyId: "gid://shopify/Product/7874233270464",
    type: "bundles",
    title: "Booty and Abs Challenge Protein Bundle",
    notice: "",
    img: ["baach-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: baachOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Chocolate
  "gid://shopify/ProductVariant/45002040049856": {
    shopifyId: "gid://shopify/Product/7874233270464",
    type: "bundles",
    title: "Booty and Abs Challenge Protein Bundle",
    notice: "",
    img: ["baach-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: baachOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day Half and Half
  "gid://shopify/ProductVariant/45002040017088": {
    shopifyId: "gid://shopify/Product/7874233270464",
    type: "bundles",
    title: "Booty and Abs Challenge Protein Bundle",
    notice: "",
    img: ["baach-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: baachOfferContent,
    noQuantity: true,
    hidden: true,
  },

  // 30 Day Meal Plan Protein Bundle ------
  // 30 day All Vanilla
  "gid://shopify/ProductVariant/44632243339456": {
    shopifyId: "gid://shopify/Product/7749101551808",
    type: "bundles",
    title: "30 Day Meal Plan Protein Bundle",
    notice: "",
    img: ["30dmp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: thirtyDmpOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day All Chocolate
  "gid://shopify/ProductVariant/44632243306688": {
    shopifyId: "gid://shopify/Product/7749101551808",
    type: "bundles",
    title: "30 Day Meal Plan Protein Bundle",
    notice: "",
    img: ["30dmp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: thirtyDmpOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day Half and Half
  "gid://shopify/ProductVariant/44632243273920": {
    shopifyId: "gid://shopify/Product/7749101551808",
    type: "bundles",
    title: "30 Day Meal Plan Protein Bundle",
    notice: "",
    img: ["30dmp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: thirtyDmpOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Vanilla
  "gid://shopify/ProductVariant/44632958959808": {
    shopifyId: "gid://shopify/Product/7749101551808",
    type: "bundles",
    title: "30 Day Meal Plan Protein Bundle",
    notice: "",
    img: ["30dmp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: thirtyDmpOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Chocolate
  "gid://shopify/ProductVariant/44632958927040": {
    shopifyId: "gid://shopify/Product/7749101551808",
    type: "bundles",
    title: "30 Day Meal Plan Protein Bundle",
    notice: "",
    img: ["30dmp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: thirtyDmpOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day Half and Half
  "gid://shopify/ProductVariant/44632958894272": {
    shopifyId: "gid://shopify/Product/7749101551808",
    type: "bundles",
    title: "30 Day Meal Plan Protein Bundle",
    notice: "",
    img: ["30dmp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: thirtyDmpOfferContent,
    noQuantity: true,
    hidden: true,
  },

  // My Dinner Plan Protein Bundle ------
  // 30 day All Vanilla
  "gid://shopify/ProductVariant/45029868961984": {
    shopifyId: "gid://shopify/Product/7891449905344",
    type: "bundles",
    title: "My Dinner Plan Protein Bundle",
    notice: "",
    img: ["mdp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: mdpOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day All Chocolate
  "gid://shopify/ProductVariant/45029868929216": {
    shopifyId: "gid://shopify/Product/7891449905344",
    type: "bundles",
    title: "My Dinner Plan Protein Bundle",
    notice: "",
    img: ["mdp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: mdpOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day Half and Half
  "gid://shopify/ProductVariant/45029868896448": {
    shopifyId: "gid://shopify/Product/7891449905344",
    type: "bundles",
    title: "My Dinner Plan Protein Bundle",
    notice: "",
    img: ["mdp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: mdpOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Vanilla
  "gid://shopify/ProductVariant/45029869060288": {
    shopifyId: "gid://shopify/Product/7891449905344",
    type: "bundles",
    title: "My Dinner Plan Protein Bundle",
    notice: "",
    img: ["mdp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: mdpOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Chocolate
  "gid://shopify/ProductVariant/45029869027520": {
    shopifyId: "gid://shopify/Product/7891449905344",
    type: "bundles",
    title: "My Dinner Plan Protein Bundle",
    notice: "",
    img: ["mdp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: mdpOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day Half and Half
  "gid://shopify/ProductVariant/45029868994752": {
    shopifyId: "gid://shopify/Product/7891449905344",
    type: "bundles",
    title: "My Dinner Plan Protein Bundle",
    notice: "",
    img: ["mdp-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: mdpOfferContent,
    noQuantity: true,
    hidden: true,
  },

  // 90 Day Challenge Protein Bundle ------
  // 30 day All Vanilla
  "gid://shopify/ProductVariant/44649379725504": {
    shopifyId: "gid://shopify/Product/7751589953728",
    type: "bundles",
    title: "90 Day Challenge Protein Bundle",
    notice: "",
    img: ["90dch-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: ninetyDchOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day All Chocolate
  "gid://shopify/ProductVariant/44649379692736": {
    shopifyId: "gid://shopify/Product/7751589953728",
    type: "bundles",
    title: "90 Day Challenge Protein Bundle",
    notice: "",
    img: ["90dch-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: ninetyDchOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day Half and Half
  "gid://shopify/ProductVariant/44649379659968": {
    shopifyId: "gid://shopify/Product/7751589953728",
    type: "bundles",
    title: "90 Day Challenge Protein Bundle",
    notice: "",
    img: ["90dch-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: ninetyDchOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Vanilla
  "gid://shopify/ProductVariant/44649379823808": {
    shopifyId: "gid://shopify/Product/7751589953728",
    type: "bundles",
    title: "90 Day Challenge Protein Bundle",
    notice: "",
    img: ["90dch-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: ninetyDchOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Chocolate
  "gid://shopify/ProductVariant/44649379791040": {
    shopifyId: "gid://shopify/Product/7751589953728",
    type: "bundles",
    title: "90 Day Challenge Protein Bundle",
    notice: "",
    img: ["90dch-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: ninetyDchOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day Half and Half
  "gid://shopify/ProductVariant/44649379758272": {
    shopifyId: "gid://shopify/Product/7751589953728",
    type: "bundles",
    title: "90 Day Challenge Protein Bundle",
    notice: "",
    img: ["90dch-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: ninetyDchOfferContent,
    noQuantity: true,
    hidden: true,
  },

  // Lioness Protein Bundle ------
  // 30 day All Vanilla
  "gid://shopify/ProductVariant/44649381200064": {
    shopifyId: "gid://shopify/Product/7751590510784",
    type: "bundles",
    title: "Lioness Protein Bundle",
    notice: "",
    img: ["lioness-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: lionessOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day All Chocolate
  "gid://shopify/ProductVariant/44649381167296": {
    shopifyId: "gid://shopify/Product/7751590510784",
    type: "bundles",
    title: "Lioness Protein Bundle",
    notice: "",
    img: ["lioness-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: lionessOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 30 day Half and Half
  "gid://shopify/ProductVariant/44649381134528": {
    shopifyId: "gid://shopify/Product/7751590510784",
    type: "bundles",
    title: "Lioness Protein Bundle",
    notice: "",
    img: ["lioness-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: lionessOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Vanilla
  "gid://shopify/ProductVariant/44649381298368": {
    shopifyId: "gid://shopify/Product/7751590510784",
    type: "bundles",
    title: "Lioness Protein Bundle",
    notice: "",
    img: ["lioness-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: lionessOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day All Chocolate
  "gid://shopify/ProductVariant/44649381265600": {
    shopifyId: "gid://shopify/Product/7751590510784",
    type: "bundles",
    title: "Lioness Protein Bundle",
    notice: "",
    img: ["lioness-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: lionessOfferContent,
    noQuantity: true,
    hidden: true,
  },
  // 60 day Half and Half
  "gid://shopify/ProductVariant/44649381232832": {
    shopifyId: "gid://shopify/Product/7751590510784",
    type: "bundles",
    title: "Lioness Protein Bundle",
    notice: "",
    img: ["lioness-protein-bundle"],
    collection: "proteins",
    paymentOptions: 1,
    noRegularText: true,
    freeGift: false,
    references: false,
    tabs: ["vanilla-protein", "chocolate-protein"],
    productsIncluded: [
      "gid://shopify/ProductVariant/37751712415936", // I Love Vanilla Protein
      "gid://shopify/ProductVariant/43069137518784", // I Love Chocolate Protein
    ],
    offer: true,
    offerContent: lionessOfferContent,
    noQuantity: true,
    hidden: true,
  },
}

export default productData
