import React from "react"

const VanillaProteinChallengerBanner = () => {
  return (
    <div className="product__banner">
      <p className="product__banner--text">
        <i className="fas fa-gift" /> FREE GIFT
      </p>
      <div className="row align-items-center justify-content-center">
        <div className="col-md-7 col-lg-6 col-xl-7 d-flex justify-content-center justify-content-md-end align-items-center order-2 order-md-1">
          <img
            alt="30 Day Challenge."
            width="408"
            height="315"
            className="lazy shadow-3"
            src="/images/product-banner-calendar.png"
          />
        </div>

        <div className="col-md-5 col-lg-6 col-xl-5 txt--center order-1 order-md-2 mb--md-30">
          <h4 className="mb--10">
            <strong>Claim Your Free Gift Today!</strong>
          </h4>
          <h4 className="txt--pink mb--10">
            <strong>30-Day Challenge Calendar Tracker</strong>
          </h4>
          <h4>
            <strong className="fw--regular">
              Stay motivated and on track!
            </strong>
          </h4>
        </div>
      </div>
    </div>
  )
}
export default VanillaProteinChallengerBanner
