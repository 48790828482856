import React from "react"

/**
 * @param theme - theme
 * */
const DiscountBtn = ({ theme }) => {
  return (
    <div className="discount-btn__container">
      <div className="discount-btn">
        ENDS {theme.discountThroughText}! <i className="far fa-long-arrow-right" />
      </div>
      <p className="discount-btn__txt">
        Cannot be combined with <br /> Subscribe and Save
      </p>
    </div>
  )
}
export default DiscountBtn
