import React from "react"

// Hooks & Utils
import { formatShopifyId } from "../../utilities/formatString"

// Components
import SidebarItem from "./SidebarItem"
import { Link } from "gatsby"
import DiscountCodeBox from "../Discount/DiscountCodeBox"

const Sidebar = ({ theme }) => {
  const shopifyData = theme.shopifyData

  return (
    <>
      {theme.sidebarOpen && (
        <span
          role="button"
          onKeyDown={theme.handleSidebar}
          onClick={theme.handleSidebar}
          tabIndex={0}
          aria-label="Close Sidebar."
          className="sidebar-overlay"
        />
      )}
      <div className={`sidebar ${theme.sidebarOpen ? "open" : ""}`}>
        <div className="sidebar__header">
          <div className="d-flex justify-content-between align-items-center mb--20 mb--xs-15">
            <h4 className="d-flex align-items-center txt--uppercase">
              <strong>
                your <span className="txt--pink">cart</span>
              </strong>
              {theme.cart && theme.cart.length > 0 && <span className="badge">{theme.cart.length}</span>}
            </h4>
            <button onClick={theme.handleSidebar} className="sidebar__close" aria-label="Close Sidebar.">
              <i className="fal fa-times" />
            </button>
          </div>
        </div>

        <div className="sidebar__content">
          {/*discount box*/}
          {theme.isDiscountActive && <DiscountCodeBox theme={theme} modifierClass="discount-sidebar" isCart={false} />}

          {theme.cart.length ? (
            <div className="sidebar__content__items">
              {theme.cart &&
                theme.cart.map((cartItem, index) => (
                  <React.Fragment key={`cart-item-${index}`}>
                    {shopifyData.map((shopifyProduct, index) => (
                      <React.Fragment key={`sidebar-item-${theme.sidebarOpen}-${index}`}>
                        {((shopifyProduct.variants.length &&
                          cartItem.variantId === formatShopifyId(shopifyProduct.variants[0].shopifyId)) ||
                          cartItem.productId === formatShopifyId(shopifyProduct.shopifyId)) && (
                          <SidebarItem shopifyProduct={shopifyProduct} theme={theme} cartItem={cartItem} />
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
            </div>
          ) : (
            <p className="txt--gray txt--15">There are no items added in the cart.</p>
          )}
        </div>
        <div className="sidebar__footer">
          <h5 className="d-flex justify-content-between align-items-center mb--20 txt--uppercase">
            <span>subtotal</span>
            <span className="txt--pink">${(Math.round(theme.subtotal * 100) / 100).toFixed(2)}</span>
          </h5>

          <div className="d-flex justify-content-between align-items-center">
            <button aria-label="Continue Shopping." onClick={theme.handleSidebar} className="btn btn--outline">
              continue shopping
            </button>
            <Link to={"/cart"} className={"btn"} onClick={theme.handleSidebar}>
              Review Cart
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
export default Sidebar
