import React from "react"

// Hooks
import { useCountdown } from "./hooks/useCountdown"
import CountdownItem from "./CountdownItem"

const Countdown = ({ endTime, modifierClass = "" }) => {
  const [days, hours, minutes, seconds] = useCountdown(endTime)

  return (
    <div className={`countdown ${modifierClass}`}>
      <CountdownItem value={days} type={"days"} />
      <CountdownItem value={hours} type={"hours"} />
      <CountdownItem value={minutes} type={"minutes"} />
      <CountdownItem value={seconds} type={"seconds"} />
    </div>
  )
}
export default Countdown
