module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Betty Rocker","description":"(#)stopdropandbettyrock anywhere, anytime with our online workout studio, support community and coaching!","lang":"en","display":"standalone","start_url":"/","background_color":"#e54e89","theme_color":"#e54e89","icon":"src/favicon/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"80dc9c9b4d85c335c8dff8f1f513170d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W7JDSWW","defaultDataLayer":{"type":"object","value":{"platform":"gatsby-shopify"}},"enableWebVitalsTracking":false,"includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://c6bd55cc6501451ba7cdff0e4f4217af@o1007263.ingest.sentry.io/5970015","environment":"production","enabled":true,"ignoreErrors":["TypeError: cancelled","Non-Error promise rejection captured with value: Object Not Found Matching Id"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
