/**
 * @param shopifyProduct - current product
 * @param variantId - selected variant Id
 * - If there is a selected variant option, check if that variant is available for sale
 * - If there is no selected variant option, check if any of the variants are available for sale. If there are none, set the product as out of stock
 * @returns true/false - check if the variant is out of stock
 * */

export const checkOutOfStock = (shopifyProduct, variantId = "") => {
  let variantsAvailableForSale

  // Check the variants
  if (variantId) {
    const selectedVariant = shopifyProduct.variants.filter(
      variant => variant.shopifyId === variantId,
    )
    variantsAvailableForSale = selectedVariant.length
      ? !selectedVariant[0].availableForSale
      : false
  } else {
    variantsAvailableForSale = !shopifyProduct.variants.some(
      variant => variant.availableForSale === true,
    )
  }

  // Check if the product tracks inventory and the inventory is less than 0 and check if the variants are available for sale
  return (
    shopifyProduct.tracksInventory &&
    shopifyProduct.totalInventory <= 0 &&
    variantsAvailableForSale
  )
}
