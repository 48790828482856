import React, { useEffect, useState } from "react"

// Components
import AccordionItem from "../../Accordions/AccordionItem"

/**
 * @param id - the id of the product
 * @param position - position top/bottom
 * */
const ProductCarouselInfo = ({ id, position }) => {
  /*============================
  HANDLE INFO VISIBILITY
  ============================*/
  const [positionClass, setPositionClass] = useState("")
  useEffect(() => {
    if (!position.length) return

    setPositionClass(
      position === "top"
        ? "mb--20 d-none d-lg-block"
        : "mt--20 d-block d-lg-none",
    )
  }, [position])

  return (
    <>
      {/*berry green protein & berry green protein Bundle*/}
      {(id === "gid://shopify/Product/2088294514758" ||
        id === "gid://shopify/Product/7004126478528") && (
        <div className={`txt--center ${positionClass}`}>
          <img
            src="/images/supplements/berry-green-protein/circles-bgp.png"
            width="442"
            height="96"
            alt="Info about Berry Green Protein."
            className="no-shadow"
          />
        </div>
      )}
      {/*vanilla protein & vanilla protein Bundle && vanilla protein challenger special */}
      {(id === "gid://shopify/Product/6121460564160" ||
        id === "gid://shopify/Product/7004126970048" ||
        id === "gid://shopify/Product/7199137923264") && (
        <div className={`txt--center ${positionClass}`}>
          <img
            src="/images/supplements/circles-vp.png"
            width="442"
            height="96"
            alt="Info about Vanilla Protein."
            className="no-shadow"
          />
        </div>
      )}
      {/*full body collagen*/}
      {(id === "gid://shopify/Product/4368664854598" ||
        id === "gid://shopify/Product/7004127854784") && (
        <div className={`txt--center ${positionClass}`}>
          <img
            src="/images/supplements/circles-fbc.png"
            width="465"
            height="92"
            alt="Info about Full Body Collagen."
            className="no-shadow"
          />
        </div>
      )}
      {/*serenicalm*/}
      {id === "gid://shopify/Product/4679620034630" && (
        <div className={`${positionClass}`}>
          <div className="row flex-column flex-md-row align-items-center">
            <div className="col-8 col-md-6">
              <h4 className="h4--supplements txt--uppercase img--arrow-abs img--arrow-abs-blue">
                <strong>reducing your stress can...</strong>
              </h4>
            </div>
            <div className="col-10 col-md-6 p--0">
              <ul className="list--sm checkmark--supplements checkmark--supplements-blue">
                <li>Keep you from snacking</li>
                <li>Boost your immune system</li>
                <li>Reduce fat storage</li>
                <li>Improve focus and concentration</li>
                <li>Help you make better decisions</li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {/*whole sleep*/}
      {id === "gid://shopify/Product/4423895580742" && (
        <div className={`${positionClass}`}>
          <div className="row flex-column flex-md-row align-items-center">
            <div className="col-8 col-md-6">
              <h4 className="h4--supplements txt--uppercase img--arrow-abs">
                <strong>A quality night's rest will...</strong>
              </h4>
            </div>
            <div className="col-10 col-md-6 p--0">
              <ul className="list--sm p--0 checkmark--supplements checkmark--supplements-purple">
                <li>Improve memory, alertness and focus</li>
                <li>More efficiently break down fat</li>
                <li>More efficiently add lean muscle</li>
                <li>Balance and improve mood</li>
                <li>Heal and restore the body each day</li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {/*chocolate protein*/}
      {id === "gid://shopify/Product/7330569388224" && (
        <div className={`txt--center ${positionClass}`}>
          <img
            src="/images/supplements/cicrles-cp.png"
            width="442"
            height="96"
            alt="Info about Chooclate Protein."
            className="no-shadow"
          />
        </div>
      )}
      {/*rock and restore and rock and restore Bundle*/}
      {(id === "gid://shopify/ProductVariant/40147557646528" ||
        id === "gid://shopify/Product/7004127494336") && (
        <div className={`txt--center ${positionClass}`}>
          <img
            src="/images/supplements/circles-rr.png"
            width="419"
            height="83"
            alt="Info about Rock and Restore."
            className="no-shadow"
          />
        </div>
      )}

      {/*workout bundle*/}
      {id === "gid://shopify/Product/7345480204480" && (
        <div className={`mb--20 ${positionClass}`}>
          <AccordionItem
            title={`I <i class="fas fa-heart txt--pink"></i> Protein (Vanilla or Chocolate):`}
            counter={false}
            icon="angle"
            modifierClasses="accordion--pink accordion-product"
            open={false}
          >
            <p className="p--supplements">
              Use pre or post workout in a smoothie, shake or added to a food to
              boost your protein intake and provide your body with the building
              blocks it needs for muscle sculpting and repair.
            </p>
          </AccordionItem>

          <AccordionItem
            title="Rock and Restore"
            counter={false}
            icon="angle"
            modifierClasses="accordion--pink accordion-product"
            open={false}
          >
            <p className="p--supplements">
              Use during a workout to support muscle protein synthesis, reduce
              post-workout soreness, and speed recovery. Contains all the
              essential aminos (including the BCAA’s) with an optimal dose of
              leucine, the most anabolic (muscle supporting) BCAA.
            </p>
          </AccordionItem>

          <AccordionItem
            title="Full Body Collagen"
            counter={false}
            icon="angle"
            modifierClasses="accordion--pink accordion-product"
            open={false}
          >
            <p className="p--supplements">
              Harness the protective benefits of collagen peptides for your
              skin, bone and joint health. I like to mix this into my Rock and
              Restore during a workout as it’s flavorless, dissolves instantly
              and is easy to consume at the same time.
            </p>
          </AccordionItem>
        </div>
      )}
    </>
  )
}
export default ProductCarouselInfo
