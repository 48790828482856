import React from "react"

const Quantity = ({
  quantity,
  handleQuantity,
  modifierClass = "",
  size = "",
  noLabel = false,
  isDisabled = false,
}) => {
  // Decrease quantity and update in cart
  const stepDown = () => {
    let newCount = +quantity - 1
    if (quantity - 1 >= 0) {
      handleQuantity(newCount)
    }
  }

  // Increase quantity and update in cart
  const stepUp = () => {
    let newCount = +quantity + 1
    handleQuantity(newCount)
  }

  return (
    <div
      className={`d-flex align-items-start align-items-sm-center flex-column flex-sm-row mb--20 ${modifierClass} ${
        !isDisabled ? "disabled" : ""
      }`}
    >
      {!noLabel && (
        <label
          htmlFor="count"
          className={
            modifierClass.length ? `${modifierClass}__label` : "mr--15"
          }
        >
          <strong>Quantity</strong>
        </label>
      )}
      <div className={modifierClass.length ? `${modifierClass}__content` : ""}>
        <div
          className={`input-field input-field--number ${
            size.length > 0 ? `input-field--number-${size}` : ""
          } m--0`}
        >
          <button
            aria-label="Decrease count."
            className="step step--down"
            onClick={stepDown}
          >
            <i className="fas fa-minus" />
          </button>
          <input
            type="number"
            value={quantity}
            min="1"
            id="count"
            onChange={e => handleQuantity(+e.target.value)}
          />
          <button
            aria-label="Increase count."
            className="step step--up"
            onClick={stepUp}
          >
            <i className="fas fa-plus" />
          </button>
        </div>
      </div>
    </div>
  )
}
export default Quantity
