import React, { Component } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

export class Slider extends Component {
  render() {
    return (
      <Carousel
        axis={this.props.axis ? this.props.axis : "horizontal"}
        className={this.props.modifierClass ? this.props.modifierClass : ""}
        showIndicators={this.props.showIndicators}
        showArrows={this.props.showArrows}
        showStatus={false}
        infiniteLoop={this.props.autoPlay}
        selectedItem={this.props.selectedItem ? this.props.selectedItem : 0}
        autoPlay={this.props.autoPlay}
        showThumbs={this.props.showThumbs}
        swipeable={!this.props.notSwipeable}
        preventMovementUntilSwipeScrollTolerance={true}
        interval={7000}
        thumbWidth={this.props.thumbWidth ? this.props.thumbWidth : 90}
        thumbHeight={this.props.thumbHeight ? this.props.thumbHeight : 70}
        onChange={(index, item) =>
          this.props.onChange ? this.props.onChange(index, item) : {}
        }
        onClickThumb={(index, item) =>
          this.props.onClickThumb ? this.props.onClickThumb(index, item) : {}
        }
      >
        {this.props.children}
      </Carousel>
    )
  }
}
export default Slider
