import React from "react"

/**
 * @param theme - theme
 * */
const DiscountTxt = ({ theme }) => {
  return (
    <p className="discount-box__title">
      Purchase any supplements and enjoy <strong>25% OFF YOUR ENTIRE ORDER</strong>{" "}
      <span className="d-inline-block">through {theme.discountThroughText}!</span>
    </p>
  )
}
export default DiscountTxt
