import React from "react"

// Components
import Country from "./Country"

const Preheader = ({ theme }) => {
  return (
    <div className="preheader">
      <div className="container d-flex justify-content-center justify-content-lg-between align-items-center">
        <div className="preheader__nav">
          <a
            href="https://thebettyrocker.com/blog/"
            className="preheader__nav__item"
          >
            Blog
          </a>
          <a
            href="https://thebettyrocker.com/faq/"
            className="preheader__nav__item"
          >
            FAQ
          </a>
          <a
            href="https://thebettyrocker.com/contact/"
            className="preheader__nav__item"
          >
            Contact
          </a>
        </div>
        <nav className="preheader__nav d-none d-lg-flex">
          <Country theme={theme} />
        </nav>
      </div>
    </div>
  )
}
export default Preheader
