import React from "react"

// Utilities
import { formatProductTitle } from "../../../utilities/formatProductInfo"

/**
 * @param product - static data for the product
 * @param shopifyProduct - data for the shopify product
 * */
const ProductInformation = ({ product, shopifyProduct }) => {
  // Set title
  const title = formatProductTitle(shopifyProduct.title, shopifyProduct)

  return (
    <>
      {product.quote && (
        <div className="product__quote">
          <p>
            <i>“{product.quote}”</i>
          </p>
          <p>
            <strong className="fw--bold">- {product.quoteAuthor}</strong>
          </p>
        </div>
      )}
      <h2 className="product__title" dangerouslySetInnerHTML={{ __html: title }} />
      <div
        className="product__description"
        dangerouslySetInnerHTML={{
          __html: shopifyProduct.descriptionHtml,
        }}
      />
    </>
  )
}
export default ProductInformation
