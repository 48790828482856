import React, { useEffect, useState } from "react"

/**
 * @param box - whether it should be a form or not
 * @param type - the type of the product
 * @param shopifyProduct - the shopify product data
 * */
const OutOfStock = ({ box, type, shopifyProduct }) => {
  /*========================================
    PRODUCT SKU
  ========================================*/
  const [SKU, setSKU] = useState("")

  useEffect(() => {
    if (!shopifyProduct) return
    setSKU(shopifyProduct.variants[0].sku)
  }, [shopifyProduct])

  /*========================================
    HANDLE FORM
  ========================================*/
  const [formData, setFormData] = useState({
    inf_custom_StoreOutofStockItemNotificationList: "",
    inf_field_FirstName: "",
    inf_field_Email: "",
  })

  const handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    setFormData({ ...formData, [name]: value })
  }

  const [errors, setErrors] = useState({
    inf_field_Email: "",
    inf_field_FirstName: "",
  })

  const handleSubmit = event => {
    handleValidation()

    const newErrors = { ...errors }
    // Validate email
    if (!formData.inf_field_Email) {
      newErrors.inf_field_Email = "Please enter your email."
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.inf_field_Email)
    ) {
      newErrors.inf_field_Email = "Please enter valid email."
    } else {
      newErrors.inf_field_Email = ""
    }

    // Validate first name
    if (!formData.inf_field_FirstName) {
      newErrors.inf_field_FirstName = "Please enter your name."
    } else {
      newErrors.inf_field_FirstName = ""
    }

    if (
      !(
        newErrors.inf_field_Email.length === 0 &&
        newErrors.inf_field_FirstName.length === 0
      )
    ) {
      event.preventDefault()
    }
  }

  /*========================================
    HANDLE FORM VALIDATION
  ========================================*/
  const handleValidation = () => {
    const newErrors = { ...errors }

    // Validate email
    if (!formData.inf_field_Email) {
      newErrors.inf_field_Email = "Please enter your email."
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.inf_field_Email)
    ) {
      newErrors.inf_field_Email = "Please enter valid email."
    } else {
      newErrors.inf_field_Email = ""
    }

    // Validate first name
    if (!formData.inf_field_FirstName) {
      newErrors.inf_field_FirstName = "Please enter your name."
    } else {
      newErrors.inf_field_FirstName = ""
    }
    setErrors(newErrors)
  }

  return (
    <>
      {box ? (
        <>
          {type === "supplements" || type === "bundles" ? (
            <div className="product__type__box active product__type__box--outofstock">
              <div className="flex-column align-items-start">
                <div className="d-flex align-items-center mb--10">
                  <img
                    src="/images/box-heart-solid.png"
                    alt="Heart inside a box decoration."
                    className="mr--10 no-shadow"
                    width={20}
                  />
                  <h5>
                    <strong>Temporarily Out Of Stock</strong>
                  </h5>
                </div>
                <p className="txt--16 mb--10">
                  We’re working on getting this back in stock as soon as
                  possible! Enter your name and email and we'll let you know as
                  soon as we have it available.
                </p>

                <form
                  acceptCharset="UTF-8"
                  action="https://xq174.infusionsoft.com/app/form/process/b1d259d3e71fdac25bf202c6feea5558"
                  className="infusion-form"
                  id="inf_form_b1d259d3e71fdac25bf202c6feea5558"
                  method="POST"
                  onSubmit={event => handleSubmit(event)}
                >
                  <input
                    name="inf_form_xid"
                    type="hidden"
                    value="b1d259d3e71fdac25bf202c6feea5558"
                  />
                  <input
                    name="inf_form_name"
                    type="hidden"
                    value="Out of Stock"
                  />
                  <input
                    name="infusionsoft_version"
                    type="hidden"
                    value="1.70.0.442855"
                  />
                  <input
                    name="inf_custom_StoreOutofStockItemNotificationList"
                    type="hidden"
                    value={SKU}
                  />

                  <div className="row gutters-7_5">
                    <div className="col-12 col-md-6 mb--15">
                      <input
                        id="inf_field_FirstName"
                        name="inf_field_FirstName"
                        placeholder="First Name "
                        type="text"
                        value={formData.inf_field_FirstName}
                        className={`input-icon input-icon--name ${
                          errors.inf_field_FirstName.length > 0
                            ? "input-error"
                            : ""
                        }`}
                        onChange={handleInputChange}
                        onBlur={handleValidation}
                      />
                      {errors.inf_field_FirstName.length > 0 && (
                        <span className="input-error-text">
                          {errors.inf_field_FirstName}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-md-6 mb--15">
                      <input
                        id="inf_field_Email"
                        name="inf_field_Email"
                        placeholder="Email "
                        type="text"
                        value={formData.inf_field_Email}
                        className={`input-icon input-icon--email ${
                          errors.inf_field_Email.length > 0 ? "input-error" : ""
                        }`}
                        onChange={handleInputChange}
                        onBlur={handleValidation}
                      />
                      {errors.inf_field_Email.length > 0 && (
                        <span className="input-error-text">
                          {errors.inf_field_Email}
                        </span>
                      )}
                    </div>
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn--full btn--md mb--5"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className="product__type__box active">
              <div className="flex-column align-items-start">
                <div className="d-flex align-items-center mb--10">
                  <img
                    src="/images/box-heart-solid.png"
                    alt="Heart inside a box decoration."
                    className="mr--10 no-shadow"
                    width={20}
                  />
                  <h5>
                    <strong>Out Of Stock</strong>
                  </h5>
                </div>
                <p className="txt--16">
                  We'll be back in stock soon! Stay tuned.
                </p>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="out-of-stock">
          <div className="d-flex align-items-center justify-content-center">
            <img
              src="/images/box-heart-solid.png"
              alt="Heart inside a box decoration."
              className="mr--10 no-shadow"
              width={20}
            />
            <h5>
              <strong>Out Of Stock</strong>
            </h5>
          </div>
        </div>
      )}
    </>
  )
}
export default OutOfStock
