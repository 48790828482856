import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { formatShopifyId } from "../utilities/formatString"
import { isObjectEmpty } from "../utilities/checkValidity"
import { checkURLParams } from "../utilities/checkURLParams"
import { UseShopifyDataQuery } from "../hooks/shopifyData"
import { convertVariantIdToLegacyResourceId, convertVariantIdToStorefrontId } from "../utilities/convertIdtoLegacyId"
import { compareDates, useCountdown } from "../components/Countdown/hooks/useCountdown"

const isBrowser = typeof window !== "undefined"

const defaultState = {
  addVariantToCart: (
    variantId,
    productId,
    quantity,
    price,
    sellingPlan,
    title,
    type,
    options,
    currentCartItem,
    isCart,
  ) => {},
  updateItemInCart: (
    variantId,
    productId,
    quantity,
    selling_plan,
    price,
    isSidebar,
    currentCartItem,
    options,
    isCart,
    error,
  ) => {},
  updateQuantity: (cartItem, quantity) => {},
  removeLineItemInCart: cartItem => {},
  sidebarOpen: false,
  handleSidebar: () => {},
  checkoutUrl: "",
  total: 0,
  subtotal: 0,
  cart: [],
  country: null,
  shipping: true,
  updateCountry: newCountry => {},
  changeModalCountry: false,
  handleChangeModalCountry: isPopup => {},
  pageLoader: true,
  discount: 0,
  error: { status: "", message: "" },
  shopifyData: [],
  giftId: "",
  showGiftBox: false,
  handleGiftBox: () => {},
  isDiscountActive: false,
  discountCode: "",
  discountThroughText: "",
  startDateTimeBasedDiscount: "",
  endDateTimeBasedDiscount: "",
}
const ThemeContext = React.createContext(defaultState)

const ThemeProvider = ({ children }) => {
  const shopifyData = UseShopifyDataQuery()

  const [cart, setCart] = useState([])
  const [checkoutUrl, setCheckoutUrl] = useState("")

  const [total, setTotal] = useState(0)
  const [subtotal, setSubtotal] = useState(0)

  const [country, setCountry] = useState(null)
  const [shipping, setShipping] = useState(true)
  const [changeModalCountry, setChangeModalCountry] = useState(false)
  const [pageLoader, setPageLoader] = useState(true)

  // Gift id - gift box on cart
  const giftId = "gid://shopify/Product/7374601781440" // All or Something Crop Top
  const [showGiftBox, setShowGiftBox] = useState(false)

  // NEW OFFERS - individual pages -
  // NOTE: UPDATE CODE FOR NEW OFFER
  const offerId = [
    "gid://shopify/Product/7729718329536", // Home Workout Domination Protein Bundle
    "gid://shopify/Product/7754890674368", // Body Fuel System Protein Bundle
    "gid://shopify/Product/7874233270464", // Booty and Abs Challenge Protein Bundle
    "gid://shopify/Product/7749101551808", // 30 Day Meal Plan Protein Bundle
    "gid://shopify/Product/7891449905344", // My Dinner Plan Protein Bundle
    "gid://shopify/Product/7751589953728", // 90 Day Challenge Protein Bundle
    "gid://shopify/Product/7751590510784", // Lioness Protein Bundle
  ]

  // UPDATE ARRAY WITH PRODUCT ID TO REMOVE ITEM FROM LOCAL STORAGE
  const hiddenProductsFromLS = [
    "gid://shopify/Product/7492219207872", // Muscle Tank
    "gid://shopify/Product/7374623080640", // Rock Your Life Muscle Tank
    "gid://shopify/Product/7374635368640", // Stop, Drop and Betty Rock Muscle Tank
    "gid://shopify/Product/7375209332928", // All or Something Muscle Tank
    "gid://shopify/Product/7374596407488", // Stop Drop and Betty Rock Crop Top
    "gid://shopify/Product/7374599618752", // Rock Your Life Crop Top
    "gid://shopify/Product/7374582513856", // Rockstar Crop Top
  ]

  /** -----------------------------------------
   DISCOUNT
    - Discount with discount code
    - Discount that is time based
    - Discount with discounted price
   ------------------------------------------- */

  // DISCOUNT REMOVE DATE
  const removeDiscountDate = "08/15/2024 23:59:59" // MM/DD/YYYY HH:mm:ss - Add the time when the discount components should hide

  const [isDiscountActive, setIsDiscountActive] = useState(false)
  const [discount, setDiscount] = useState(0)

  // DISCOUNT CODE VALUE
  const discountCode = "SUMMER25"
  // DISCOUNT TEXT VALUE
  const discountThroughText = "THU 8/15"
  // FOR PRICE DISCOUNT (product id)
  const discountProductPriceId = ""

  // TIME BASED DISCOUNT
  const startDateTimeBasedDiscount = "08/13/2024 00:35:00" // MM/DD/YYYY HH:mm:ss - Add the start date of the discount
  const endDateTimeBasedDiscount = "08/15/2024 23:59:59" // MM/DD/YYYY HH:mm:ss - Add the end date of the countdown

  // Time left until the removeDiscountDate is reached
  const discountTimeLeft = useCountdown(removeDiscountDate)

  // Activate/Remove discount based on entered time
  useEffect(() => {
    // If development compare with current start date, if it's in the future, show the discount
    if (process.env.GATSBY_NODE_ENV === "development" && !discountTimeLeft.every(item => item === 0)) {
      setIsDiscountActive(true)
      return
    }

    if (discountTimeLeft.every(item => item === 0)) {
      setIsDiscountActive(false)
    } else {
      setIsDiscountActive(compareDates(new Date(), startDateTimeBasedDiscount, removeDiscountDate))
    }
  }, [discountTimeLeft])

  /** -----------------------------------------
   CART
    - Set up cart on page load
   ------------------------------------------- */
  // Get the cartItems from localStorage on load, if they exist
  useEffect(() => {
    const parameters = checkURLParams()
    let cartLS = JSON.parse(localStorage.getItem("cartItems"))

    if (parameters.hasOwnProperty("cart")) {
      if (parameters["cart"] === "clear") {
        localStorage.removeItem("cartItems")
        setCart([])
      }
    }

    if (parameters.hasOwnProperty("status")) {
      checkCartItemsQuantityStock(parameters["status"], cartLS)
    }

    // Update country
    // Check local Storage until the value is set from gatsby-browser
    const checkLS = setInterval(() => {
      if (window.localStorage.getItem("country")) {
        setCountry(window.localStorage.getItem("country") || "US")
        setShipping(
          window.localStorage.getItem("shipping") !== null && window.localStorage.getItem("shipping") !== undefined
            ? window.localStorage.getItem("shipping") === "true"
            : true,
        )
        clearInterval(checkLS)
      }
    }, 100)

    // Sync local storage across multiple tabs
    window.addEventListener("storage", function (event) {
      if (event.key === "cartItems") {
        cartLS = event.storageArea.cartItems
        setCart(cartLS ? JSON.parse(cartLS) : [])
      }

      if (event.key === "country") {
        updateCountry(event.newValue, true)
      }
    })

    let cartLSFinal = cartLS ? cartLS : []

    if (cartLS) {
      cartLS.forEach((cartItem, index) => {
        cartItem.id = index

        const shopifyProduct = shopifyData.filter(item => item.shopifyId === cartItem.productId)
        if (shopifyProduct.length) {
          // Product has been removed and it should be hidden from cart
          const isProductHidden = hiddenProductsFromLS.includes(cartItem.productId)

          if (!isProductHidden) {
            shopifyProduct[0].variants.forEach(item => {
              // get variant id
              const variantId = formatShopifyId(item.shopifyId)

              if (variantId === cartItem.variantId) {
                if (!item.availableForSale) {
                  let newCart = [...cartLS]
                  newCart.forEach(item => {
                    if (item.variantId === variantId && item.selling_plan === cartItem.selling_plan) {
                      cartLSFinal = removeLSItem(newCart, cartItem)
                    }
                  })
                  localStorage.setItem("cartItems", JSON.stringify(newCart))
                }
              }
            })
          } else {
            // If the product is in shopify but has been hidden on the store, remove it from local storage
            let newCart = [...cartLSFinal]
            cartLSFinal = removeLSItem(newCart, cartItem)
          }
        } else {
          // If there is a product in the cart that has been removed from shopify, remove it from local storage
          let newCart = [...cartLSFinal]
          cartLSFinal = removeLSItem(newCart, cartItem)
        }
      })
    }

    // If the gift hasn't been declined, show the gift box on cart
    let declinedGift = getCookie("declined-gift")
    setShowGiftBox(!declinedGift && giftId.length)

    // Set Cart
    setCart(cartLSFinal)
  }, [])

  /** -----------------------------------------
   SHOPIFY URL
   - Set shopify api url
   -  Calculate Total and Subtotal in Order Summary
   ------------------------------------------- */

  // Set url
  useEffect(() => {
    if (!cart || !cart.length) return

    const storeUrl = "https://checkout.thebettyrocker.com/cart/"
    let apiUrl = `${storeUrl}?`
    let id

    cart.forEach((item, index) => {
      // Find the product and replace the id with the StorefrontId until shopify makes changes in POST add.js and recognizes the nww id
      id = convertVariantIdToLegacyResourceId(shopifyData, item.variantId)

      apiUrl += `${index > 0 ? "&" : ""}id${index}=${id}&quantity${index}=${item.quantity}&selling_plan${index}=${
        item.selling_plan
      }`
    })
    apiUrl += `&length=${cart.length}`

    if (isDiscountActive) {
      let isOneTimeSupplementPurchased = false

      // DISCOUNT APPLIED IF SUPPLEMENT OR BUNDLE IS PURCHASED
      // Check if there is supplement or bundle purchased
      const discountProductTypeSelected = cart.filter(
        cartItem => cartItem.type === "supplements" || cartItem.type === "bundles",
      )

      // DISCOUNT APPLIED IF A SPECIFIC SUPPLEMENT OR BUNDLE IS PURCHASED
      // const discountProductTypeSelected = cart.filter(
      //   cartItem =>
      //    (cartItem.type === "supplements" && cartItem.productId === "") ||
      //    (cartItem.type === "bundles" & cartItem.productId === "")
      // )

      if (discountProductTypeSelected.length) {
        // Check if there is a one time supplement purchased
        isOneTimeSupplementPurchased = discountProductTypeSelected.some(
          product => product.selling_plan.toString().toLowerCase() === "no",
        )

        // Update url with discount code if it's active
        if (isOneTimeSupplementPurchased && shipping && discountCode.length && isDiscountActive) {
          apiUrl += `&discount=${discountCode};`
        }
      }

      // Check if the discount price product is selected
      const isDiscountItemSelected = cart.filter(item => item.productId === discountProductPriceId)

      // If there is a supplement and a discount item selected, apply the discount
      let discountValue =
        discountProductTypeSelected.length && isDiscountItemSelected.length ? isDiscountItemSelected[0].price : 0
      setDiscount(discountValue)
    }

    setCheckoutUrl(apiUrl)
  }, [cart, isDiscountActive])

  // Calculate Total and Subtotal in Order Summary
  useEffect(() => {
    let total = 0
    cart.forEach(item => {
      if (item && item.productId !== giftId) {
        total += item.quantity * Number(item.price)
      }
    })

    setSubtotal(total)
    setTotal(total - discount)
  }, [cart, discount])

  /** -----------------------------------------
   Shopify Actions
    - addVariantToCart - add product to cart or update it
    - cart useEffect - update or reset cart when change occurs
    - setNewItemInCart - add new item in cart
    - updateQuantity - update product quantity
    - updateItemInCart - updated cart item
    - removeLineItemInCart - remove cart item
    - removeLSItem - remove cart item from local storage
   ------------------------------------------- */

  // Add product to cart or update it
  const addVariantToCart = (
    variantId,
    productId,
    quantity,
    price,
    sellingPlan,
    title,
    type,
    options = {},
    currentCartItem = {},
    isCart,
  ) => {
    variantId = formatShopifyId(variantId)

    const productNewValues = {
      variantId: variantId,
      productId: productId,
      quantity: quantity,
      price: price,
      selling_plan: sellingPlan,
      title: title,
      type: type,
      options: options,
    }

    // If the selected product is the gift id, limit to one variation per product
    if (productId === giftId) {
      // If there is a gift
      let giftInCart = cart.filter(product => product.productId === giftId)
      if (giftInCart.length) {
        updateItemInCart(giftInCart[0], productNewValues, isCart)
      } else {
        setNewItemInCart(variantId, productId, quantity, price, sellingPlan, title, type, options, isCart)
      }
      return
    }

    // If the selected product is the individual offer, limit to one variation per product
    if (offerId.includes(productId)) {
      // If there is a gift
      let offerInCart = cart.filter(product => offerId.includes(product.productId) && productId === product.productId)
      if (offerInCart.length && offerInCart[0].productId === productId) {
        updateItemInCart(offerInCart[0], productNewValues, isCart)
      } else {
        setNewItemInCart(variantId, productId, quantity, price, sellingPlan, title, type, options, isCart)
      }
      return
    }

    // if the request comes from the cart items on cart page, update the currentCartItem with the new values
    if (!isObjectEmpty(currentCartItem)) {
      updateItemInCart(currentCartItem, productNewValues, isCart)
      return
    }

    // Check if the product id is in the cart
    const productIdInCart = cart.filter(product => product.productId === productId)

    // If the productId is not in the cart, add the new product
    if (!productIdInCart.length) {
      setNewItemInCart(variantId, productId, quantity, price, sellingPlan, title, type, options, isCart)
      return
    }

    // If the productId is in the cart
    if (productIdInCart.length) {
      // If the variantId exists in the product objects, update that product with the new values
      const variantIdInProductItems = productIdInCart.filter(product => product.variantId === variantId)
      if (variantIdInProductItems.length) {
        let cartItem

        // If it's supplement or bundle and has the same selling plan update it, else add new product
        if (type === "supplements" || type === "bundles") {
          const productSameSellingPlan = variantIdInProductItems.filter(variant => variant.selling_plan === sellingPlan)

          if (productSameSellingPlan.length) {
            cartItem = productSameSellingPlan[0]
            updateItemInCart(cartItem, productNewValues, isCart)
          } else {
            setNewItemInCart(variantId, productId, quantity, price, sellingPlan, title, type, options, isCart)
          }
        } else {
          cartItem = variantIdInProductItems[0]
          updateItemInCart(cartItem, productNewValues, isCart)
        }
      } else {
        setNewItemInCart(variantId, productId, quantity, price, sellingPlan, title, type, options, isCart)
      }
    }

    if (!isCart) setSidebarOpen(true)

    // push custom event to GTM
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({ ecommerce: null })

      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: "USD",
          value: 0.0,
          items: [
            {
              item_id: convertVariantIdToStorefrontId(shopifyData, variantId),
              item_name: title,
              currency: "USD",
              item_brand:
                type === "supplements" || type === "bundles"
                  ? "Whole Betty"
                  : type === "apparel" || type === "merchandise"
                    ? "Betty Rocker"
                    : "The Betty Rocker",
              item_category: type,
              price: +price,
              quantity: quantity,
            },
          ],
        },
      })
    }
  }

  // Update or reset cart when change occurs
  useEffect(() => {
    if (!cart.length) {
      // reset gift box if cart is empty
      if (giftId.length) setShowGiftBox(false)
      return
    }

    // Reset cart if there are items with old IDs (containing shopify)
    if (
      cart[0].variantId.indexOf("shopify") === -1 ||
      cart[0].productId.indexOf("shopify") === -1 ||
      !cart[0].priceUpdate
    ) {
      setCart([])
      localStorage.setItem("cartItems", JSON.stringify([]))
      setPageLoader(true)
      window.location.reload()
    } else {
      localStorage.setItem("cartItems", JSON.stringify(cart))
    }

    // Updated gift box on cart changes
    if (!giftId.length) return
    const giftInCart = cart.filter(item => item.productId === giftId)
    const giftConditionProductSelected = cart.filter(item => item.type === "supplements" || item.type === "bundles")

    // Remove gift if no supplement or bundle is selected
    if (giftConditionProductSelected.length === 0 && giftInCart.length > 0) {
      let filteredCart = [...cart]
      filteredCart = removeLSItem(filteredCart, giftInCart[0])
      setCart(filteredCart)
    }

    // Show gift box if:
    // - If the product is not in cart
    // - If there is a supplement or bundle selected in cart
    let declinedGift = getCookie("declined-gift")
    if (!declinedGift && giftId.length) {
      setShowGiftBox(giftInCart.length <= 0 && giftConditionProductSelected.length > 0)
    }
  }, [cart])

  // Add new item in cart
  const setNewItemInCart = (variantId, productId, quantity, price, sellingPlan, title, type, options = {}, isCart) => {
    const newCart = [
      ...cart,
      {
        variantId: variantId,
        productId: productId,
        quantity: Number(quantity),
        price: Number(price),
        selling_plan: sellingPlan ? sellingPlan : "No",
        title: title,
        type: type,
        options: options,
        priceUpdate: true,
      },
    ]

    // Set unique Id for all the products in the cart
    newCart.forEach((product, index) => {
      product.id = index
    })

    setCart(newCart)

    if (!isCart) {
      setSidebarOpen(true)
    } else {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      })
    }
  }

  // Update product quantity
  const updateQuantity = (cartItem, quantity) => {
    const newCart = Array.from(cart)
    const itemInCart = newCart.filter(product => product.id === cartItem.id)

    if (itemInCart.length) {
      itemInCart[0].quantity = quantity
    }

    // If quantity is removed, remove the cart item
    if (quantity === 0) {
      removeLineItemInCart(cartItem)
    } else {
      setCart(newCart)
    }
  }

  // Updated cart item
  const updateItemInCart = (cartItem, productNewValues, isCart) => {
    // Find the product to be updated
    const newCart = [...cart]

    let productToBeUpdated
    // If the product is type supplement or bundles, select the one with same productId, variantId and sellingPlan
    // else select the one with same productId
    if (cartItem.type === "supplements" || cartItem.type === "bundles") {
      productToBeUpdated = newCart.filter(
        product => product.id === cartItem.id && product.selling_plan === cartItem.selling_plan,
      )[0]
    } else {
      productToBeUpdated = newCart.filter(product => product.id === cartItem.id)[0]
    }

    // There is a restriction for products of the type of meal plan and workout plan and Home Workout Domination Protein Bundle. The quantity is limited to 1,
    // so it cannot increase if the product is updated
    const quantity =
      !isCart &&
      cartItem.type !== "meal plan" &&
      cartItem.type !== "workout plan" &&
      !offerId.includes(productNewValues.productId) &&
      productNewValues.productId !== giftId
        ? productNewValues.quantity + productToBeUpdated.quantity
        : productNewValues.quantity

    newCart.forEach(product => {
      if (product.id === productToBeUpdated.id) {
        product.id = cartItem.id
        product.variantId = productNewValues.variantId
        product.productId = productNewValues.productId
        product.quantity = quantity
        product.price = productNewValues.price
        product.selling_plan = productNewValues.selling_plan
        product.title = productNewValues.title
        product.type = productNewValues.type
        product.options = productNewValues.options
      }
    })

    if (productNewValues.quantity === 0) {
      removeLineItemInCart(productToBeUpdated)
    } else {
      // if there are multiple same products, combine them
      // - if they are supplements or bundles combine them only if they have same selling plan
      let sameProductsInCart
      if (productToBeUpdated.type === "supplements" || productToBeUpdated.type === "bundles") {
        sameProductsInCart = newCart.filter(
          product =>
            product.productId === productNewValues.productId &&
            product.variantId === productNewValues.variantId &&
            product.selling_plan === productNewValues.selling_plan,
        )
      } else {
        sameProductsInCart = newCart.filter(
          product =>
            product.productId === productNewValues.productId && product.variantId === productNewValues.variantId,
        )
      }

      if (sameProductsInCart.length > 1) {
        // Calculate the combined quantity
        let quantity = 0
        // Extract the cart item id's which are basically the order of the cart items
        let productIds = []

        sameProductsInCart.forEach((product, index) => {
          // Prevent quantity increase when merging products
          quantity += product.quantity
          productIds = [...productIds, product.id]

          // If it's not the first item, remove the repeating cart item
          if (index !== 0) {
            newCart.splice(product.id, 1)
          }
        })

        // Update the remaining item with the combined quantity
        newCart[productIds[0]].quantity = quantity
      }

      // Reset the ids
      newCart.forEach((product, index) => (product.id = index))

      // Update the cart with the new state
      setCart(newCart)
    }

    if (!isCart) {
      setSidebarOpen(true)
    }
  }

  // Remove product from cart
  const removeLineItemInCart = cartItem => {
    // data for custom event to GTM
    const cartItemId = cartItem.id
    const variantId = cartItem.variantId
    const type = cartItem.type
    const price = cartItem.price
    const quantity = cartItem.quantity
    const title = cartItem.title

    let newCart = Array.from(cart)
    newCart.splice(cartItemId, 1)
    // Update the cart item's id to start from 0
    newCart.forEach((item, index) => {
      item.id = index
    })
    setCart(newCart)
    localStorage.setItem("cartItems", JSON.stringify(newCart))

    // Push custom event to GTM
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
          currency: "USD",
          value: 0.0,
          items: [
            {
              item_id: convertVariantIdToStorefrontId(variantId),
              item_name: title,
              currency: "USD",
              item_brand:
                type === "supplements" || type === "bundles"
                  ? "Whole Betty"
                  : type === "apparel" || type === "merchandise"
                    ? "Betty Rocker"
                    : "The Betty Rocker",
              item_category: type,
              price: +price,
              quantity: quantity,
            },
          ],
        },
      })
    }
  }

  // Remove item from localStorage and update the ids of the rest of the items
  const removeLSItem = (cart, cartItem) => {
    const indexOfItemToBeRemoved = cart.indexOf(cartItem)

    cart.splice(indexOfItemToBeRemoved, 1)
    cart.forEach((cartItem, index) => {
      cartItem.id = index
    })

    return cart
  }

  /** -----------------------------------------
   SHOPIFY PRODUCT ERROR
    - checkCartItemsQuantityStock - Check if the quantity of the cartItems exceeds their stock an update error message
    - findExceedingStockItems - Check if selected products are out of stock
   ------------------------------------------- */

  // Is there shopify error
  const [error, setError] = useState({ status: "", message: "" })

  // Check if the quantity of the cartItems exceeds their stock an update error message
  const checkCartItemsQuantityStock = (shopifyError, cartItems) => {
    let message

    if (shopifyError === "exceeds-stock") {
      const products = findExceedingStockItems(cartItems)

      message = "<ul class='list list--disc'>"

      if (products.length) {
        products.forEach(product => {
          message += `<li>You've requested more of ${product.name} than the available ${product.stock} from this product.</li>`
        })
        message += "</ul>"
      } else {
        message = "We were not able to process your order at this time. Please try again later."
      }
    } else {
      message = "We were not able to process your order at this time. Please try again later."
    }

    setError({ status: shopifyError, message: message })
  }

  // Check if selected products are out of stock
  const findExceedingStockItems = cartItems => {
    let productsExceedingStock = []

    cartItems.forEach(cartItem => {
      const shopifyProduct = shopifyData.filter(product => product.shopifyId === cartItem.productId)[0]

      if (shopifyProduct.tracksInventory) {
        const shopifyVariant = shopifyProduct.variants.filter(variant => variant.shopifyId === cartItem.variantId)[0]

        if (shopifyVariant.inventoryQuantity < cartItem.quantity) {
          productsExceedingStock = [
            ...productsExceedingStock,
            {
              name: shopifyProduct.title,
              stock: shopifyVariant.inventoryQuantity,
            },
          ]
        }
      }
    })

    return productsExceedingStock
  }

  /** -----------------------------------------
   COOKIES
    - getCookie - get cookie value
    - handleGiftBox - set cookie value if gift has been declined and hide gift box
   ------------------------------------------- */

  // Get cookie value
  function getCookie(name) {
    let value = `; ${document.cookie}`
    let parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(";").shift()
  }

  // Set cookie value if gift has been declined and hide gift box
  const handleGiftBox = () => {
    document.cookie = `declined-gift=true; max-age=${24 * 60 * 60}`
    setShowGiftBox(false)
  }

  /** -----------------------------------------
   SHIPPING
    - updateCountry - Change country USA/UK/CAN - Worldwide
    - handleChangeModalCountry - open/close change country modal
    - country useEffect - if worldwide is selected, check if there are shipping products selected, redirect to homepage
    - removeShippingProductFromCart - if worldwide is selected, check if there are shipping products selected, remove them from Cart
   ------------------------------------------- */

  // Change country USA/UK/CAN - Worldwide
  const updateCountry = (newCountry, isLocalStorage = false) => {
    setCountry(newCountry)

    if (country !== newCountry || isLocalStorage) {
      window.localStorage.setItem("country", newCountry)
      window.localStorage.setItem("shipping", newCountry !== "worldwide")
      setShipping(newCountry !== "worldwide")
    }

    setChangeModalCountry(false)
  }

  // Open/close change country modal
  const handleChangeModalCountry = isPopup => setChangeModalCountry(isPopup)

  // If worldwide is selected, check if there are shipping products selected, redirect to homepage
  useEffect(() => {
    if (!country) return
    setPageLoader(false)

    const notShippingPages = [
      "/",
      "/category/workout-plans/",
      "/category/meal-plans/",
      "/category/programs/",
      "/products/90-day-challenge/",
      "/products/home-workout-domination/",
      "/products/home-workout-domination-2/",
      "/products/30-day-booty-abs-challenge/",
      "/products/lioness/",
      "/products/30-day-meal-plan/",
      "/products/7-day-meal-plan/",
      "/cart/",
    ]

    if (country === "worldwide") {
      // If not on homepage or workout/meal plan, redirect to homepage
      if (!notShippingPages.includes(window.location.pathname)) {
        navigate("/")
      }
      removeShippingProductFromCart()
    }
  }, [country])

  // If worldwide is selected, check if there are shipping products selected, remove them from Cart
  const removeShippingProductFromCart = () => {
    let filteredCart = [...cart]

    cart.forEach(cartItem => {
      if (cartItem.type !== "meal plan" && cartItem.type !== "workout plan") {
        filteredCart = removeLSItem(filteredCart, cartItem)
      }
    })

    setCart(filteredCart)
  }

  /** -----------------------------------------
   SIDEBAR OPEN/CLOSE
   ------------------------------------------- */
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const handleSidebar = () => setSidebarOpen(!sidebarOpen)

  useEffect(() => {
    if (sidebarOpen) {
      isBrowser && document.body.classList.add("overflow--hidden")
    } else {
      isBrowser && document.body.classList.remove("overflow--hidden")
    }
  }, [sidebarOpen])

  return (
    <ThemeContext.Provider
      value={{
        addVariantToCart: (
          variantId,
          productId,
          quantity,
          price,
          sellingPlan,
          title,
          type,
          options,
          currentCartItem,
          isCart,
        ) =>
          addVariantToCart(
            variantId,
            productId,
            quantity,
            price,
            sellingPlan,
            title,
            type,
            options,
            currentCartItem,
            isCart,
          ),
        updateItemInCart: (
          variantId,
          productId,
          quantity,
          selling_plan,
          price,
          isSidebar,
          currentCartItem,
          options,
          isCart,
        ) =>
          updateItemInCart(
            variantId,
            productId,
            quantity,
            selling_plan,
            price,
            isSidebar,
            currentCartItem,
            options,
            isCart,
          ),
        updateQuantity: (cartItem, quantity) => updateQuantity(cartItem, quantity),
        removeLineItemInCart: cartItem => removeLineItemInCart(cartItem),
        sidebarOpen: sidebarOpen,
        handleSidebar: handleSidebar,
        checkoutUrl: checkoutUrl,
        total: total,
        subtotal: subtotal,
        cart: cart,
        country: country,
        shipping: shipping,
        updateCountry: newCountry => updateCountry(newCountry),
        changeModalCountry: changeModalCountry,
        handleChangeModalCountry: isPopup => handleChangeModalCountry(isPopup),
        pageLoader: pageLoader,
        discount: discount,
        error: error,
        shopifyData: shopifyData,
        giftId: giftId,
        showGiftBox: showGiftBox,
        handleGiftBox: handleGiftBox,
        isDiscountActive: isDiscountActive,
        discountCode: discountCode,
        discountThroughText: discountThroughText,
        startDateTimeBasedDiscount: startDateTimeBasedDiscount,
        endDateTimeBasedDiscount: endDateTimeBasedDiscount,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
export default ThemeContext

export { ThemeProvider }
