import React from "react"

// Components
import { Link } from "gatsby"
import DiscountCode from "./Items/DiscountCode"
import DiscountBtn from "./Items/DiscountBtn"
import DiscountTxt from "./Items/DiscountTxt"

/**
 * @param theme - theme
 * @param modifierClass - class for specific style
 * @param isCart - is the box located in the cart
 * */
const DiscountCodeBox = ({ theme, modifierClass, isCart }) => {
  const discountType = "pink" // pink || bg

  return (
    <>
      {theme.shipping && !theme.discount && (
        <>
          {isCart ? (
            <Link
              to={"/category/supplements"}
              role="button"
              className={`discount--${discountType} discount-box ${modifierClass}`}
            >
              <div className="row align-items-center">
                <div className="col-12 col-sm-8">
                  <DiscountCode theme={theme} />
                  <DiscountTxt theme={theme} />
                  <DiscountBtn theme={theme} />
                </div>
                <img
                  src="/images/discounts/discount-25-supplements.png"
                  alt="Supplements."
                  width={335}
                  height={182}
                  className="discount-box__img d-none d-md-block"
                />
                <img
                  src="/images/discounts/discount-25-supplements-sm.png"
                  alt="Supplements."
                  width={203}
                  height={169}
                  className="discount-box__img d-block d-md-none"
                />
              </div>
            </Link>
          ) : (
            <Link
              to={"/category/supplements"}
              role="button"
              onClick={theme.handleSidebar}
              className={`discount--${discountType} discount-box ${modifierClass}`}
            >
              {/*Discount Code*/}
              <DiscountCode theme={theme} />

              <div className="row">
                <div className="col-11 col-sm-10">
                  <DiscountTxt theme={theme} />
                  <DiscountBtn theme={theme} />
                </div>
              </div>

              <img
                src="/images/discounts/discount-25-supplements-sm.png"
                alt="Supplements."
                width={203}
                height={169}
                className="discount-box__img"
              />
            </Link>
          )}
        </>
      )}
    </>
  )
}
export default DiscountCodeBox
