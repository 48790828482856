import React, { useState, useEffect } from "react"
import ReactPlayer from "react-player"

// Utilities
import { extractSelectedOptionImageName, extractVariantImageName, formatShopifyId } from "../../utilities/formatString"
import { isObjectEmpty } from "../../utilities/checkValidity"
import { checkOutOfStock } from "../../utilities/checkOutOfStock"

// Data
import productData from "../../../static/data/product"

// Components
import Slider from "../Sliders/Slider"
import { Link } from "gatsby"
import ProductCarouselInfo from "./ProductInfoComponents/ProductCarouselInfo"
import OutOfStock from "./ProductInfoComponents/OutOfStock"
import WorkoutBundleOptions from "./WorkoutBundle/WorkoutBundleOptions"
import ProductInfoFourPillarsAccordion from "./FourPillars/ProductInfoFourPillarsAccordion"
import VanillaProteinChallengerBanner from "./VanillaProteinChallengerSpecial/VanillaProteinChallengerBanner"
import ShippingIcons from "./ProductInfoComponents/ShippingIcons"
import ProductInformation from "./ProductInfoComponents/ProductInformation"
import VanillaProteinChallengerAddToCart from "./VanillaProteinChallengerSpecial/VanillaProteinChallengerAddToCart"
import ProductPrice from "./ProductInfoComponents/ProductPrice"
import Quantity from "../Quantity/Quantity"
import ProductPaymentOptions from "../ProductPaymentOptions/ProductPaymentOptions"
import OfferVariations from "./OfferVariations/OfferVariations"

/**
 * @param theme - theme context data
 * @param isPopup - is it located in a popup
 * @param handleCloseQuickBuyModal - close quick buy modal
 * @param shopifyProduct - data for the shopify product
 * @param product - static data for the product
 * @param order - the order of the component
 * @param selectedCartItem - the cartItem in localStorage
 * @param isCart - is it opened from the cart
 * */
const ProductInfo = ({
  theme,
  isPopup,
  handleCloseQuickBuyModal,
  shopifyProduct,
  product,
  order,
  selectedCartItem = {},
  isCart,
}) => {
  const shopifyData = theme.shopifyData
  const [selectedProduct, setSelectedProduct] = useState(product)

  // Set default options
  const [paymentType, setPaymentType] = useState("")
  const [totalPrice, setTotalPrice] = useState(0)
  const [isCartUpdate, setIsCartUpdate] = useState(false)
  const selectedOption = {}

  // Quantity
  const [quantity, setQuantity] = useState(1)
  const handleQuantity = quantity => setQuantity(quantity)

  // Cart item
  const [cartItem, setCartItem] = useState({})

  // Set selling plan options
  const [sellingPlan, setSellingPlan] = useState("")
  const [sellingPlanOptions, setSellingPlanOptions] = useState([])
  const [orderId, setOrderId] = useState(0)
  const [paymentPlanPrice, setPaymentPlanPrice] = useState(0)
  const [paymentPlanInterval, setPaymentPlanInterval] = useState(1)
  const [paymentPlanMonthly, setPaymentPlanMonthly] = useState(0)
  const [subscriptionPrice, setSubscriptionPrice] = useState(0)

  // Set gallery
  const [gallery, setGallery] = useState([])
  const [selectedGalleryItem, setSelectedGalleryItem] = useState(0)
  // Note: this is used only for the Workout Bundle product for now
  const [selectedOptionImageName, setSelectedOptionImageName] = useState("")

  // Set id and variant
  const [shopifyVariant, setShopifyVariant] = useState("")
  const [variantId, setVariantId] = useState("")
  const [productId, setProductId] = useState("")

  // Set cart
  const [alreadyInCart, setAlreadyInCart] = useState(false)

  // Check if out of stock
  const isOutOfStock = checkOutOfStock(shopifyProduct, variantId)

  useEffect(() => {
    if (!paymentType || !paymentType.length || !quantity) return

    let price
    if (paymentType === "three" && !isObjectEmpty(bundle)) {
      price = bundle.variants[0].price
    } else {
      price = paymentType === "two" ? Number(subscriptionPrice) : shopifyVariant.price
    }
    setTotalPrice(price * quantity)
  }, [paymentType, quantity, subscriptionPrice, shopifyVariant])

  // Set the payment type
  const handleChange = e => {
    setPaymentType(e.target.value)
  }

  // Remove overflow hidden
  const handleOverflow = () => document.body.classList.remove("overflow--hidden")

  const handlePaymentType = type => {
    setPaymentType(type)

    // Set the first option as selected when the payment type is changed
    if (type === "two") {
      setSellingPlan(Number(sellingPlanOptions[0].sellingPlanId))
    }

    if (shopifyProduct.handle === "workout-promo") {
      // Update gallery with bundle images
      if (shopifyVariant.media.length) {
        updateGallery(extractSelectedOptionImageName(shopifyVariant.media[0].image.originalSrc))
      }
    } else {
      // Update gallery with bundle images
      updateGallery("", type === "three")
    }

    if (!isObjectEmpty(cartItem)) {
      if ((cartItem.selling_plan === "No" && type === "one") || (cartItem.selling_plan !== "No" && type === "two")) {
        setAlreadyInCart(true)
        setIsCartUpdate(false)
      } else {
        setIsCartUpdate(true)
        setAlreadyInCart(false)
      }
    } else {
      setAlreadyInCart(false)
      setIsCartUpdate(false)
    }
  }

  /*===========================================
   Gallery
   ===========================================*/

  // Set model by getting current slide
  const slideChange = (index, item) => {
    setSelectedGalleryItem(index)
    updateThumbVisibility(index, item)
  }

  // Move the clicked thumb to be visible
  const onClickThumb = (index, item) => {
    setSelectedGalleryItem(index)
    updateThumbVisibility(index, item)
  }

  // Update the gallery images
  const updateGallery = (selectedOptionName = "", isBundle = false) => {
    let images = []

    if (shopifyProduct.media.length >= 1) {
      shopifyProduct.media.forEach(media => {
        let isImageInVariants = false
        let isDuplicatedImage = false

        let replaceableImage = ""

        shopifyProduct.variants.forEach(variant => {
          if (variant.media.length) {
            // Set the first image of the carousel according to the selected variant
            if (
              extractSelectedOptionImageName(variant.media[0].image.originalSrc) === selectedOptionName &&
              media.image.originalSrc.indexOf(`${selectedOptionName}-gallery`) !== -1
            ) {
              replaceableImage = media.image.originalSrc
            }

            // Hide the image that is located both in the main carousel and in the variants
            if (media.image.originalSrc.split("?")[0] === variant.media[0].image.originalSrc.split("?")[0]) {
              isImageInVariants = true
            }
          }
        })

        // If it contains workout-bundle it means that the first image is dependent on the selected choice
        if (!isObjectEmpty(media) && media.image.originalSrc.indexOf("workout-bundle") !== -1) {
          isDuplicatedImage = true
        }

        // Set the replaceable image as first
        if (replaceableImage.length) {
          if (images.length > 0) {
            images[0] = replaceableImage
          } else {
            images = [replaceableImage]
          }
        }

        // Don't add images to the gallery if they are inside the variants or a duplicated
        if (!isImageInVariants && !isDuplicatedImage && !isObjectEmpty(media)) {
          images = [...images, media.image.originalSrc]
        }

        // If chocolate workout bundle is selected, remove the vanilla product and vice versa
        if (selectedOptionName.length) {
          const optionType = selectedOptionName.split("-")[0]

          let removeType = ""
          if (optionType === "vanilla") removeType = "chocolate"
          if (optionType === "chocolate") removeType = "vanilla"

          let filteredImages = [...images]

          if (removeType.length) {
            images = filteredImages.filter(item => item.indexOf(removeType) === -1)
          }
        }
      })

      // Update gallery whenever protein variant is selected
      if (shopifyProduct.tags.indexOf("promo") !== -1) {
        shopifyProduct.variants.forEach(variant => {
          if (
            extractVariantImageName(variant.media[0].image.originalSrc, shopifyProduct) === selectedOptionName &&
            variant.media.length
          ) {
            images[0] = variant.media[0].image.originalSrc
          }
        })
      }
    }

    // If the bundle is selected, update the gallery with the bundle images
    if (isBundle) {
      images = []
      bundle.media.forEach(media => {
        images = [...images, media.image.originalSrc]
      })
    }

    if (selectedProduct.video) {
      images = [...images, selectedProduct.video]
    }

    setSelectedGalleryItem(0)
    setGallery(images)
  }

  // Update the thumb visibility and move them left/right
  const updateThumbVisibility = (index, item) => {
    const thumbsAnimated = document.querySelectorAll(".thumbs-wrapper .thumbs")[0]
    const thumbs = document.querySelectorAll(".thumbs-wrapper .thumb")

    const thumbsAnimatedWidth = thumbsAnimated.clientWidth

    let selectedItem
    thumbs.forEach((thumb, i) => {
      if (i === index) {
        selectedItem = thumb
      }
    })

    let difference = thumbsAnimatedWidth - (selectedItem.offsetLeft + selectedItem.clientWidth)

    let itemsSize = thumbs.length * selectedItem.clientWidth

    if (selectedItem.offsetLeft === 0) {
      thumbsAnimated.style.marginLeft = `0px`
    }

    if (difference < 90 && itemsSize > thumbsAnimatedWidth) {
      thumbsAnimated.style.marginLeft = `${difference - 90}px`
    }

    if (difference >= thumbsAnimatedWidth / 2) {
      thumbsAnimated.style.marginLeft = `0px`
    }
  }

  // Set the selected cart item as cart item for getting the default options
  useEffect(() => {
    if (isObjectEmpty(selectedCartItem)) return
    setCartItem(selectedCartItem)
    setAlreadyInCart(true)
  }, [selectedCartItem])

  // Set default options and set bundle
  const [bundle, setBundle] = useState({})
  useEffect(() => {
    if (!theme || !shopifyProduct.shopifyId || !shopifyProduct.title) return

    // Set bundle
    const bundleProduct = selectedProduct.bundleId
      ? shopifyData.filter(item => item.variants[0].shopifyId === selectedProduct.bundleId)[0]
      : {}
    setBundle(bundleProduct)

    if (theme.cart.length) {
      setAlreadyInCart(false)

      let isInCart = theme.cart.filter(item => item.productId === shopifyProduct.shopifyId)

      // If there isn't a selected cart item object sent to the component,
      // find the product in the cart and set it (for digital products only)
      if (isInCart.length) {
        theme.cart.forEach(item => {
          if (item.title === shopifyProduct.title && (item.type === "meal plan" || item.type === "workout plan")) {
            if (isObjectEmpty(selectedCartItem)) {
              setCartItem(item)
            }
          } else {
            setPaymentType("one")
          }
        })
      } else {
        setPaymentType("one")
      }
    } else {
      setAlreadyInCart(false)
      setPaymentType("one")
    }
  }, [shopifyProduct.shopifyId, shopifyProduct.title])

  // Update the gallery when the variant is changed
  useEffect(() => {
    if (!variantId.length) return

    // If the product is workout promo or protein promo, set the default selected option the classic workout bundle
    if (shopifyProduct.handle === "workout-promo" || shopifyProduct.tags.indexOf("promo") !== -1) {
      let selectedType

      shopifyProduct.variants.forEach(variant => {
        if (variant.shopifyId === variantId && variant.media.length) {
          if (shopifyProduct.handle === "workout-promo") {
            selectedType = extractSelectedOptionImageName(variant.media[0].image.originalSrc)
          } else {
            selectedType = extractVariantImageName(variant.media[0].image.originalSrc, shopifyProduct)
          }
        }
      })

      updateGallery(selectedType)
    } else {
      updateGallery()
    }
  }, [variantId])

  // Handle Gallery content
  useEffect(() => {
    if (!selectedOptionImageName.length) return

    updateGallery(selectedOptionImageName)
  }, [selectedOptionImageName])

  // Handle the cart items
  useEffect(() => {
    if (isObjectEmpty(cartItem)) return

    setQuantity(cartItem.quantity)
    setPaymentType(cartItem.selling_plan === "No" ? "one" : "two")
    setAlreadyInCart(true)

    setVariantId(cartItem.variantId)
    setProductId(cartItem.productId)

    if (productData[cartItem.variantId].sellingPlans) {
      productData[cartItem.variantId].sellingPlans.forEach(item => {
        if (cartItem.selling_plan === Number(item.sellingPlanId)) {
          setPaymentPlanInterval(item.months)
          setSellingPlan(Number(item.sellingPlanId))
        }
      })
    }
  }, [cartItem])

  // Set variant and product
  useEffect(() => {
    if (!shopifyProduct.shopifyId || !shopifyProduct.variants) return

    // Set the default selected variant to be the one set in the object
    // NOTE: UPDATE CODE FOR NEW OFFER
    const promoOptionItems = {
      workout: {
        productId: "gid://shopify/Product/7345480204480",
        variantId: "gid://shopify/ProductVariant/43141487755456",
      },
      hwd: {
        productId: "gid://shopify/Product/7729718329536",
        variantId: "gid://shopify/ProductVariant/44562535743680",
      },
      bfs: {
        productId: "gid://shopify/Product/7754890674368",
        variantId: "gid://shopify/ProductVariant/44657971495104",
      },
      baach: {
        productId: "gid://shopify/Product/7874233270464",
        variantId: "gid://shopify/ProductVariant/45002039918784",
      },
      thirtyDmp: {
        productId: "gid://shopify/Product/7749101551808",
        variantId: "gid://shopify/ProductVariant/44632243273920",
      },
      mdp: {
        productId: "gid://shopify/Product/7891449905344",
        variantId: "gid://shopify/ProductVariant/45029868896448",
      },
      ninetyDch: {
        productId: "gid://shopify/Product/7751589953728",
        variantId: "gid://shopify/ProductVariant/44649379659968",
      },
      lioness: {
        productId: "gid://shopify/Product/7751590510784",
        variantId: "gid://shopify/ProductVariant/44649381134528",
      },
    }

    let variant

    // Set variant
    if (Object.values(promoOptionItems).filter(item => item.productId === shopifyProduct.shopifyId).length) {
      if (!isObjectEmpty(selectedCartItem)) {
        variant = shopifyProduct.variants.filter(variant => variant.shopifyId === selectedCartItem.variantId)[0]
      } else {
        for (const promoItem of Object.values(promoOptionItems)) {
          if (shopifyProduct.shopifyId === promoItem.productId) {
            variant = shopifyProduct.variants.filter(variant => variant.shopifyId === promoItem.variantId)[0]
          }
        }
      }
    } else {
      variant = shopifyProduct.variants[0]
    }

    setShopifyVariant(variant)

    // Set product id
    setProductId(formatShopifyId(shopifyProduct.shopifyId))
  }, [shopifyProduct.variants, shopifyProduct.shopifyId])

  // Set variant shopifyId
  useEffect(() => {
    if (!shopifyVariant.shopifyId || !isObjectEmpty(cartItem)) return
    setVariantId(formatShopifyId(shopifyVariant.shopifyId))
  }, [cartItem, shopifyVariant.shopifyId])

  // Set selling plan values
  useEffect(() => {
    if (!variantId || !productData) return

    let options = []
    let paymentMonthly

    if (productData[variantId] && productData[variantId].sellingPlans) {
      productData[variantId].sellingPlans.forEach((item, index) => {
        options = [...options, item]
      })
      setSellingPlanOptions(options)

      if (options.length === 1 && shopifyVariant.compareAtPrice) {
        setPaymentPlanPrice(shopifyVariant.compareAtPrice - productData[variantId].sellingPlans[0].price)
        setPaymentPlanInterval(productData[variantId].sellingPlans[0].months)

        paymentMonthly = Number(
          (shopifyVariant.compareAtPrice -
            (shopifyVariant.compareAtPrice - productData[variantId].sellingPlans[0].price)) /
            productData[variantId].sellingPlans[0].months,
        )
        setPaymentPlanMonthly(paymentMonthly.toFixed(2))
      }
    }
  }, [variantId, shopifyVariant.compareAtPrice])

  // Set subscription data
  useEffect(() => {
    if (!variantId || !productData || !productData[variantId] || !sellingPlan) return

    if (productData[variantId].sellingPlans) {
      const item = productData[variantId].sellingPlans.filter(item => item.sellingPlanId === sellingPlan)
      const price = item[0].price
      const months = item[0].months
      if (price && months) {
        setSubscriptionPrice(price / months)
      } else {
        if (productData[variantId].type === "bundles") {
          setSubscriptionPrice(shopifyVariant.price.toFixed(2))
        } else {
          // If there is discount percentage item, use that to calculate the discounted price
          if (product.discountPercentage) {
            setSubscriptionPrice(((shopifyVariant.price * (100 - +product.discountPercentage)) / 100).toFixed(2))
          } else {
            setSubscriptionPrice(((shopifyVariant.price * 89.99) / 100).toFixed(2))
          }
        }
      }
    }
  }, [sellingPlan, variantId, shopifyVariant.price])

  // Set order
  useEffect(() => {
    setOrderId(order)
  }, [order])

  // Disable add to cart/update buttons if there is no inventory for the selected variant
  const [variantInventory, setVariantInventory] = useState(true)

  // Handle variant option selection
  const handleVariantSelect = selectedVariant => {
    let extractedImageName = ""
    if (selectedVariant.media.length) {
      if (shopifyProduct.handle === "workout-promo") {
        extractedImageName = extractSelectedOptionImageName(selectedVariant.media[0].image.originalSrc)
      } else {
        extractedImageName = extractVariantImageName(selectedVariant.media[0].image.originalSrc, shopifyProduct)
      }
    }

    setShopifyVariant(selectedVariant)
    setVariantId(selectedVariant.shopifyId)
    setSelectedProduct(productData[selectedVariant.shopifyId])
    setSelectedOptionImageName(extractedImageName)

    if (shopifyProduct.tracksInventory) {
      setVariantInventory(selectedVariant.inventoryQuantity > 0 && selectedVariant.availableForSale)
    }
  }

  /*===========================================
   HANDLE SUBMIT
   ===========================================*/

  const handleSubmit = theme => {
    let vId, pId, price, plan, title, type

    // If the bundle is selected, replace all the products values with the bundles values
    if (paymentType === "three" && !isObjectEmpty(bundle)) {
      vId = bundle.variants[0].shopifyId
      pId = bundle.shopifyId
      price = bundle.variants[0].price
      plan = "No"
      title = bundle.title
      type = productData[product.bundleId].type
    } else {
      vId = variantId
      pId = productId
      price = paymentType === "two" ? Number(subscriptionPrice) : shopifyVariant.price
      plan = paymentType === "two" ? sellingPlan : "No"
      title = shopifyProduct.title
      type = productData[variantId].type
    }

    let options = selectedOption

    // If the product is an offer
    if (shopifyProduct.tags.indexOf("promo") !== -1 && shopifyVariant.selectedOptions.length) {
      const variantInterval = shopifyVariant.selectedOptions.filter(item => {
        return item.name.toLowerCase() === "interval"
      })[0].value
      const variantType = shopifyVariant.selectedOptions.filter(item => {
        return item.name.toLowerCase() === "type"
      })[0].value

      options = {
        interval: variantInterval,
        type: variantType,
      }
    }

    theme.addVariantToCart(vId, pId, quantity, price, plan, title, type, options, selectedCartItem, isCart)

    if (isPopup) handleCloseQuickBuyModal()
  }

  return (
    <>
      <div className="row">
        {/*gallery*/}
        <div className="col-12 col-lg-6">
          <div className="product-images">
            {shopifyVariant && shopifyVariant.compareAtPrice && !selectedProduct.offer && (
              <div className="product-onsale">
                <i className="fas fa-siren-on" /> sale
              </div>
            )}

            {paymentType === "two" && selectedProduct.type === "supplements" && (
              <div
                className={`product-discount ${
                  productId === "gid://shopify/ProductVariant/20808707866694" // berry green protein
                    ? "product-discount--right"
                    : ""
                }`}
              >
                <p className="product-discount__title">Subscribe and Save</p>
                <p className="product-discount__content">
                  <span className="d-block">
                    <strong>10% off every order.</strong>
                  </span>
                  Delivered on your schedule. Cancel anytime!{" "}
                </p>
              </div>
            )}

            {gallery.length > 0 ? (
              <>
                {shopifyProduct.media.length === 1 && (
                  <img width="520" height="434" src={shopifyProduct.media[0].image.originalSrc} alt="" />
                )}
                {shopifyProduct.media.length > 1 && (
                  <Slider
                    modifierClass="product-carousel"
                    selectedItem={selectedGalleryItem}
                    showIndicators={false}
                    autoPlay={false}
                    showThumbs={true}
                    onChange={slideChange}
                    onClickThumb={onClickThumb}
                  >
                    {gallery.map((item, index) =>
                      index === gallery.length - 1 && productData[variantId]?.video ? (
                        <React.Fragment key={`gallery-thumb-${index}`}>
                          <img
                            src={`/images/video-thumbs/${productData[variantId].img}-video.png`}
                            alt=""
                            className="no-shadow video-thumb"
                          />
                          <div className="video bg--white video--16-9">
                            <ReactPlayer
                              url={`https://player.vimeo.com/video/${item}`}
                              controls={true}
                              width="640"
                              height="360"
                            />
                          </div>
                        </React.Fragment>
                      ) : (
                        <img key={index} src={item} alt="" className="no-shadow" />
                      ),
                    )}
                  </Slider>
                )}
              </>
            ) : (
              <span className="img-loader" />
            )}
          </div>

          <ProductCarouselInfo id={shopifyProduct.shopifyId} position="top" />
        </div>
        {/*content*/}
        <div className="col-12 col-lg-6">
          <div className="product">
            {selectedProduct && <ProductInformation product={selectedProduct} shopifyProduct={shopifyProduct} />}

            {shopifyProduct.handle === "4-pillars-of-health-promo" && <ProductInfoFourPillarsAccordion />}

            {/*PRICE*/}
            {product.type !== "bundles" && (
              <ProductPrice
                shopifyData={shopifyData}
                shopifyVariant={shopifyVariant}
                product={selectedProduct}
                totalPrice={totalPrice}
                paymentType={paymentType}
                sellingPlan={sellingPlan}
                paymentPlanInterval={paymentPlanInterval}
              />
            )}

            {product.offer && (
              <OfferVariations
                shopifyProduct={shopifyProduct}
                orderId={orderId}
                selectedVariant={shopifyVariant}
                selectedVariantId={variantId}
                handleVariantSelect={handleVariantSelect}
              />
            )}

            {shopifyProduct.handle === "workout-promo" && (
              <WorkoutBundleOptions
                shopifyProduct={shopifyProduct}
                product={selectedProduct}
                orderId={orderId}
                selectedVariant={shopifyVariant}
                selectedVariantId={variantId}
                paymentType={paymentType}
                isImgVisible={true}
                handleVariantSelect={handleVariantSelect}
                handlePaymentType={type => handlePaymentType(type)}
                handleChange={e => handleChange(e)}
              />
            )}

            {shopifyProduct.handle === "vanilla-protein-challenger-special" ? (
              <VanillaProteinChallengerAddToCart
                alreadyInCart={alreadyInCart}
                theme={theme}
                variantId={variantId}
                productId={productId}
                quantity={quantity}
                price={shopifyVariant.price}
                title={shopifyProduct.title}
                isCart={isCart}
              />
            ) : (
              <>
                {!isOutOfStock ? (
                  <>
                    {(selectedProduct.type === "bundles" || selectedProduct.type === "supplements") &&
                      shopifyProduct.handle !== "workout-promo" &&
                      product.paymentOptions === 2 && (
                        <ProductPaymentOptions
                          shopifyVariant={shopifyVariant}
                          product={selectedProduct}
                          paymentType={paymentType}
                          bundle={bundle}
                          orderId={orderId}
                          isImgVisible={true}
                          handlePaymentType={type => handlePaymentType(type)}
                          handleChange={e => handleChange(e)}
                        />
                      )}

                    {/*Meal Plan & Workout Plan*/}
                    {selectedProduct.type !== "supplements" &&
                      selectedProduct.type !== "bundles" &&
                      selectedProduct.paymentOptions === 2 && (
                        <ProductPaymentOptions
                          shopifyVariant={shopifyVariant}
                          product={selectedProduct}
                          paymentType={paymentType}
                          bundle={bundle}
                          orderId={orderId}
                          paymentPlan
                          handlePaymentType={type => handlePaymentType(type)}
                          handleChange={e => handleChange(e)}
                          isPaymentPlan={true}
                          paymentPlanPrice={paymentPlanPrice}
                          paymentPlanMonthly={paymentPlanMonthly}
                          paymentPlanInterval={paymentPlanInterval}
                        />
                      )}

                    {/*PRICE*/}
                    {product.type === "bundles" && (
                      <ProductPrice
                        shopifyData={shopifyData}
                        shopifyVariant={shopifyVariant}
                        product={selectedProduct}
                        totalPrice={totalPrice}
                        paymentType={paymentType}
                        sellingPlan={sellingPlan}
                        paymentPlanInterval={paymentPlanInterval}
                      />
                    )}

                    {(selectedProduct.type === "supplements" || selectedProduct.type === "bundles") &&
                      !selectedProduct.noQuantity && (
                        <div className="row">
                          <div className="col-6 col-lg-5">
                            <Quantity quantity={quantity} handleQuantity={handleQuantity} size="lg" />
                          </div>

                          {selectedProduct.paymentOptions === 2 && paymentType === "two" && (
                            <div className="col-6 col-lg-7">
                              <div className="input d-flex align-items-start align-items-sm-center flex-column flex-sm-row mb--20">
                                <label htmlFor="deliverEvery" className="mr--15">
                                  <strong>Deliver Every</strong>
                                </label>
                                <select
                                  id="deliverEvery"
                                  value={sellingPlan}
                                  onChange={event => setSellingPlan(Number(event.target.value))}
                                >
                                  {sellingPlanOptions.map((item, index) => (
                                    <option key={`selling-plan-${index}`} value={item.sellingPlanId}>
                                      {item.orderIntervalFrequency} Days
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                    {!variantInventory && (
                      <>
                        <h6 className="txt--20 txt--pink">
                          <strong>Temporarily Out Of Stock</strong>
                        </h6>
                        <p className="txt--16">We’re working on getting this back in stock as soon as possible!</p>
                      </>
                    )}

                    <div className={isPopup ? "d-flex flex-column align-items-center justify-content-center" : ""}>
                      <div
                        className={`d-flex align-items-center justify-content-center flex-wrap
                      ${isPopup ? " info-buttons" : " flex-column flex-sm-row justify-content-lg-start"}`}
                      >
                        {alreadyInCart &&
                        selectedProduct.type !== "supplements" &&
                        selectedProduct.type !== "bundles" &&
                        selectedProduct.paymentOptions !== 3 ? (
                          <button aria-label="Added to Cart." disabled className="btn btn--md mb--10">
                            added to cart
                          </button>
                        ) : (
                          <button
                            aria-label="Add to Cart."
                            onClick={() => handleSubmit(theme)}
                            className="btn btn--md mb--10"
                            disabled={!variantInventory}
                          >
                            {(isCartUpdate && selectedProduct.type !== "supplements") ||
                            !isObjectEmpty(selectedCartItem)
                              ? "update cart"
                              : "add to cart"}
                          </button>
                        )}

                        {isPopup && (
                          <Link
                            to={`/products/${shopifyProduct.handle}`}
                            onClick={handleOverflow}
                            className="btn btn--md btn--outline ml--10 mb--10"
                          >
                            Learn More
                          </Link>
                        )}
                        {isPopup ? (
                          <div className="d-flex justify-content-center align-items-center flex-wrap flex-column flex-sm-row">
                            <img
                              src="/images/credit-cards.png"
                              alt=""
                              width="300"
                              height="39"
                              className="modal__credit-cards no-shadow"
                            />
                            {(selectedProduct.type === "supplements" || selectedProduct.type === "bundles") && (
                              <ShippingIcons modifierClass="ml--10" usaOnly={selectedProduct.offer} />
                            )}
                          </div>
                        ) : (
                          <ShippingIcons modifierClass="ml--sm-30 mb--10" usaOnly={selectedProduct.offer} />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <OutOfStock box={true} type={selectedProduct.type} shopifyProduct={shopifyProduct} />
                )}
              </>
            )}

            <ProductCarouselInfo id={shopifyProduct.shopifyId} position="bottom" />
          </div>
        </div>
      </div>

      {shopifyProduct.handle === "vanilla-protein-challenger-special" && <VanillaProteinChallengerBanner />}
    </>
  )
}
export default ProductInfo
