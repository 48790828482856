import React from "react"

// Components
import Countdown from "../Countdown/Countdown"

const DiscountHelloBar = ({ theme }) => {
  return (
    <>
      {theme.shipping && (
        <div className="discount-preheader">
          <div className="container">
            <div className="d-flex flex-wrap justify-content-center align-items-center">
              <p className="discount-preheader__txt">
                <img src="/images/discounts/icon-star.svg" width={22} height={22} alt="Icon star." className="mr--5" />{" "}
                <strong className="fw--bold mr--5">25% OFF YOUR ENTIRE ORDER</strong> with supplement{" "}
                <span className="d-inline-block">
                  purchase through {theme.discountThroughText}!{" "}
                  <img
                    src="/images/discounts/icon-star.svg"
                    width={22}
                    height={22}
                    alt="Icon star."
                    className="ml--5"
                  />{" "}
                </span>
              </p>

              {theme.endDateTimeBasedDiscount.length > 0 && (
                <Countdown endTime={theme.endDateTimeBasedDiscount} modifierClass="ml--15" />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default DiscountHelloBar
