import React from "react"

// Provider
import ThemeContext from "../../context/ThemeContext"

/**
 * Modal Country is used for switching between worldwide shop and US/Canada/UK shop
 * */
const ModalCountry = () => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <>
          {theme.changeModalCountry && (
            <>
              <span
                aria-label="Close Modal."
                role="button"
                tabIndex={0}
                className="overlay overlay-over-sidebar"
                onClick={() => theme.handleChangeModalCountry(false)}
                onKeyDown={() => theme.handleChangeModalCountry(false)}
              />
              <div className="modal modal-country shadow-1">
                <div className="modal-country__header arrow-down arrow-down--pink arrow-down--sm">
                  <img
                    src="/images/globe-icon.png"
                    width="53"
                    height="60"
                    alt=""
                    className="radius-0 no-shadow"
                  />
                  <span>Shop Location Alert</span>
                  <button
                    aria-label="Close Modal."
                    onClick={() => theme.handleChangeModalCountry(false)}
                    className="modal__close"
                  >
                    <i className="fal fa-times" />
                  </button>
                </div>

                <div className="modal-country__content">
                  <p>
                    Be aware that when changing your shop location from your
                    local address, some items may have limited availability.
                  </p>
                  {theme.shipping ? (
                    <div>
                      <button
                        aria-label="Stay in US/UK/Canada Shop."
                        onClick={() => {
                          theme.updateCountry("US")
                        }}
                        className="btn btn--md mr--10 mr--xxs-0 mb--xxs-10"
                      >
                        Stay in US/UK/Canada Shop
                      </button>
                      <button
                        aria-label="Go to Worldwide Shop."
                        onClick={() => {
                          theme.updateCountry("worldwide")
                        }}
                        className="btn btn--md btn--outline"
                      >
                        Go to Worldwide Shop
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        aria-label="Stay in Worldwide Shop."
                        onClick={() => {
                          theme.updateCountry("worldwide")
                        }}
                        className="btn btn--md mr--10 mr--xxs-0 mb--xxs-10"
                      >
                        Stay in Worldwide Shop
                      </button>
                      <button
                        aria-label="Go to US/UK/CANADA Shop."
                        onClick={() => {
                          theme.updateCountry("US")
                        }}
                        className="btn btn--md btn--outline"
                      >
                        Go to US/UK/Canada Shop
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </ThemeContext.Consumer>
  )
}
export default ModalCountry
