import React, { useEffect, useState } from "react"

// Provider
import ThemeContext from "../../context/ThemeContext"

// Components
import { Link } from "gatsby"
import ModalLegal from "../Modals/ModalLegal"

/**
 * @param pageLocation - page location
 * **/
export default function Footer({ pageLocation }) {
  // If one of the following titles, the footer needs to have bigger padding top
  const isPadding =
    (pageLocation.pathname.indexOf("category") !== -1 &&
      pageLocation.pathname.indexOf("apparel") === -1 &&
      pageLocation.pathname.indexOf("merchandise") === -1 &&
      pageLocation.pathname.indexOf("products") === -1) ||
    pageLocation.pathname === "/"

  // Set modal variables
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [legalType, setLegalType] = useState([])

  // Handle modal data
  const handleModalData = type => {
    setLegalType(type)
    setIsModalOpen(true)
  }

  // Add/remove overflow hidden when modal is opened/closed
  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("overflow--hidden")
    } else {
      document.body.classList.remove("overflow--hidden")
    }
  }, [isModalOpen])

  return (
    <ThemeContext.Consumer>
      {theme => (
        <>
          <footer className={`footer ${isPadding ? "footer--padding" : ""}`}>
            <div className="container">
              <div className="row gutters-30 align-items-center">
                <div className="col-12 col-sm-6 order-1 order-sm-3 order-lg-1 col-lg-3">
                  <h2>Connect with me</h2>
                  <div className="social-media">
                    <div className="d-flex justify-content-around">
                      <a
                        rel="nofollow noopener noreferrer"
                        href="https://www.facebook.com/thebettyrocker"
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f" />
                        <span className="sr-only hidden">Facebook</span>
                      </a>
                      <a
                        rel="nofollow noopener noreferrer"
                        href="https://www.instagram.com/thebettyrocker/"
                        target="_blank"
                      >
                        <i className="fab fa-instagram" aria-hidden="true" />
                        <span className="sr-only hidden">Instagram</span>
                      </a>
                      <a
                        rel="nofollow noopener noreferrer"
                        href="https://www.pinterest.com/thebettyrocker/"
                        target="_blank"
                      >
                        <i className="fab fa-pinterest" aria-hidden="true" />
                        <span className="sr-only hidden">Pinterest</span>
                      </a>
                      <a
                        rel="nofollow noopener noreferrer"
                        href="https://www.youtube.com/user/TheBettyRocker"
                        target="_blank"
                      >
                        <i className="fab fa-youtube" aria-hidden="true" />
                        <span className="sr-only hidden">Youtube</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 order-2 order-sm-1 order-lg-2 col-lg-3">
                  <ul className="footer-nav">
                    <li>
                      <a
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        href="https://thebettyrocker.com/blog/"
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        href="https://thebettyrocker.com/faq/"
                      >
                        FAQ
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        href="https://thebettyrocker.com/success-stories/"
                      >
                        Reviews
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        href="https://thebettyrocker.com/contact/"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-sm-6 order-3 order-sm-2 order-lg-3 col-lg-3">
                  <ul className="footer-nav">
                    <li>
                      <Link to="/category/workout-plans/">Workout Plans</Link>
                    </li>
                    <li>
                      <Link to="/category/meal-plans/">Meal Plans</Link>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        href="https://shop.thebettyrocker.com/rock-your-life/"
                      >
                        Rock Your Life
                      </a>
                    </li>
                    {theme.shipping && (
                      <li>
                        <Link to="/category/supplements/">Supplements</Link>
                      </li>
                    )}
                    {theme.shipping && (
                      <li>
                        <Link to="/category/merchandise/">Merchandise</Link>
                      </li>
                    )}
                    {theme.shipping && (
                      <li>
                        <Link to="/category/apparel/">Apparel</Link>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="col-12 col-sm-6 order-4 order-sm-4 order-lg-4 col-lg-3">
                  <h2 className="txt--22">
                    {theme.shipping
                      ? "Not in the US, UK or CANADA?"
                      : "In the US, UK or CANADA?"}
                  </h2>
                  <span className="double-arrows" />
                  <button
                    aria-label={
                      theme.shipping
                        ? "Go to Worldwide Shop."
                        : "Go to US/UK/Canada Shop."
                    }
                    onClick={() => theme.handleChangeModalCountry(true)}
                    className="btn btn--full"
                  >
                    {theme.shipping ? "worldwide shop" : "us/uk/canada shop"}
                  </button>
                </div>
              </div>

              <ul className="footer-aftermenu">
                <li>
                  <span>
                    © {new Date().getFullYear()} The Betty Rocker, Inc.{" "}
                  </span>
                </li>
                <li>
                  <span>All Rights Reserved</span>
                </li>
                <li>
                  <button
                    aria-label="Go to Terms."
                    className="footer-link"
                    onClick={() => handleModalData("terms")}
                  >
                    Terms
                  </button>
                </li>
                <li>
                  <button
                    aria-label="Go to Privacy."
                    className="footer-link"
                    onClick={() => handleModalData("privacy")}
                  >
                    Privacy
                  </button>
                </li>
                <li>
                  <button
                    aria-label="Go to Return Policy."
                    className="footer-link"
                    onClick={() => handleModalData("return")}
                  >
                    Return Policy
                  </button>
                </li>
                <li>
                  <button
                    aria-label="Go to Shipping Information."
                    className="footer-link"
                    onClick={() => handleModalData("shipping")}
                  >
                    Shipping Information
                  </button>
                </li>
              </ul>
            </div>
          </footer>

          {isModalOpen && (
            <ModalLegal
              type={legalType}
              handleCloseModal={() => setIsModalOpen(false)}
            />
          )}
        </>
      )}
    </ThemeContext.Consumer>
  )
}
