import React from "react"

const CountdownItem = ({ value, type }) => {
  const digits = Array.from(String(value))
  return (
    <div className="countdown__item">
      <div className="countdown__item__value">
        <span>{digits.length < 2 ? 0 : digits[0]}</span>
        <span>{digits[digits.length - 1]}</span>
      </div>
      <div className="countdown__item__type">{type}</div>
    </div>
  )
}
export default CountdownItem
