import React from "react"

const ShippingIcons = ({ modifierClass, usaOnly = false }) => {
  return (
    <div className={`d-flex align-items-center ${modifierClass || ""}`}>
      <p className="txt--16 txt--uppercase m--0">Shipping to{usaOnly && ": "}</p>
      <div className="shipping-icons">
        {usaOnly && <p className="mb--0 txt--gray txt--16 mr--5">USA only</p>}
        <img src="/images/us-square-icon.jpg" width={35} height={20} alt="USA." />
        {!usaOnly && <img src="/images/uk-square-icon.jpg" width={35} height={20} alt="UK." />}
        {!usaOnly && <img src="/images/canada-square-icon.jpg" width={35} height={20} alt="Canada." />}
      </div>
    </div>
  )
}
export default ShippingIcons
